import { getIconPath } from 'utils/scripts/getIconPath';
import './wallet-item.scss';
import { digitSperator } from 'utils/scripts/exchange';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';

interface WalletItemProps {
  symbol: string;
  name: string;
  buttons?: {
    buy?: boolean;
    sell?: boolean;
    withdraw?: boolean;
    deposit?: boolean;
    trade?: boolean;
  };
  unitBalance?: number;
  dollarBalance?: number;
  irtBalance?: number;
  style?: any;
}

export const WalletItem: React.FC<WalletItemProps> = ({
  symbol,
  buttons,
  unitBalance,
  dollarBalance,
  irtBalance,
  style,
  name,
}) => {
  const navigate = useNavigate();

  return (
    <div className="xpay-wallet-item" style={style}>
      <div className="currency-label">
        <img src={getIconPath(symbol)} alt="" width={32} height={32} />
        <div className={'col'}>
          <span>{name}</span>
          <span className={'en'}>{symbol.toUpperCase()}</span>
        </div>
      </div>

      {symbol.toUpperCase() !== 'IRT' && (
        <div className="unit-balance">
          موجودی: {unitBalance && <span>{unitBalance} واحد</span>}
        </div>
      )}

      {symbol.toUpperCase() !== 'IRT' && (
        <div className="dollar-balance">
          معادل دلاری موجودی:{' '}
          {dollarBalance && <span>{dollarBalance.toFixed(2)} دلار</span>}
        </div>
      )}

      <div className="irt-balance">
        معادل تومانی موجودی:{' '}
        {irtBalance && (
          <span>{digitSperator(irtBalance.toFixed(0))} تومان</span>
        )}
      </div>

      {buttons && (
        <div className="buttons">
          {buttons.buy && (
            <Button
              className="buy-button"
              type="outlined"
              text="خرید"
              onClick={() =>
                navigate(
                  `/dashboard/orders/create-order?side=buy&symbol=${symbol.toUpperCase()}`,
                )
              }
            />
          )}
          {buttons.sell && (
            <Button
              className="sell-button"
              type="outlined"
              text="فروش"
              onClick={() =>
                navigate(
                  `/dashboard/orders/create-order?side=sell&symbol=${symbol.toUpperCase()}`,
                )
              }
            />
          )}
          {buttons.deposit && (
            <Button
              className="deposit-button"
              type="outlined"
              text="واریز"
              onClick={() =>
                navigate(`/dashboard/wallet/deposit/${symbol.toUpperCase()}`)
              }
            />
          )}
          {buttons.withdraw && (
            <Button
              className="withdraw-button"
              type="outlined"
              text="برداشت"
              onClick={() =>
                navigate(`/dashboard/wallet/withdrawal/${symbol.toUpperCase()}`)
              }
            />
          )}
          {symbol.toUpperCase() !== 'IRT' && buttons.trade && (
            <Button
              className="trade-button"
              type="outlined"
              text="معامله"
              onClick={() =>
                navigate(
                  `/dashboard/orders/create-order?symbol=${symbol.toUpperCase()}`,
                )
              }
            />
          )}
        </div>
      )}
    </div>
  );
};
