import './wallet-navigation.scss';
import { useNavigate } from 'react-router-dom';
import { WalletNavigationItem } from './wallet-navigation-item/wallet-navigation-item';

export const WalletNavigationPage = () => {
  const WalletNavigationItems = [
    {
      id: 0,
      title: 'موجودی',
      link: '/dashboard/wallet',
    },
    {
      id: 1,
      title: 'واریز تومانی',
      link: '/dashboard/wallet/deposit/IRT',
    },
    {
      id: 2,
      title: 'برداشت تومانی',
      link: '/dashboard/wallet/withdrawal/IRT',
    },
    {
      id: 3,
      title: 'واریز رمزارز',
      link: '/dashboard/wallet/deposit/',
    },
    {
      id: 3,
      title: 'برداشت رمزارز',
      link: '/dashboard/wallet/withdrawal/',
    },
  ];

  const navigate = useNavigate();

  const handleNavigation = (orderType: number) => {
    navigate(`/dashboard/orders?type=${orderType}`);
  };

  return (
    <div className="xpay-orders-navigation">
      {WalletNavigationItems.map((item, index) => (
        <WalletNavigationItem
          key={item.id}
          id={item.id}
          title={item.title}
          onClick={() => navigate(item.link)}
        />
      ))}
    </div>
  );
};
