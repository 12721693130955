import * as React from 'react';
import {Button, Card} from 'components';
import ReactCodeInput from 'react-verification-code-input';
import {
    TwoFactorAuthSmsVerify,
    TwoFactorAuthSmsVerifyError
} from 'core/domains/profile/two-factor-auth/two-factor-auth';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {disableOtp, setupGoogleAuth} from 'core/repositories/profile/profile';


type Props = {
    setStage: any,
    isActive: boolean
};

export const OtpCode: React.FC<Props> = ({setStage, isActive}) => {
    const [fieldValue, setFieldValue] = React.useState<TwoFactorAuthSmsVerify>();
    const [fieldError, setFieldError] = React.useState<TwoFactorAuthSmsVerifyError>();
    const [loading, setLoading] = React.useState<boolean>(false);

    function handleGoogleAuth() {
        isActive
            ? disableOtp(
                setLoading,
                fieldValue?.appCode || ''
            )
            : setupGoogleAuth(
                setLoading,
                setFieldError,
                fieldError,
                fieldValue,
                setStage,
            );
    }

    return <Card className="xpay-two-step-verify">
        <>
            <div className="head">
                <div>
                    <h4>تایید صحت دو مرحله ای اپلیکیشن</h4>
                </div>
                <p>کد ۶ رقمی نمایش داده شده در Authenticator را اینجا وارد نمایید.</p>
            </div>

            <div className="main code app-code">
                <ReactCodeInput
                    className={`input `}
                    fieldWidth={48}
                    values={
                        fieldValue?.appCode ? fieldValue?.appCode.split('') : []
                    }
                    autoFocus={true}
                    onChange={(value) => {
                        setFieldValue((prev) => ({
                            ...prev,
                            appCode: value,
                        }));
                    }}
                />
                <div className="stats">
                    <div className="errors">
                        {
                            fieldError?.appCode && (
                                <>
                                    <QuestionCircleOutlined/>
                                    <span>{fieldError.appCode}</span>
                                </>
                            )}
                    </div>
                </div>
            </div>
            <Button
                type="info"
                text="تایید"
                onClick={() => handleGoogleAuth()}
                loading={loading}
                disabled={fieldValue?.appCode?.length !== 6 ? true : false}
            />
        </>
    </Card>;
};
