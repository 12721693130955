import { API } from 'apis';
import { Asset } from 'core/domains/markets/asset';
import { WalletFilterValues } from 'core/domains/wallet/wallet';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';
import { toast } from 'react-toastify';

export const getMineAssets = async (
  setLoader: any,
  setResponse: any,
  filters: WalletFilterValues,
  search: string,
) => {
  var q = '?';
  q += `AmountSortDirection=${filters.amountSort}`;
  if (search !== '') q += `&SearchPhrase=${search ?? ''}`;

  setLoader(true);
  await getViaAuth<Asset>(`${API.wallet.assets}` + q).then((data: any) => {
    setLoader(false);
    if (responseValidator(data.status) && data.value) {
      setResponse(data.value);
    }
  });
};

export const syncWallet = async (setLoader: any) => {
  setLoader(true);
  await postViaAuth<Asset>(`${API.wallet.sync}`, {}).then((data: any) => {
    setLoader(false);
    if (responseValidator(data.status) && data.value) {
      toast.success('کیف پول به روز شد');
    }
  });
};
