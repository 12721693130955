import { Empty, Spin } from 'antd';
import * as React from 'react';
import './table.scss';
import DataTable, { Direction, TableColumn } from 'react-data-table-component';
import { Pagination as Paging, XpayComponent } from 'core/domains';
import { Pagination } from 'components/pagination/pagination';
import { Spinner } from '../spinner/spinner';

interface Props extends XpayComponent {
  data?: any[];
  setData?: any;
  column: TableColumn<any>[];
  hasSearchbar?: boolean;
  defaultSortFieldId?: string;
  searchFilterItems?: string[];
  hasFiltering?: boolean;
  pagination?: Paging;
  expandableRowsComponent?: ({ data }: any) => JSX.Element;
  blur?: boolean;
  getData?: any;
  setTableLoading?: any;
  filters?: any;
  query?: string;
}

export const Table: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState<boolean>();

  const [pagedReesult, setpagedResult] = React.useState<any[]>();
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState<any[]>(
    props.data || [],
  );

  React.useEffect(() => {
    setpagedResult(props.data);
  }, [props.data]);

  // React.useEffect(() => {
  //   var q = '';
  //
  //   if (filterText !== '')
  //     q += `${q.includes('?') ? '&' : '?'}keywords=${filterText}`;
  //
  //   if (props.pagination) {
  //     props.getData(props.setTableLoading, props.setData, q, {
  //       pageNumber: 1,
  //       pageSize: 10,
  //     });
  //   }
  //
  //   if (!props.pagination && filterText !== '') {
  //     setLoading(true);
  //     const items: any[] = [];
  //     props.data &&
  //       props.data.forEach((item) => {
  //         for (const name in item) {
  //           if (props.searchFilterItems)
  //             for (let i = 0; i <= props.searchFilterItems.length; i++) {
  //               if (name.includes(props.searchFilterItems[i])) {
  //                 item[name]
  //                   .trim()
  //                   .toLowerCase()
  //                   .includes(filterText.trim().toLowerCase()) &&
  //                   items.push(item);
  //               }
  //             }
  //         }
  //       });
  //     setFilteredItems(
  //       items.filter((thing, i, arr) => {
  //         return arr.indexOf(arr.find((t) => t.id === thing.id)) === i;
  //       }),
  //     );
  //   } else props.data && setFilteredItems(props?.data);
  // }, [filterText]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
  }, [filterText, resetPaginationToggle]);

  return (
    <div
      className={`xpay-data-table ${!props.hasSearchbar ? 'non-header' : ''} ${
        props.className ? props.className : ''
      }`}
    >
      <DataTable
        columns={props.column}
        progressComponent={
          <Spinner loading={loading}>
            <></>
          </Spinner>
        }
        defaultSortFieldId={props.defaultSortFieldId}
        data={props.data || []}
        responsive
        expandableRows={props.expandableRowsComponent ? true : false}
        expandableRowsComponent={props.expandableRowsComponent}
        progressPending={loading}
        direction={Direction.RTL}
        subHeader={true}
        subHeaderComponent={subHeaderComponentMemo}
        noDataComponent={
          <>
            <Empty description="داده‌ای برای نمایش وجود ندارد" />
          </>
        }
      />

      {props.pagination && props.data?.length !== 0 && (
        <Pagination
          page={props.pagination.pageIndex || 0}
          data={props.data || []}
          setdata={props.setData}
          setloader={props.setTableLoading}
          getdata={props.getData}
          size={props.pagination.pageSize || 10}
          query={props.query}
          totalRows={props.pagination.totalCount}
        />
      )}
    </div>
  );
};
