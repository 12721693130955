import './buy-perfect-money-modal.scss';
import { Button, Card, Modal } from '../../../../../components';
import close from '../../../../../assets/icons/close.png';
import { Divider } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ReactComponent as WalletIcon } from 'assets/icons/create-order/wallet.svg';
import { ReactComponent as GatewayIcon } from 'assets/icons/create-order/gateway.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/create-order/error.svg';
import { BankAccount, BankAccountResponse } from '../../../../../core/domains';
import { getMineBankAccount } from '../../../../../core/repositories';
import * as React from 'react';
import { Asset } from '../../../../../core/domains/markets/asset';
import { digitSeparator } from '../../../../../utils/scripts/exchange';
import { Side } from '../create-order';
import { useNavigate } from 'react-router-dom';
import {
  postViaAuth,
  responseValidator,
} from '../../../../../utils/scripts/api';
import { API } from '../../../../../apis';
import { Spinner } from '../../../../../components/spinner/spinner';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  assets: Asset;
  sourceAmount: string;
  destinationAmount: string;
  side: Side;
  onComplete?: () => void;
}

enum Method {
  Wallet,
  Gateway,
}

export const BuyPerfectMoneyModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  assets,
  sourceAmount,
  destinationAmount,
  side,
  onComplete,
}) => {
  const [method, setMethod] = useState<Method>(Method.Wallet);
  const [bankAccounts, setBankAccounts] = useState<BankAccountResponse[]>();
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const createPerfectMoneyOrder = () => {
    if (method === Method.Wallet) {
      setLoading(true);
      postViaAuth(API.transactions.perfectMoney.buy, {
        amount: sourceAmount,
        source: 0,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          toast.success('تراکنش با موفقیت انجام شد.');
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'purchase',
            amount: sourceAmount,
          });
          setIsOpen(false);
          onComplete && onComplete();
        }
      });
    } else if (method === Method.Gateway) {
      setLoading(true);
      postViaAuth(API.transactions.perfectMoney.buy, {
        amount: destinationAmount,
        source: 1,
        bankAccountId: selectedBankAccount?.id,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          toast.success('در حال انتقال به درگاه پرداخت...');
          setIsOpen(false);
          if (responseValidator(response.status)) {
            window.open(response.value.paymentUrl, '_self');
          }
        }
      });
    }
  };

  useEffect(() => {
    !bankAccounts && isOpen && getMineBankAccount(setLoading, setBankAccounts);
  }, [isOpen]);

  useEffect(() => {
    if (bankAccounts && bankAccounts.length > 0)
      setSelectedBankAccount({
        id: bankAccounts[0].id,
        cardNumber: bankAccounts[0].cardNumber,
        bankName: bankAccounts[0].bank.name,
        iBan: bankAccounts[0].iBan,
      });
  }, [bankAccounts]);

  return (
    <Modal
      className={'buy-perfect-money-modal'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card
        title={'انتخاب روش پرداخت'}
        button={
          <img
            style={{ cursor: 'pointer' }}
            src={close}
            alt={'Close'}
            onClick={() => setIsOpen(false)}
          />
        }
        divider
      >
        <div className={'payment-methods'}>
          <div
            className={`payment-method ${method === Method.Wallet ? 'active' : ''}`}
            onClick={() => setMethod(Method.Wallet)}
          >
            <div className={'col'}>
              <WalletIcon />
              <span>واریز از کیف پول</span>
            </div>
          </div>
          <div
            className={`payment-method ${method === Method.Gateway ? 'active' : ''}`}
            onClick={() => setMethod(Method.Gateway)}
          >
            <div className={'col'}>
              <GatewayIcon />
              <span>واریز از درگاه</span>
            </div>
          </div>
        </div>
        <Divider className={'card-divider'} />
        {method === Method.Gateway && (
          <>
            <div className={'choose-card'}>
              <p>کارت بانکی خود را برای پرداخت از درگاه انتخاب کنید:</p>
              <Button
                type={'card'}
                text={'افزودن کارت'}
                onClick={() => navigate('/dashboard/bank-accounts/add-card')}
              />
            </div>
            <ul className="cards">
              <Spinner loading={loading}>
                {bankAccounts?.map((item, index) => (
                  <>
                    <li
                      onClick={() =>
                        setSelectedBankAccount({
                          id: item.id,
                          cardNumber: item.cardNumber,
                          bankName: item.bank.name,
                          iBan: item.iBan,
                        })
                      }
                    >
                      <div>
                        <img
                          src={`/banks/${item.bank.code.toLowerCase()}.svg`}
                        />
                        <span>{item.bank.name}</span>
                      </div>
                      <span dir={'ltr'}>{item.cardNumber}</span>
                    </li>
                    {index !== bankAccounts.length - 1 && <Divider />}
                  </>
                ))}
              </Spinner>
            </ul>
            <Button
              type={'primary'}
              text={'پرداخت'}
              onClick={createPerfectMoneyOrder}
              loading={loading}
            />
          </>
        )}
        {method === Method.Wallet && (
          <>
            <div className={'account-balance'}>
              <p>موجودی کیف پول</p>
              <Button
                type={'card'}
                text={'افزودن موجودی'}
                onClick={() => navigate('/dashboard/wallet')}
              />
            </div>
            <ul className={'balance-info'}>
              <Spinner loading={loading}>
                <li>
                  <p>مجموع کلی دارایی تومانی:</p>
                  <span>
                    {digitSeparator(assets?.irtBalance.toFixed(0))} تومان
                  </span>
                </li>
                <Divider />
                <li>
                  <p>مبلغ سفارش:</p>
                  <span>{digitSeparator(sourceAmount)} تومان</span>
                </li>

                {assets?.irtBalance < Number(destinationAmount) && (
                  <div className={'error-content'}>
                    <p>
                      <ErrorIcon />
                      موجودی شما کافی نمیباشد، لطفا نسبت به شارژ کیف پول اقدام
                      نمایید.
                    </p>
                    <Button
                      className={'charge-button'}
                      type={'primary'}
                      onClick={() => navigate('/dashboard/wallet')}
                      text={`شارژ کیف پول به مبلغ ${digitSeparator((Number(destinationAmount) - assets?.irtBalance).toFixed(0))} تومان`}
                    />
                  </div>
                )}
              </Spinner>
            </ul>
            <Button
              type={'primary'}
              text={'پرداخت'}
              disabled={assets?.irtBalance < Number(destinationAmount)}
              onClick={createPerfectMoneyOrder}
              loading={loading}
            />
          </>
        )}
      </Card>
    </Modal>
  );
};
