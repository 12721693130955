import { MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { Enum, EnumAsString } from "core/domains";

export const ToItemTypes = (items: Enum[]): ItemType[] => {
    const allData: MenuProps['items'] = [];
    items?.length > 0 && items.map((item) => {
        allData.push({
            key: item.id,
            label: item.title
        })
    })
    return allData;
}

export const ToItemTypesAsString = (items: EnumAsString[]): ItemType[] => {
    const allData: MenuProps['items'] = [];
    items?.length > 0 && items.map((item) => {
        allData.push({
            key: item.id,
            label: item.title
        })
    })
    return allData;
}