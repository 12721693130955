import { API } from 'apis';
import { Market, OrderList } from 'core/domains';
import { Page } from 'core/domains/pagging/pagging';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';
import { Side } from '../../../routes/dashboard/orders/create/create-order';
import { toast } from 'react-toastify';

export const createOrders = (
  setmodal: any,
  setloader: any,
  market: Market,
  amount: number,
  side: Side,
  clearInputs: any,
) => {
  setloader(true);
  postViaAuth(API.orders.create, {
    baseSymbol: market.symbol,
    quoteSymbol: 'IRT',
    side: side === Side.Buy ? 1 : 2,
    sourceAmount: Number.parseFloat(amount.toString().replaceAll(',', '')),
  }).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      toast.success('سفارش با موفقیت ثبت شد.');
      setmodal(false);
      clearInputs();
    } else {
      setmodal(false);
    }
  });
};

export const getMineOrders = (
  setloader: any,
  setData: any,
  page: Page,
  query: string,
) => {
  if (page && query) {
    setloader(true);

    getViaAuth<OrderList>(
      API.orders.mine + `${page.pageNumber}/${page.pageSize}` + query,
    ).then((response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setData(response.value);
      }
    });
  }
};
