import React, { FC, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { OrderNavigationItem } from './orders-navigation-item/orders-navigation-item';
import './orders-navigation.scss';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setActiveItemId: Dispatch<SetStateAction<number | undefined>>;
}

export const OrdersNavigationPage: FC<Props> = ({
  isOpen,
  setIsOpen,
  setActiveItemId,
}) => {
  const OrdersNavigationItems = [
    {
      id: 0,
      title: 'خرید و فروش',
      link: '/orders',
      orderType: 0,
    },
    {
      id: 1,
      title: 'واریز و برداشت تومانی',
      link: '/orders',
      orderType: 1,
    },
    {
      id: 2,
      title: 'واریز و برداشت ارزی',
      link: '/orders',
      orderType: 2,
    },
    {
      id: 3,
      title: 'خرید و فروش پرفکت مانی',
      link: '/orders',
      orderType: 3,
    },
  ];

  const navigate = useNavigate();

  const handleNavigation = (orderType: number) => {
    navigate(`/dashboard/orders?type=${orderType}`);
    setIsOpen(false);
    setActiveItemId(undefined);
  };

  return (
    <Drawer
      title="سوابق"
      placement="right"
      closable={true}
      onClose={() => {
        setActiveItemId(undefined);
        setIsOpen(false);
      }}
      open={isOpen}
      width="75%"
      className="xpay-orders-navigation"
      headerStyle={{ padding: '24px', direction: 'ltr' }}
      bodyStyle={{ padding: 0 }}
      closeIcon={<CloseOutlined />}
    >
      {OrdersNavigationItems.map((item, index) => (
        <OrderNavigationItem
          key={item.id}
          id={item.id}
          title={item.title}
          onClick={() => handleNavigation(item.orderType)}
        />
      ))}
    </Drawer>
  );
};
