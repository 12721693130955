import './circle.scss';

export const Circle = ({
  centerText,
  textColor = 'white',
  bottomText,
  reached,
}: {
  centerText: string;
  textColor?: string;
  bottomText: string;
  reached?: boolean;
}) => {
  return (
    <div className={`circle ${reached ? 'reached' : ''}`}>
      <div className="inner">
        <svg width="" height="" viewBox="0 0 56 56" fill="none">
          <circle cx="28" cy="28" r="28" fill="#3364E4" />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            fill={textColor}
          >
            {centerText}
          </text>
        </svg>
      </div>
      <div className="bottomText">{bottomText}</div>
    </div>
  );
};
