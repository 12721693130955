import * as React from 'react';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Calendar, Input } from 'components';
import { Badge } from 'components/badge/badge';
import { Table } from 'components/table/table';
import moment from 'jalali-moment';
import './pm-transactions.scss';
import { Divider, Dropdown, MenuProps, Spin } from 'antd';
import {
  Transaction,
  TransactionFilterValues,
  TransactionList,
} from 'core/domains';
import { digitSperator } from '../../../../../utils/scripts/exchange';
import { TableColumn } from 'react-data-table-component';
import { getIconPath } from 'utils/scripts/getIconPath';
import { ToItemTypes } from 'utils/scripts/table';
import {
  _states,
  _transactionStates,
  _transactionTypes,
  _types,
} from '../filters/constants';
import { getAllMineTransactions } from 'core/repositories/transcations/transaction';
import { getPageNum } from 'utils/scripts/paggingHelper';
import { TransactionOrderDetailModal } from '../../../../../components/transaction-order-modal/transaction-order-modal';
import { Spinner } from '../../../../../components/spinner/spinner';

const { useState } = React;

const empty_filters = {
  type: {
    id: 0,
    title: 'نوع تراکنش',
  },
  state: {
    id: 0,
    title: 'وضعیت تراکنش',
  },
};

export const PmTransactions: React.FC = () => {
  const [data, setData] = useState<TransactionList>();
  const [query, setQuery] = useState<string>('');

  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');

  const filterHandle = () => {
    var q = '';
    q += `${q.includes('?') ? '&' : '?'}marketType=3`;

    if (filters) {
      if (filters.type?.id !== 0)
        q += `${q.includes('?') ? '&' : '?'}type=${filters.type?.id}`;
      if (filters.state?.id !== 0)
        q += `${q.includes('?') ? '&' : '?'}state=${filters.state?.id}`;
      if (filters.startDate)
        q += `${q.includes('?') ? '&' : '?'}startDate=${filters.startDate}`;
      if (filters.endDate)
        q += `${q.includes('?') ? '&' : '?'}endDate=${filters.endDate}`;
      if (filters.sort?.id)
        q += `${q.includes('?') ? '&' : '?'}sort=${filters.sort?.id}`;
      if (filters.keywords)
        q += `${q.includes('?') ? '&' : '?'}keywords=${filters.keywords}`;
    }
    setQuery(q);
    getAllMineTransactions(
      setCardLoading,
      setData,
      {
        pageNumber: getPageNum(),
        pageSize: 10,
      },
      q,
    );
  };

  const clickOnType: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      type: _types.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const clickOnState: MenuProps['onClick'] = ({ key }) => {
    setFilters({
      ...filters,
      state: _transactionStates.filter((t) => t.id === parseInt(key))[0],
    });
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  React.useEffect(() => {
    !isOpen && setSelectedItem(null);
  }, [isOpen]);

  const [filters, setFilters] =
    useState<TransactionFilterValues>(empty_filters);
  const [selectedItem, setSelectedItem] = useState<Transaction | null>();

  React.useEffect(() => {
    filterHandle();
  }, []);

  React.useEffect(() => {
    setIsOpen(false);
  }, []);

  const columns: TableColumn<Transaction>[] = [
    {
      name: '#',
      id: 'index',
      selector: (row, rowIndex) => (rowIndex ? rowIndex + 1 : 1),
      width: '50px',
    },
    // {
    //   name: 'کد پیگیری',
    //   id: 'trackingCode',
    //   selector: (row) => row?.trackingCode,
    //   cell: (row) => <span className="en">{row.trackingCode}</span>,
    // },
    {
      name: 'نام ارز',
      id: 'currencyName',
      selector: (row) => row.market.symbol,
      cell: (row) => (
        <>
          <img src={getIconPath(row.market.symbol)} />
          <span>{row.market.name}</span>
        </>
      ),
    },
    {
      name: 'نوع تراکنش',
      id: 'transactionType',
      selector: (row) => row.type.title,
      cell: (row) => <span>{row?.type?.title}</span>,
    },
    {
      name: 'مقدار',
      id: 'price',
      selector: (row) => digitSperator(row.amount),
    },
    {
      name: 'زمان',
      id: 'date',
      selector: (row) => row.createdAt,
      cell: (row: any) => (
        <div className="column-date">
          <span className="day">
            {moment(row.createdAt).locale('fa').format('jD jMMMM jYYYY')}
          </span>
          <span className="time">
            ساعت {moment(row.createdAt).local().format('HH:mm')}
          </span>
        </div>
      ),
    },
    {
      name: 'وضعیت',
      id: 'state',
      selector: (row) => row.state.id,
      cell: (row: any) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id == 2
              ? 'success'
              : row.state.id == 1
                ? 'waiting'
                : 'error'
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? 'success'
              : row.state.id == 1
                ? 'waiting'
                : 'danger'
          }.svg`}
        />
      ),
      width: '200px',
    },
    {
      name: 'جزئیات',
      id: 'detail',
      cell: (row) => (
        <InfoCircleOutlined
          width={16}
          height={16}
          onClick={() => handleModal(row)}
        />
      ),
    },
  ];

  function handleModal(item: Transaction) {
    setSelectedItem(item);
  }

  React.useEffect(() => {
    selectedItem && setIsOpen(true);
  }, [selectedItem]);

  return (
    <div className="orders-pm-transactions">
      {selectedItem && (
        <TransactionOrderDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          transactionItem={selectedItem}
        />
      )}

      <Spinner loading={cardLoading}>
        <div className="desktop-show">
          <div className="filter-row">
            <div className="fields">
              <Input
                type="text"
                value={filters?.keywords}
                placeholder="جستجو کنید ..."
                name="search"
                onChange={(e) =>
                  setFilters({ ...filters, keywords: e.target.value })
                }
              />

              <Dropdown
                trigger={['click', 'hover']}
                menu={{
                  items: ToItemTypes(_transactionTypes),
                  onClick: clickOnType,
                }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.type?.title}
                />
              </Dropdown>

              <Dropdown
                className="states-dropdown"
                trigger={['click', 'hover']}
                menu={{
                  items: ToItemTypes(_transactionStates),
                  onClick: clickOnState,
                }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.state?.title}
                />
              </Dropdown>

              <Calendar
                label="از تاریخ"
                placeholder="از تاریخ"
                name="startDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, startDate: value }))
                }
              />
              <Calendar
                label="تا تاریخ"
                placeholder="تا تاریخ"
                name="endDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, endDate: value }))
                }
              />
            </div>

            <Divider type="vertical" />
            <div className="buttons">
              <Button
                className="search"
                type="outlined"
                text="اعمال فیلتر"
                onClick={filterHandle}
              />
              <Button
                className="clear-search"
                type="outlined"
                text="حذف فیلتر"
                onClick={() => {
                  setFilters(empty_filters);
                  getAllMineTransactions(
                    setCardLoading,
                    setData,
                    {
                      pageNumber: 1,
                      pageSize: 10,
                    },
                    '?marketType=3',
                  );
                }}
              />
            </div>
          </div>
          {data && (
            <Table
              setData={setData}
              pagination={data.pagination}
              blur={cardLoading}
              hasFiltering={true}
              data={data.items}
              column={columns}
              getData={getAllMineTransactions}
              setTableLoading={setCardLoading}
              query={query}
            />
          )}
        </div>

        <ul className="mobile-show">
          <div className="filter-row">
            <h4>خرید و فروش های پرفکت مانی</h4>

            <div className="fields">
              <Input
                type="text"
                value={filters?.keywords}
                className="search"
                placeholder="جستجو کنید ..."
                name="search"
                onChange={(e) =>
                  setFilters({ ...filters, keywords: e.target.value })
                }
              />

              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(_types), onClick: clickOnType }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.type?.title}
                />
              </Dropdown>

              <Dropdown
                trigger={['click', 'hover']}
                menu={{ items: ToItemTypes(_states), onClick: clickOnState }}
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filters?.state?.title}
                />
              </Dropdown>

              <Calendar
                label="از تاریخ"
                placeholder="از تاریخ"
                name="startDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, startDate: value }))
                }
              />
              <Calendar
                label="تا تاریخ"
                placeholder="تا تاریخ"
                name="endDate"
                onChange={(value) =>
                  setFilters((prev: any) => ({ ...prev, endDate: value }))
                }
              />
            </div>
            <div className="buttons">
              <Button
                className="search"
                type="outlined"
                text="اعمال فیلتر"
                onClick={filterHandle}
              />
              <Button
                className="clear-search"
                type="outlined"
                text="حذف فیلتر"
                onClick={() => {
                  setFilters(empty_filters);
                  getAllMineTransactions(
                    setCardLoading,
                    setData,
                    {
                      pageNumber: 1,
                      pageSize: 10,
                    },
                    '?marketType=3',
                  );
                }}
              />
            </div>
          </div>
          {data?.items.map((item) => (
            <li>
              <div>
                <div className="right">
                  <img src={getIconPath(item.market.symbol)} alt="" />
                  <span>{item.market.name}</span>
                  <span className="en">{item.market.symbol.toUpperCase()}</span>
                </div>
                <div>
                  <p> نوع تراکنش : {item?.type.title}</p>
                </div>
              </div>
              <div>
                <div className="right">
                  <p>مبلغ: </p>
                  <span>{item.amount}</span>
                  <p>واحد</p>
                </div>
                <div className="left"></div>
              </div>
              <div>
                <div className="right">
                  <p>
                    {moment(item.createdAt)
                      .locale('fa')
                      .local()
                      .format('YYYY/MM/DD')}{' '}
                    | {moment(item.createdAt).local().format('HH:mm')}
                  </p>
                </div>
                <div className="left">
                  <Badge
                    text={item.state.title}
                    type={
                      item.state.id == 2 || item.state.id == 3
                        ? 'success'
                        : item.state.id == 1
                          ? 'error'
                          : 'waiting'
                    }
                  />
                </div>
              </div>
              <Button
                type="outlined"
                text="مشاهده جزئیات"
                onClick={() => handleModal(item)}
              />
            </li>
          ))}
        </ul>
      </Spinner>
    </div>
  );
};
