import * as React from 'react';
import {Button, Card} from 'components';
import './index.scss';
import {QrCode} from 'core/domains/profile/profile';
import {copyClipboard} from "../../../../../../utils/scripts/copyToClipboard";
import {CopyOutlined} from "@ant-design/icons";


type Props = {
    setStage: any,
    qrCode?: QrCode,
    setIsOpen: any
};

export const RecoveryCodesAuthenticator: React.FC<Props> = ({setStage, qrCode, setIsOpen}) => {
    return <Card className="xpay-two-step-verify">
        <>
            <div className="head">
                <div>
                    <h4>تهیه پشتیبان</h4>
                </div>
                <p>لطفا این رمز عبور را روی کاغذ نوشته و در جای امنی نگه داری نمایید. این کد در زمان حذف شدن نرم افزار و
                    یا سرقت گوشی شما به بازگرداندن اکانت شما کمک خواهد کرد.</p>
            </div>

            <div className="main recovery">
                <img src="/assets/images/profile/edit.svg" alt="edit"/>

                <p>{qrCode?.recoveryCodes} </p>
                <CopyOutlined
                    onClick={() =>
                        copyClipboard(qrCode?.recoveryCodes ?? '', 'کد ریکاوری')
                    }
                />
            </div>

            <Button
                type="info"
                text="متوجه شدم"
                onClick={() => {
                    setStage('PHONE');
                    setIsOpen(false);
                }}
            />
        </>
    </Card>;
};
