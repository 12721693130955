import * as React from 'react';
import { useEffect } from 'react';
import { Button, Card } from 'components';
import { copyToClipboard } from 'utils/scripts/copyToClipboard';
import { CopyOutlined } from '@ant-design/icons';
import { getQrCodeOtp } from '../../../../../../core/repositories/profile/profile';
import { Spin } from 'antd';
import { QrCode } from '../../../../../../core/domains/profile/profile';
import { QRCodeSVG } from 'qrcode.react';
import { Spinner } from '../../../../../../components/spinner/spinner';

type Props = {
  setStage: any;
  setQrCode: any;
  qrCode?: QrCode;
};

export const QrCodeGoogleAuthenticator: React.FC<Props> = ({
  setStage,
  setQrCode,
  qrCode,
}) => {
  const codeRef = React.useRef<HTMLElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    getQrCodeOtp(setLoading, setQrCode);
  }, []);

  return (
    <Spinner loading={loading}>
      <Card className="xpay-two-step-verify">
        <>
          <div className="head">
            <div>
              <h4>
                اسکن <span className="en">QR-Code</span>
              </h4>
            </div>
            <p>
              کد زیر را توسط Authenticator اسکن کنید. چنانچه به هر دلیل موفق
              نشدید، می توانید کد را به صورت دستی وارد کنید.
            </p>
          </div>

          <div className="main qr-code">
            {qrCode && (
              <QRCodeSVG
                style={{ margin: '16px 0' }}
                value={qrCode.qrCodeUrl}
              />
            )}

            <div>
              <span className={'en'} ref={codeRef}>
                {qrCode?.authenticatorKey}
              </span>
              <CopyOutlined
                onClick={() => copyToClipboard(codeRef.current, 'کد مورد نظر')}
              />
            </div>
          </div>
          <div className="buttons">
            <Button
              type="outlined"
              text="مرحله قبل"
              onClick={() => setStage('DOWNLOAD')}
            />
            <Button
              type="info"
              text="مرحله بعد"
              onClick={() => setStage('CODE')}
            />
          </div>
        </>
      </Card>
    </Spinner>
  );
};
