import * as React from 'react';
import ReactModal from 'react-modal';
import './pop-up.scss';
import { Button } from 'components/button/button';

type Props = {
  isOpen: boolean;
  title?: string;
  description?: string;
  setIsOpen: any;
  className?: string;
  children?: any;
  closeAble?: boolean;
  onClose?: any;
  urlPage?: any;
  linkTo?: any;
  image?: React.ReactNode;
};

export const PopUp: React.FC<Props> = (props) => {
  return (
    <ReactModal
      style={{
        overlay: {
          zIndex: 999,
          background: '#00000066',
        },
      }}
      shouldCloseOnEsc
      className={`xpay-popup ${props.className}`}
      isOpen={props.isOpen}
      onAfterClose={props.onClose}
      onRequestClose={() => props.setIsOpen(false)}
    >
      <div className="popup-content">
        {props.image && props.image}
        {props.title && <h4>{props.title}</h4>}
        {props.description && <p>{props.description}</p>}
        <div className="buttons">
          {props.linkTo && (
            <Button
              text="مشاهده"
              type="primary"
              onClick={() => {
                document.open(props.linkTo, '_blank');
              }}
            />
          )}
          <Button
            text="بازگشت"
            type="outlined"
            onClick={() => props.setIsOpen(false)}
          />
        </div>
      </div>
    </ReactModal>
  );
};
