import './irt-withdrawal.scss';
import { Button, Card, Input, Modal } from '../../../../components';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Collapse, CollapseProps, Divider, Slider } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  getMineBalanceByPair,
  getMineBankAccount,
} from '../../../../core/repositories';
import { BankAccountResponse, Cart, Profile } from '../../../../core/domains';
import { AssetItem } from '../../../../core/domains/markets/asset';
import {
  getViaAuth,
  postViaAuth,
  responseValidator,
} from '../../../../utils/scripts/api';
import { API } from '../../../../apis';
import { AmountPolicy } from '../../../../core/domains/sitesettings/sitesetting';
import { ReactComponent as BackIcon } from 'assets/icons/wallet/Back.svg';
import { ReactComponent as BlueChevronIcon } from 'assets/icons/wallet/BlueChevron.svg';
import { ReactComponent as SilverChevronIcon } from 'assets/icons/wallet/SilverChevron.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/wallet/Warning.svg';
import {
  deleteCommaSperator,
  digitSeparator,
} from '../../../../utils/scripts/exchange';
import { toast } from 'react-toastify';
import { WithdrawalConfirm } from '../widthrawl/confirmModal/withdrawal-confirm-modal';
import { getIconPath } from '../../../../utils/scripts/getIconPath';
import { faNumToEnNum } from '../../../../utils/scripts/decimal';
import { APPNAME } from '../../../../apis/constants';

const ALERT_ITEMS: CollapseProps['items'] = [
  {
    key: '1',
    label: 'نکات قابل  توجه در برداشت تومانی',
    children: (
      <section className={'alert-content'}>
        <div className={'alert-logo'}>
          <WarningIcon />
        </div>
        <p>
          ۱- برداشت های ثبت شده در روزهای تعطیل، در اولین روز کاری بعد از
          تعطیلات با اولین سیکل پایا طبق زمان‌بندی ارسال می‌شوند.
          <br />
          ۲- امکان برداشت وجه فقط به شماره حساب‌هایی که تایید شده باشند وجود
          خواهد داشت.
          <br />
          ۳- {APPNAME} به ۱۲ بانک تحت قرارداد واریز آنی دارد ولیکن در صورتی که
          سیستم واریز آنی از سمت خود بانک مشکل داشته باشد به صورت پایا انجام
          خواهد شد.
          <br />
          ۴- کارمزد برداشت برای انجام عملیات بانکی است و {APPNAME} در آن ذی نفع
          نمیباشد.
        </p>
      </section>
    ),
  },
];

export const IRTWithdrawal = () => {
  const [isBankAccountModalOpen, setIsBankAccountModalOpen] =
    useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = useState<BankAccountResponse[]>([]);
  const [bankAccountsLoading, setBankAccountsLoading] =
    useState<boolean>(false);
  const [wallet, setWallet] = useState<AssetItem>();
  const [policies, setPolicies] = useState<AmountPolicy>();
  const [loading, setLoading] = useState<boolean>(false);
  const [minWithdrawalAmount, setMinWithdrawalAmount] = useState<number>(0);
  const [maxIrtWithdrawal, setMaxIrtWithdrawal] = useState<number>(0);
  const [selectedCard, setSelectedCard] = useState<BankAccountResponse>();
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>('');

  const [twoStepIsOpen, setTwoStepIsOpen] = useState<boolean>(false);
  const [trackingCode, setTrackingCode] = useState<string>('');
  const [withdrawalCommission, setWithdrawalCommission] = useState<number>();
  const [isTwoStepActive, setIsTwoStepActive] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [modalStage, setModalStage] = useState<'code' | 'thankyou'>('code');

  const [sliderValue, setSliderValue] = useState<number>(0);

  const getWithdrawalMinMax = async () => {
    await getViaAuth<Cart[]>(API.policies.amount).then((response: any) => {
      if (responseValidator(response.status)) {
        setMinWithdrawalAmount(response.value.minWidthrawlAmount);
      }
    });

    await getViaAuth<Profile>(API.level.mine).then((response: any) => {
      if (responseValidator(response.status)) {
        setMaxIrtWithdrawal(response.value.maxIrtWithdrawal);
      }
    });
  };

  const onSubmitWithdrawal = () => {
    if (Number(withdrawalAmount) > wallet?.balance!) {
      toast.error('مبلغ درخواستی از موجودی بیشتر است');
      return;
    }
    if (Number(withdrawalAmount) < 100000) {
      toast.error('برداشت مبالغ زیر ۱۰۰ هزار تومان امکان پذیر نیست');
      return;
    }
    setLoading(true);

    postViaAuth(API.widthrawl.currency, {
      amount: parseFloat(deleteCommaSperator(withdrawalAmount)),
      bankAccountId: selectedCard?.id,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setTwoStepIsOpen(true);
        setTrackingCode(response.value.tracking);
        setWithdrawalCommission(response.value.withdrawalCommission);
        setIsTwoStepActive(response.value.isTwoStepActive);
      }
    });
  };

  const onWithdrawalAmountChange = (value: string) => {
    value = faNumToEnNum(value);
    const numericValue = value.replace(/\D/g, '');
    numericValue ? setWithdrawalAmount(numericValue) : setWithdrawalAmount('');
  };

  const onChangeSlider = (sliderValue: number) => {
    if (sliderValue !== null && wallet) {
      setWithdrawalAmount(((wallet?.balance * sliderValue) / 100).toFixed(0));
      setSliderValue(sliderValue);
    }
  };

  function backModal() {
    getMineBalanceByPair('IRT', setBankAccountsLoading, setWallet);
    setWithdrawalAmount('');
    setTwoStepIsOpen(false);
  }

  const verifyCurrencyWithdrawal = (
    code: string,
    trackingCode: string,
    setError: any | undefined,
  ) => {
    if (code.length === 6) {
      setLoading(true);
      postViaAuth(API.widthrawl.verifyCurrency, {
        trackingCode: trackingCode,
        otp: code,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'withdrawal',
            amount: withdrawalAmount,
          });
          setModalStage('thankyou');
          setIsWaiting(response.data.state.id === 6 ? true : false);
        } else {
          if (response.status === 400)
            setError &&
              setError({
                phoneCode: response.message,
              });
        }
      });
    }
  };

  const sendRequestWithdrawal = () => {
    setLoading(true);
    setModalStage('code');
    postViaAuth(API.widthrawl.currency, {
      amount: parseFloat(deleteCommaSperator(withdrawalAmount)),
      bankAccountId: selectedCard?.id,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setTwoStepIsOpen(true);
        setTrackingCode(response.value.tracking);
        setWithdrawalCommission(response.value.withdrawalCommission);
        setIsTwoStepActive(response.value.isTwoStepActive);
        toast.success('درخواست شما با موفقیت ثبت شد');
      }
    });
  };

  useEffect(() => {
    getWithdrawalMinMax();
    getMineBankAccount(setBankAccountsLoading, setBankAccounts);
    getMineBalanceByPair('IRT', setBankAccountsLoading, setWallet);
  }, []);

  useEffect(() => {
    bankAccounts.length !== 0 && setSelectedCard(bankAccounts[0]);
  }, [bankAccounts]);

  return (
    <div className={'xpay-irt-withdrawal'}>
      <Modal
        className="currency-widthrawl-confirm-modal"
        isOpen={twoStepIsOpen}
        setIsOpen={setTwoStepIsOpen}
      >
        <WithdrawalConfirm
          isTwoStepActive={isTwoStepActive ?? 0}
          onBack={() => backModal()}
          setIsOpen={setTwoStepIsOpen}
          marketName={'تومان'}
          setLoading={setLoading}
          isWaiting={isWaiting}
          trackingCode={trackingCode}
          stage={modalStage}
          amount={Number(withdrawalAmount)}
          cardNumber={selectedCard?.cardNumber ?? '0'}
          loading={loading}
          onConfirm={verifyCurrencyWithdrawal}
          onResend={sendRequestWithdrawal}
          symbol={'IRT'}
          withdrawalCommission={withdrawalCommission}
        />
      </Modal>
      <Modal
        isOpen={isBankAccountModalOpen}
        setIsOpen={setIsBankAccountModalOpen}
        className={'xpay-bank-accounts-modal'}
      >
        <Card
          title={'شماره شبای بانکی جهت برداشت را انتخاب کنید:'}
          href={'/dashboard/bank-accounts/add-iban'}
          hrefTitle={'افزودن شماره شبا'}
          divider
        >
          <ul>
            {bankAccountsLoading ? (
              <LoadingOutlined />
            ) : (
              bankAccounts?.map((item, index) => (
                <>
                  <li
                    onClick={() => {
                      setSelectedCard(item);
                      setIsBankAccountModalOpen(false);
                    }}
                    key={index}
                  >
                    <div className={'title'}>
                      <img src={`/banks/${item.bank.code.toLowerCase()}.svg`} />
                      <span>{item.bank.name}</span>
                    </div>
                    <span dir="ltr">{item.iBan}</span>
                    <BackIcon />
                  </li>
                  {index !== bankAccounts.length - 1 && <Divider />}
                </>
              ))
            )}
          </ul>
        </Card>
      </Modal>
      <div className={'withdrawal-box'}>
        <div className={'financial-info'}>
          <div className={'info'}>
            <p>موجودی فعلی شما:</p>
            <span>
              {wallet ? (
                digitSeparator(wallet?.balance.toFixed(0))
              ) : (
                <LoadingOutlined />
              )}{' '}
              تومان
            </span>
          </div>
          <div className={'info'}>
            <p>سقف برداشت روزانه شما:</p>
            <span>
              {maxIrtWithdrawal ? (
                digitSeparator(maxIrtWithdrawal)
              ) : (
                <LoadingOutlined />
              )}{' '}
              تومان
            </span>
          </div>
        </div>
        <Divider />
        <Input
          value={digitSeparator(withdrawalAmount)}
          placeholder="مبلغ برداشتی را به تومان وارد کنید"
          type="text"
          dir={withdrawalAmount === '' ? 'rtl' : 'ltr'}
          name="withdrawalAmount"
          onChange={(e) => onWithdrawalAmountChange(e.target.value)}
          adorments={{
            endAdornment: {
              adornmentIcon: (
                <div className={'adornments'}>
                  <Button
                    type={'card'}
                    text={'انتخاب تمام موجودی'}
                    onClick={() => {
                      wallet && setWithdrawalAmount(wallet.balance.toString());
                      setSliderValue(100);
                    }}
                  />
                  <div className={'irt-container'}>
                    <span className={'en'}>IRT</span>
                    <img
                      src={getIconPath('irt')}
                      alt=""
                      width={32}
                      height={32}
                    />
                  </div>
                </div>
              ),
            },
          }}
        />
        <Slider
          marks={{
            0: 'حداقل',
            25: '25٪',
            50: '50٪',
            75: '75٪',
            100: '100٪',
          }}
          value={sliderValue}
          step={1}
          tooltip={{
            placement: 'bottom',
            formatter: (value) => {
              return `${value}٪`;
            },
          }}
          trackStyle={{ background: '#3F62FE' }}
          onChange={(sliderValue) => onChangeSlider(sliderValue)}
        />
        <div
          className={`choose-card ${selectedCard ? 'chosen' : ''}`}
          onClick={() => setIsBankAccountModalOpen(true)}
        >
          {selectedCard ? (
            <>
              <div className={'chosen-card'}>
                <img
                  src={`/banks/${selectedCard.bank.code.toLowerCase()}.svg`}
                />
                <span>{selectedCard.bank.name}</span>
                <span dir="ltr">{selectedCard.iBan}</span>
              </div>
              <SilverChevronIcon />
            </>
          ) : (
            <>
              <p>شماره شبای بانکی خود را برای پرداخت از درگاه انتخاب کنید</p>
              <BlueChevronIcon />
            </>
          )}
        </div>

        <Button
          type={'primary'}
          text={'تایید برداشت'}
          disabled={
            loading || withdrawalAmount === '0' || withdrawalAmount === ''
          }
          onClick={onSubmitWithdrawal}
          loading={loading}
        />
      </div>
      <Collapse
        items={ALERT_ITEMS}
        defaultActiveKey={'1'}
        className={'alert-collapse'}
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? (
            <Button
              type={'primary'}
              icon={<EyeInvisibleOutlined />}
              text={'بستن لیست'}
            />
          ) : (
            <Button
              type={'primary'}
              icon={<EyeOutlined />}
              text={'نمایش لیست'}
            />
          )
        }
      />
      {/*<FAQ />*/}
    </div>
  );
};
