import React from 'react';
import './action-button.scss';

interface ActionButtonProps {
  icon: React.ReactNode;
  text: string;
  link: string;
  onClick?: () => void;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  text,
  link,
  onClick,
}) => {
  return (
    <a
      className="action-button"
      href={link}
      target="_self"
      rel="noopener noreferrer"
      onClick={(e) => {
        if (onClick) {
          e.preventDefault(); // Prevent navigation if onClick is provided
          onClick(); // Call the onClick handler
        }
      }}
    >
      <div className="icon-container">{icon}</div>
      <span>{text}</span>
    </a>
  );
};
