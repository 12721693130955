type apiParams = string | number;

export const __API = {
  token: 'general/signin/refresh-token',
  login: 'general/signin',
  verify_2fa: 'general/verify-2fa',
  verify: 'general/verify',
  signup: 'general/signup',
  signupVerify: 'general/signup-verify',
  forgotPassword: 'general/forgot-password',
  verifyForgotPassword: 'general/verify-forgot-password',
  setPassword: 'general/set-password',
  changePassword: 'general/change-password',
  policies: {
    amount: 'sitesettings/currency',
  },
  notification: {
    getMine: 'notification/getmine',
    read: 'notification/read',
    reasonsEnum: 'enum/notificationreasons',
  },
  referral: {
    getMineReferralInfo: 'referral/get-mine-referral-info',
    getMineReferralActions: 'referral/get-mine-referral-actions/',
    getMineInvitedUsers: 'referral/get-mine-invited-users/',
    withdrawal: 'referral/request-widthrawal-referral',
  },
  profile: {
    sendOtp: 'general/send-otp',
    setupOtp: 'general/setup-2fa',
    disableOtp: 'general/disable-2fa',
    getQrCode: 'general/2fa-qrcode',
    getProfile: 'general/get-profile',
    setEmailAddress: 'general/email/edit',
  },
  bankAccount: {
    mine: 'bankaccount/mine',
    addCard: 'bankaccount/add',
    delete: 'bankaccount/delete',
  },
  ticket: {
    add: 'ticket/add',
    mine: 'ticket/mine',
    get: 'ticket/get',
    answer: 'ticket/answer',
  },
  orders: {
    mine: 'order/get-mine/',
    create: 'order/create',
    commisions: 'siteSettings/commissions',
  },
  level: {
    mine: 'level/mine',
    getAll: 'level/list',
    hasLandline: 'level/userhaslandline',
    hasAddressAndPostalCode: 'level/userhasaddressandpostalcode',
    requestUpgradeToLevelOne: 'level/request-upgrade-to-1',
    verifyUpgradeToLevelOne: 'level/verify-upgrade-to-1',
    callLandLineUpgradeToLevelTwo: 'level/calllandline-upgrade-to-2',
    verifyLandLineUpgradeToLevelTwo: 'level/verifylandline-upgrade-to-2',
    addAddressAndPostalCodeUpgradeToLevelTwo: 'level/addaddress-upgrade-to-2',
    uploadFilesUpgradeToLevelTwo: 'level/uploadfiles-upgrade-to-2',
  },
  markets: {
    getAll: 'market/get-all',
    priceList: 'market/price-list',
    getAllNetworks: 'market/networks/',
    getAllPrices: 'market/all-market-prices/',
    exchange: 'market/exchange',
    marketPrice: 'market/marketprice',
  },
  popup: {
    getActiveMinePopup: 'popup/getactivepopups',
  },
  transactions: {
    crypto: {
      detail: 'transaction/crypto-details',
    },
    perfectMoney: {
      buy: 'transaction/buy/perfectmoney',
      sell: 'transaction/sell/perfectmoney',
      verify: 'transaction/buy/perfectmoney/verify',
    },
    recent: 'transaction/recent',
    mine: 'transaction/mine',
    depositLimit: 'transaction/get-mine-deposit-limit',
    cancel: 'transaction/cancel',
  },
  fiats: {
    getAll: 'fiat/getallfiats',
  },
  deposit: {
    perfectMoney: 'transaction/sell/perfectmoney',
    currency: {
      pay: 'transaction/deposit/currency/pay',
      verify: 'transaction/deposit/currency/verify',
    },
    cashIn: {
      request: 'transaction/cash-in-request',
      cashInActivationSetting: 'admin/sitesettings/cashinactivation',
    },
    crypto: {
      step1: 'transaction/deposit/crypto',
      step2: 'transaction/deposit/crypto-step2',
      networksAndWallets: 'market/networks/deposit/',
    },
  },
  widthrawl: {
    currency: 'transaction/withdrawal/currency/request',
    verifyCurrency: 'transaction/withdrawal/currency/verify',
    crypto: 'transaction/withdrawal/crypto/',
    verifyCrypto: 'transaction/withdrawal/crypto/verify/',
  },
  wallet: {
    assets: 'wallet/mine',
    balance: 'wallet/mine/balance',
    pairBalance: 'wallet/mine/',
    sync: 'wallet/mine/sync',
  },
  authentication: {
    upgradeToLevel1: 'level/upgrade-to-1',
    upgradeToLevel2: 'level/upgrade-to-2',
    upgradeToLevel3: 'level/upgrade-to-3',
    upgradeToLevel4: 'level/upgrade-to-4',
    verifyUpgradeToLevel4: 'level/verify-upgrade-to-4',
  },
  siteSettings: {
    commissions: 'sitesettings/commissions',
  },
  validations: {
    settings: 'sitesettings/user-validation',
  },
};
