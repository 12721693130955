import { Divider, Spin, Statistic } from 'antd';
import { Card } from 'components';
import { Input } from 'antd';
import * as React from 'react';
import './wallet.scss';
import { Asset } from 'core/domains/markets/asset';
import { getMineAssets } from 'core/repositories/wallet/wallet';
import { digitSeparator, digitSperator } from '../../../utils/scripts/exchange';
import { WalletFilterValues } from 'core/domains/wallet/wallet';
import { ReactComponent as BlueRectangle } from 'assets/icons/wallet/BlueRectangle.svg';
import { ReactComponent as GreenDot } from 'assets/icons/wallet/GreenDot.svg';
import { ReactComponent as GreenRectangle } from 'assets/icons/wallet/GreenRectangle.svg';
import { ReactComponent as Info } from 'assets/icons/wallet/Info.svg';
import { ReactComponent as Search } from 'assets/icons/wallet/Search.svg';
import { WalletItem } from './wallet-item/wallet-item';
import Progress from 'antd/es/progress';
import { getViaAuth, responseValidator } from '../../../utils/scripts/api';
import { useEffect, useState } from 'react';
import { Spinner } from 'components/spinner/spinner';
import { SyncOutlined } from '@ant-design/icons';

export const Wallet: React.FC = () => {
  const { Countdown } = Statistic;

  const [search, setSearch] = React.useState<string>('');
  const [assets, setAssets] = React.useState<Asset>();
  const [assetsResult, setAssetsResult] = React.useState<Asset>();
  const [loading, setLoading] = React.useState<boolean>();
  const [syncLoading, setSyncLoading] = useState<boolean>(false);

  const [percentages, setPercentages] = useState({ irt: 0, crypto: 0 });

  const onMount = async () => {
    !assets &&
      (await getMineAssets(setLoading, setAssets, { amountSort: 2 }, ''));
    setAssetsResult(assets);
    handleProgress();
    setSearch('');
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    if (assets) {
      const filteredAssets = assets.assets.filter(
        (asset) =>
          asset.symbol.toLowerCase().startsWith(value.toLowerCase()) ||
          asset.name.toLowerCase().startsWith(value.toLowerCase()),
      );
      setAssetsResult((prevAssetsResult) => ({
        ...prevAssetsResult!,
        assets: filteredAssets,
      }));
    }
  };

  const handleSync = () => {
    getMineAssets(setSyncLoading, setAssets, { amountSort: 2 }, '');
  };

  const handleProgress = () => {
    if (assets) {
      const cryptoDollarValue = assets?.assets
        .filter((asset) => asset.symbol.toUpperCase() !== 'IRT')
        .reduce((acc, asset) => acc + asset.balance * asset.lastPrice, 0);

      const irtDollarValue = assets?.usdtBalance - cryptoDollarValue;

      const usdtBalance = assets.usdtBalance || 0.01;

      const irtPercent = (irtDollarValue / usdtBalance) * 100;
      const cryptoPercent = (cryptoDollarValue / usdtBalance) * 100;

      setPercentages({
        irt: irtPercent ?? 0,
        crypto: cryptoPercent ?? 0,
      });
    }
  };

  useEffect(() => {
    onMount();
  }, [assets]);

  return (
    <div className="xpay-wallet-page">
      <Spinner loading={loading}>
        <Card className="balance-card">
          <div className="right-side">
            <div className="col">
              <h3>مجموع کل دارایی</h3>
              <h4>{digitSperator(assets?.irtBalance.toFixed(0))} تومان</h4>
            </div>
            <div className="values">
              <div className="col">
                <span>
                  <GreenDot /> ارزش رمز ارز (دلاری)
                </span>
                <span>
                  {assets?.usdtBalance !== undefined
                    ? digitSperator(
                        (
                          assets?.usdtBalance! *
                          (percentages.crypto / 100)
                        )?.toFixed(2),
                      )
                    : 0}{' '}
                  دلار
                  <span className={'mobile-show'}>
                    {' '}
                    ={' '}
                    {assets?.irtBalance
                      ? digitSperator(
                          (
                            assets?.irtBalance *
                            (percentages.crypto / 100)
                          )?.toFixed(0),
                        )
                      : 0}{' '}
                    تومان
                  </span>
                </span>
              </div>
              <div className="col desktop-show">
                <span>
                  <GreenDot /> ارزش رمز ارز (تومانی)
                </span>
                <span>
                  {assets?.irtBalance
                    ? digitSperator(
                        (
                          assets?.irtBalance *
                          (percentages.crypto / 100)
                        )?.toFixed(0),
                      )
                    : 0}{' '}
                  تومان
                </span>
              </div>
              <div className="col">
                <span>
                  <GreenDot /> موجودی تومانی
                </span>
                <span>
                  {digitSeparator(
                    assets?.assets
                      ?.find((asset) => asset.symbol.toUpperCase() === 'IRT')
                      ?.balance?.toFixed(0),
                  ) ?? 0}{' '}
                  تومان
                </span>
              </div>
            </div>
          </div>
          <Divider type="vertical" />
          <div className="middle-side">
            <div className="col">
              <span>
                <BlueRectangle /> موجودی تومانی - {percentages?.irt.toFixed(1)}٪
              </span>
              <span>
                <GreenRectangle /> موجودی رمز ارز -{' '}
                {percentages?.crypto.toFixed(1)}٪
              </span>
            </div>
          </div>
          <div className="left-side">
            <Progress
              percent={percentages?.irt}
              strokeColor={'rgb(21, 82, 240)'}
              trailColor={'rgb(64, 197, 242)'}
              type="circle"
              strokeWidth={10}
              format={() => <></>}
              showInfo
            />
          </div>
        </Card>
      </Spinner>

      <Spinner loading={loading}>
        <Card className="currencies-card">
          <div className="header">
            <div className="right-side">
              <Input
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="نام و یا نماد رمز ارز مورد نظر خود را وارد کنید..."
                suffix={<Search />}
              />
              <div className={'sync-icon-mobile'} onClick={handleSync}>
                <SyncOutlined spin={syncLoading} />
              </div>
            </div>
            {!assets?.withdrawalEnable && (
              <div className="left-side">
                <>
                  <span>
                    <Info /> تا باز شدن قفل برداشت:
                  </span>
                  <Countdown value={assets?.withdrawalDisableTill} />
                </>
              </div>
            )}
            <div className={'sync-icon-desktop'} onClick={handleSync}>
              <SyncOutlined spin={syncLoading} />
            </div>
          </div>
          <Spinner loading={syncLoading}>
            <div className="wallet-list">
              {assetsResult &&
                assetsResult.assets.map((result) => (
                  <WalletItem
                    name={result.name}
                    symbol={result.symbol}
                    unitBalance={result.balance}
                    dollarBalance={result.balance * result.lastPrice}
                    irtBalance={result.value}
                    buttons={{
                      withdraw:
                        result.symbol.toUpperCase() === 'PM'
                          ? false
                          : result.isWidthrawEnable,
                      deposit:
                        result.symbol.toUpperCase() === 'PM'
                          ? false
                          : result.isDepositEnable,
                      trade: result.isTradeEnable,
                    }}
                  />
                ))}
            </div>
          </Spinner>
        </Card>
      </Spinner>
    </div>
  );
};
