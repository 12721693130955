import * as React from 'react';
import './card.scss';
import { Divider, Spin } from 'antd';
import { XpayComponent } from 'core/domains';
import { Link } from 'react-router-dom';
import { Spinner } from '../spinner/spinner';

interface Props extends XpayComponent {
  title?: string;
  children: React.ReactNode;
  tags?: React.ReactNode;
  blur?: boolean;
  shadow?: boolean;
  style?: any;
  ref?: any;
  href?: any;
  hrefTitle?: any;
  divider?: any;
  button?: any;
}

export const Card: React.FC<Props> = (props: Props) => {
  return (
    <div
      ref={props.ref}
      style={props.style}
      className={`xpay-panel-card 
            ${props.shadow ? 'shadowed' : ''} 
            ${props.blur ? 'loading' : ''} 
            ${props.className ? props.className : ''}`}
    >
      {props.title ? (
        <div className="head">
          <h3>{props.title}</h3>
          {props.tags && props.tags}
          {props?.href && props?.hrefTitle ? (
            <Link to={props?.href}>{props?.hrefTitle}</Link>
          ) : (
            <></>
          )}
          {props?.button}
        </div>
      ) : (
        <></>
      )}

      {props.divider ? (
        <Divider
          className="card-divider"
          style={{ margin: '0', gridArea: 'unset' }}
        />
      ) : (
        <></>
      )}

      <div className="card-body">
        {props.blur ? (
          <Spinner loading={props.blur ? props.blur : false}>
            {props.children}
          </Spinner>
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};
