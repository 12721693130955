import * as React from 'react';
import { useState } from 'react';
import { BottomMenu, Navbar, Sidebar } from 'components';
import './dashboard.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { SidebarItems } from 'utils/constants/common';
import { useAppDispatch } from 'core/redux/hooks';
import { setMenuItem, setProfileData } from 'core/redux/actions';
import { isLogin } from '../../utils/scripts/api';
import { getMineProfile } from '../../core/repositories/profile/profile';
import { PopUp } from '../../components/pop-up/pop-up';
import {
  getPopUpDetailsForShow,
  logShowPopUp,
  Popup,
} from '../../core/repositories/popups/popup';
import { APPNAME, SERVER_ADDRESS } from '../../apis/constants';
import { Helmet } from 'react-helmet';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  component: React.ReactElement;
  title: string;
}

const Dashboard: React.FC<Props> = (props) => {
  const [title, setTitle] = React.useState<string>('');

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.trim();
  React.useEffect(() => {
    if (!isLogin()) navigate('/login');
    SidebarItems.map((item, index) => {
      path.includes(item?.link) &&
        path === item.link &&
        dispatch(setMenuItem(item.id));
    });
  }, [location.pathname]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(true);
  const [isOpen, setIsOpen] = useState<any>(true);
  const [popupData, setPopUpData] = useState<Popup>();

  const storedSidebar = localStorage.getItem('sidebar');
  const [sidebarOpen, setSidebarOpen] = useState(
    storedSidebar ? JSON.parse(storedSidebar) : true,
  );

  const fetchProfileData = async () => {
    await getMineProfile(setIsLoading, (data: any) => {
      dispatch(setProfileData(data));
      setProfile(data);
      setIsLoading(false);
    });
  };

  React.useEffect(() => {
    if (!window.location.href.includes('verify')) {
      fetchProfileData();
      var popupRes = getPopUpDetailsForShow().then((res) => {
        res.map((popup: any) => {
          if (popup.urlPage === path) {
            setPopUpData(popup);
            logShowPopUp(popup);
          }
        });
      });
    } else setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (location.pathname.split('/')[0].includes('signin')) setTitle('ورود');
    else setTitle(props.title);
  }, [location.pathname]);
  return (
    <>
      <Helmet>
        <title>
          {title || ''} {title && '|'} {APPNAME}
        </title>
        <meta
          name="description"
          content="خرید و فروش اتوماتیک و آسان پرفکت مانی ، تتر و رمز ارز ها احراز هویت هوشمند و سریع زیر ۱ دقیقه بدون معطلی نرخ های رقابتی و بدون کارمزد"
        />
      </Helmet>
      <div className="xpay-container">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          items={SidebarItems}
        />
        {popupData && (
          <PopUp
            description={popupData.description}
            isOpen={isOpen}
            linkTo={popupData.linkTo}
            image={
              popupData.imageUrl ? (
                <img src={SERVER_ADDRESS + popupData.imageUrl} />
              ) : null
            }
            title={popupData.title}
            setIsOpen={setIsOpen}
          />
        )}
        {!isLoading && <Navbar sidebarOpen={sidebarOpen} profile={profile} />}
        {!isLoading && (
          <div
            className={`xpay-dashboard ${
              path.replaceAll('/', '') === 'dashboard' ? '' : 'nav-hide'
            }`}
          >
            {props.component}
          </div>
        )}

        <BottomMenu />
      </div>
    </>
  );
};

export default connector(Dashboard);
