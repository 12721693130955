import { DownOutlined } from '@ant-design/icons';
import { Button, Card } from 'components';
import { Badge } from 'components/badge/badge';
import { Chart } from 'components/chart/chart';
import { Table } from 'components/table/table';
import { ConfigProvider, Pagination } from 'antd';
import {
  MarketFilterValues,
  PaginationList,
  PriceTableMarket,
} from 'core/domains';
import { useEffect, useState } from 'react';
import { toFixed, ToIranCurrency } from 'utils/scripts/decimal';
import './markets.scss';
import { getIconPath } from 'utils/scripts/getIconPath';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SortIcon } from 'assets/icons/orders/Sort.svg';
import { ReactComponent as Search } from 'assets/icons/wallet/Search.svg';
import { _dateSorts } from '../orders/my-orders/filters/constants';
import { Dropdown, MenuProps, Spin } from 'antd';
import { ToItemTypes } from 'utils/scripts/table';
import { getPrices } from 'core/repositories';
import { TableColumn } from 'react-data-table-component';
import { Spinner } from '../../../components/spinner/spinner';
import faIR from 'antd/lib/locale/fa_IR';

const empty_filters = {
  keywords: '',
  sort: {
    id: 0,
    title: 'مرتب سازی',
    icon: <SortIcon />,
  },
};

export const Markets: React.FC = () => {
  const [filterText, setFilterText] = useState('');
  const [markets, setMarkets] = useState<PriceTableMarket[]>();
  const [marketResults, setMarketResults] = useState<PriceTableMarket[]>();
  const [search, setSearch] = useState<string>('');
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const navigate = useNavigate();

  useEffect(() => {
    getPrices(setCardIsLoading, setMarkets, setMarketResults);
  }, []);

  useEffect(() => {
    if (marketResults?.length! > pageSize) {
      handlePageChange(1, 10);
    }
  }, [marketResults]);

  // const clickOnSort: MenuProps['onClick'] = ({ key }) => {
  //   setFilters({
  //     ...filters,
  //     sort: _dateSorts.filter((t) => t.id === parseInt(key))[0],
  //   });
  // };

  const handleButtonClick = (side: string, symbol: string) => {
    navigate(`/dashboard/orders/create-order?side=${side}&symbol=${symbol}`);
  };

  const columns: TableColumn<PriceTableMarket>[] = [
    {
      name: '#',
      id: 'index',
      selector: (row, rowIndex) => (rowIndex ? rowIndex + 1 : 1),
      width: '50px',
    },
    {
      name: 'نام ارز',
      id: 'name',
      cell: (row: PriceTableMarket) => (
        <span className="d-flex">
          <img src={getIconPath(row.symbol)} alt="" />
          <p className="market">
            {row.name}
            <span className="symbol">{row.symbol}</span>
          </p>
        </span>
      ),
    },
    {
      name: 'قیمت دلاری',
      id: 'last_usdt_price',
      selector: (row: PriceTableMarket) => {
        if (row.weeklyPriceLog) {
          const lastPrice = row.weeklyPriceLog[row.weeklyPriceLog.length - 1];
          if (!isNaN(lastPrice)) {
            const fixedValue = lastPrice.toFixed(12);
            return parseFloat(fixedValue).toString();
          }
        }
        return '-';
      },
    },
    {
      name: 'قیمت خرید (تومان)',
      id: 'buyPrice',
      hide: 720,

      selector: (row: PriceTableMarket) => row.buyPrice,
      cell: (row: PriceTableMarket) => ToIranCurrency(row.buyPrice),
    },
    {
      name: 'قیمت فروش (تومان)',
      id: 'sellPrice',
      hide: 720,
      selector: (row: PriceTableMarket) => row.sellPrice,
      cell: (row: PriceTableMarket) => ToIranCurrency(row.sellPrice),
    },
    {
      name: 'تغییرات (24 ساعت)',
      id: 'change24h',
      // hide: 'md',
      selector: (row: PriceTableMarket) => row.changeOf24HourPercent,
      cell: (row: PriceTableMarket) =>
        row.weeklyPriceLog ? (
          <Badge
            text={`${row.changeOf24HourPercent.toFixed(2)}٪`}
            type={row.changeOf24HourPercent > 0.0 ? 'success' : 'error'}
          />
        ) : (
          '-'
        ),
    },
    {
      name: 'نمودار تغییرات (24 ساعت)',
      id: 'chart',
      hide: 720,
      cell: (row: PriceTableMarket) =>
        row.weeklyPriceLog ? (
          <Chart data={row.weeklyPriceLog} />
        ) : (
          <span>-</span>
        ),
    },
    {
      name: 'معامله',
      id: 'create-order',
      cell: (row: PriceTableMarket) => (
        <div className="currency-buttons">
          <Button
            className="buy"
            type="outlined"
            text="خرید"
            onClick={() => handleButtonClick('buy', row.symbol.toLowerCase())}
          />
          <Button
            className="sell"
            type="outlined"
            text="فروش"
            onClick={() => handleButtonClick('sell', row.symbol.toLowerCase())}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);

    const startIndex = (page - 1) * size;
    const endIndex = startIndex + size;
    console.log({ startIndex, endIndex });

    setMarketResults(markets?.slice(startIndex, endIndex)!);
  };

  const onSearch = (value: string) => {
    setSearch(value);

    // Filter the markets based on the search value
    const filteredMarkets = markets?.filter(
      (market) =>
        market.symbol.toLowerCase().includes(value.toLowerCase()) ||
        market.name.toLowerCase().includes(value.toLowerCase()),
    );

    // Update the marketResults with the filtered markets
    setMarketResults(filteredMarkets || []);

    // Reset to the first page when searching
    setCurrentPage(1);
  };

  return (
    <Card className="xpay-markets-dashboard">
      <Spinner loading={cardIsLoading}>
        <div className="desktop-show">
          <div className="filter-row">
            <div className="fields">
              <div className="right-side">
                <div className="field">
                  <Search />
                  <input
                    type="text"
                    value={search}
                    placeholder="نام و یا نماد رمز ارز مورد نظر خود را وارد کنید..."
                    className="search"
                    name="search"
                    onChange={(e) => onSearch(e.target.value)}
                  />
                </div>

                {/*<Dropdown*/}
                {/*  trigger={['click']}*/}
                {/*  menu={{*/}
                {/*    items: ToItemTypes(_dateSorts),*/}
                {/*    onClick: clickOnSort,*/}
                {/*  }}*/}
                {/*  placement="bottom"*/}
                {/*>*/}
                {/*  <Button*/}
                {/*    icon={<DownOutlined />}*/}
                {/*    type="outlined"*/}
                {/*    text={filters?.sort?.title}*/}
                {/*  />*/}
                {/*</Dropdown>*/}
              </div>

              {/*<div className="buttons">*/}
              {/*  <Button*/}
              {/*    className="search"*/}
              {/*    type="outlined"*/}
              {/*    text="اعمال فیلتر"*/}
              {/*    onClick={() => {}}*/}
              {/*  />*/}
              {/*  <Button*/}
              {/*    className="clear-search"*/}
              {/*    type="outlined"*/}
              {/*    text="حذف فیلتر"*/}
              {/*    onClick={() => {*/}
              {/*      setFilters(empty_filters);*/}
              {/*      getPrices(setCardIsLoading, setMarkets, setMarketResults);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
          {markets && !cardIsLoading && (
            <>
              <Table
                data={marketResults!}
                className="price-chart-table"
                column={columns}
                hasFiltering={true}
                blur={cardIsLoading}
                setTableLoading={setCardIsLoading}
                getData={getPrices}
                query={query}
                // hasSearchbar
                // searchFilterItems={['symbol', 'name']}
              />
              <ConfigProvider locale={faIR}>
                <Pagination
                  style={{
                    marginInline: 'auto',
                    marginTop: '32px',
                    direction: 'ltr',
                  }}
                  showSizeChanger
                  current={currentPage}
                  pageSize={pageSize}
                  total={
                    search === '' ? markets?.length : marketResults?.length
                  }
                  onChange={handlePageChange}
                  onShowSizeChange={(current, size) => {
                    setPageSize(size);
                    handlePageChange(1, size);
                    setSearch('');
                  }}
                />
              </ConfigProvider>
            </>
          )}
        </div>
      </Spinner>
      <div className="mobile-show">
        <div className="filter-row">
          <div className="fields">
            <div className="right-side">
              <div className="field">
                <Search />
                <input
                  type="text"
                  value={search}
                  placeholder="نام و یا نماد رمرز ارز مورد نظر خود را وارد کنید..."
                  className="search"
                  name="search"
                  onChange={(e) => onSearch(e.target.value)}
                />
              </div>

              {/*<Dropdown*/}
              {/*  trigger={['click']}*/}
              {/*  menu={{ items: ToItemTypes(_dateSorts), onClick: clickOnSort }}*/}
              {/*  placement="bottom"*/}
              {/*>*/}
              {/*  <Button*/}
              {/*    icon={<DownOutlined />}*/}
              {/*    type="outlined"*/}
              {/*    text={filters?.sort?.title}*/}
              {/*  />*/}
              {/*</Dropdown>*/}
            </div>

            {/*<div className="buttons">*/}
            {/*  <Button*/}
            {/*    className="search"*/}
            {/*    type="outlined"*/}
            {/*    text="اعمال فیلتر"*/}
            {/*    onClick={() => {}}*/}
            {/*  />*/}
            {/*  <Button*/}
            {/*    className="clear-search"*/}
            {/*    type="outlined"*/}
            {/*    text="حذف فیلتر"*/}
            {/*    onClick={() => {*/}
            {/*      setFilters(empty_filters);*/}
            {/*      getPrices(setCardIsLoading, setMarkets, setMarketResults);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
        {markets?.length &&
          markets.length > 0 &&
          marketResults?.map((item) => (
            <Card blur={cardIsLoading} className="coin-card">
              <div>
                <div className="right">
                  <img src={getIconPath(item.symbol)} alt="" />
                  <p>{item.name}</p>
                  <span className={'en'}>{item.symbol}</span>
                </div>
                <div className="left">
                  <p className={'en'}>
                    {item.weeklyPriceLog
                      ? item.weeklyPriceLog[item.weeklyPriceLog.length - 1]
                      : '-'}
                    $
                  </p>
                </div>
              </div>
              <div>
                <p className="right">قیمت فروش:</p>
                <p className="left">{ToIranCurrency(item.buyPrice)} تومان</p>
              </div>
              <div>
                <p className="right">قیمت خرید:</p>
                <p className="left">{ToIranCurrency(item.sellPrice)} تومان</p>
              </div>
              <div>
                <div className="right">
                  <p>
                    تغییرات<span className={'en'}>(24h)</span>:
                  </p>
                  <span
                    className={`${
                      item.changeOf24HourPercent > 0 ? 'success' : 'danger'
                    }`}
                  >
                    {item.changeOf24HourPercent.toFixed(1)}٪
                  </span>
                </div>
                <div className="left">
                  {item.weeklyPriceLog ? (
                    <Chart data={item.weeklyPriceLog} />
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <Button
                onClick={() =>
                  navigate(
                    `/dashboard/orders/create-order?side=buy&symbol=${item.symbol.toLowerCase()}`,
                  )
                }
                text="خرید و فروش"
                type="info"
              />
            </Card>
          ))}
        <ConfigProvider locale={faIR}>
          <Pagination
            style={{
              marginInline: 'auto',
              marginTop: '32px',
              direction: 'ltr',
            }}
            showSizeChanger
            current={currentPage}
            pageSize={pageSize}
            total={search === '' ? markets?.length : marketResults?.length}
            onChange={handlePageChange}
            onShowSizeChange={(current, size) => {
              setPageSize(size);
              handlePageChange(1, size);
              setSearch('');
            }}
          />
        </ConfigProvider>
      </div>
    </Card>
  );
};
