import * as React from 'react';
import './alert.scss';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-alert-icon.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-alert.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-alert.svg';
import { WarningOutlined } from '@ant-design/icons';
import { XpayComponent } from 'core/domains';

interface Props extends XpayComponent {
  type: 'success' | 'danger' | 'warning' | 'info';
  message: string | React.ReactNode;
  title?: string;
  noIcon?: boolean;
  closeAble?: boolean;
  iconOnTop?: boolean;
  outlinedIcon?: boolean;
  customIcon?: React.ReactNode;
}

export const Alert: React.FC<Props> = (props) => {
  const [isClosed, setIsClosed] = React.useState<boolean>(false);

  const renderIcon = () => {
    switch (props.type) {
      case 'success':
        return <SuccessIcon />;
      case 'warning':
        if (props?.customIcon) return props.customIcon;
        if (props?.outlinedIcon) return <WarningIcon />;
        if (!props?.outlinedIcon) return <WarningOutlined />;
        break;
      case 'info':
        return (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="48"
              height="48"
              rx="10"
              fill="url(#paint0_linear_0_1)"
            />
            <path
              d="M24 38C16.258 38 10 31.7266 10 24C10 16.2706 16.258 10 24 10C31.728 10 38 16.2706 38 24C38 31.7266 31.728 38 24 38ZM24 19.8966C24.672 19.8966 25.218 19.3506 25.218 18.6786C25.218 18.0066 24.672 17.4466 23.986 17.4466C23.314 17.4466 22.768 18.0066 22.768 18.6786C22.768 19.3506 23.314 19.8966 24 19.8966ZM24 30.538C24.672 30.538 25.232 29.9766 25.232 29.306L25.232 23.118C25.232 22.4446 24.672 21.9 24 21.9C23.328 21.9 22.782 22.4446 22.782 23.118L22.782 29.306C22.782 29.9766 23.328 30.538 24 30.538Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_0_1"
                x1="-13.5105"
                y1="2.69178e-06"
                x2="58.2718"
                y2="6.82133"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3F62FE" />
                <stop offset="1" stop-color="#678AF5" />
              </linearGradient>
            </defs>
          </svg>
        );
      case 'danger':
        return <ErrorIcon />;
      default:
        break;
    }
  };
  return (
    <div
      className={`xpay-alert ${props.type} ${props.className} ${
        isClosed ? 'd-none' : ''
      } ${props.iconOnTop && 'icon-on-top'}`}
    >
      {props.iconOnTop && (
        <>
          <div className="head-icon">
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.03953 0.235396C6.99137 -0.26732 8.20217 0.0629796 8.73408 0.961394L13.8222 9.37148C13.9342 9.62251 13.9832 9.82663 13.9972 10.0387C14.0252 10.5341 13.8432 11.0157 13.4863 11.3863C13.1293 11.7556 12.6464 11.9736 12.1215 12H1.87523C1.65827 11.9874 1.44131 11.9405 1.23834 11.8679C0.223511 11.4781 -0.266406 10.3815 0.146524 9.43093L5.26966 0.955449C5.44463 0.657519 5.71059 0.400546 6.03953 0.235396ZM6.99837 8.18174C6.66243 8.18174 6.38247 8.44598 6.38247 8.76373C6.38247 9.08015 6.66243 9.34505 6.99837 9.34505C7.33431 9.34505 7.60727 9.08015 7.60727 8.75646C7.60727 8.44003 7.33431 8.18174 6.99837 8.18174ZM6.99837 4.06026C6.66243 4.06026 6.38247 4.31724 6.38247 4.63498V6.50382C6.38247 6.82091 6.66243 7.08581 6.99837 7.08581C7.33431 7.08581 7.60727 6.82091 7.60727 6.50382V4.63498C7.60727 4.31724 7.33431 4.06026 6.99837 4.06026Z"
                fill="white"
              />
            </svg>
          </div>
        </>
      )}
      <div className="alert-box">
        <div className={`alert-right-side ${!props}`}>
          <div className="alert-icon">
            {!props.noIcon && !props.iconOnTop && renderIcon()}
          </div>
          <div className="alert-content">
            {props.title && <span className="title">{props.title}</span>}
            <span className="message">{props.message}</span>
          </div>
        </div>
        <div className="alert-left-side">
          {props.closeAble && (
            <Close
              className="closable-icon"
              onClick={() => setIsClosed(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
