import * as React from 'react';

import 'components/bottom-menu/bottom-menu.scss';
import BottomMenuItem from 'components/bottom-menu/bottom-menu-item/bottom-menu-item';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem } from '../../utils/constants/common';
import { NavigationPage } from '../navigation/navigation';
import { useEffect, useState } from 'react';
import { OrdersNavigationPage } from '../orders-navigation/orders-navigation';

type Props = {};

export const BottomMenu: React.FC<Props> = (props) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState<boolean>(false);
  const [isOrdersNavigationOpen, setIsOrdersNavigationOpen] =
    useState<boolean>(false);
  const [activeItemId, setActiveItemId] = useState<number>();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsNavigationOpen(false);
    setIsOrdersNavigationOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    if (!activeItemId) {
      setActiveItemId(
        MobileMenuItems.find((item) => item.link === location.pathname)?.id,
      );
    }
  }, [activeItemId, location.pathname]);

  const handleClick = (id: number) => {
    if (id === 1) {
      if (isNavigationOpen) {
        setIsNavigationOpen(false);
        setActiveItemId(undefined);
      } else {
        setIsNavigationOpen(true);
        setIsOrdersNavigationOpen(false);
        setActiveItemId(id);
      }
    }
    if (id === 4) {
      if (isOrdersNavigationOpen) {
        setIsOrdersNavigationOpen(false);
        setActiveItemId(undefined);
      } else {
        setIsOrdersNavigationOpen(true);
        setIsNavigationOpen(false);
        setActiveItemId(id);
      }
    }
    if (id === 2 || id === 3 || id === 5) {
      navigate(MobileMenuItems.find((item) => item.id === id)?.link!);
      setActiveItemId(id);
    }
  };

  const MobileMenuItems: MenuItem[] = [
    {
      id: 1,
      title: 'منو',
      onClick: () => handleClick(1),
      isActive: activeItemId === 1,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="18"
          viewBox="0 0 25 18"
          fill="none"
        >
          <path
            d="M10.6 17L23.4 17M1 9H23.4M10.6 1L23.4 1"
            stroke="#B3B3B3"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 2,
      title: 'کیف پول',
      link: '/dashboard/wallet',
      onClick: () => handleClick(2),
      isActive: activeItemId === 2,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="26"
          viewBox="0 0 29 26"
          fill="none"
        >
          <path
            d="M25.7109 19.2857V23C25.7109 24.1046 24.8155 25 23.7109 25H3.71094C2.60637 25 1.71094 24.1046 1.71094 23V3C1.71094 1.89543 2.60637 1 3.71094 1H23.7109C24.8155 1 25.7109 1.89543 25.7109 3V6.71429M25.7109 19.2857H15.7109C14.6064 19.2857 13.7109 18.3903 13.7109 17.2857V8.71429C13.7109 7.60972 14.6064 6.71429 15.7109 6.71429H25.7109M25.7109 19.2857H25.9967C27.1012 19.2857 27.9967 18.3903 27.9967 17.2857V8.71428C27.9967 7.60971 27.1012 6.71429 25.9967 6.71429H25.7109"
            stroke="#B3B3B3"
            stroke-width="2"
          />
          <path
            d="M20.5679 13H20.625"
            stroke="#B3B3B3"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      ),
    },
    {
      id: 3,
      title: 'خانه',
      link: '/dashboard',
      onClick: () => handleClick(3),
      isActive: activeItemId === 3,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          className={`bottom-menu-home-item ${location.pathname === '/dashboard' ? 'active' : ''}`}
        >
          <rect
            width="48"
            height="48"
            rx="10"
            fill="url(#paint0_linear_7402_9174)"
          />
          <path
            d="M23.9767 36.101V35.101V36.101ZM33.258 36.1011V35.1011V36.1011ZM34.5192 19.9889L33.8886 20.765L34.5192 19.9889ZM11.6953 21.5411V34.101H13.6953V21.5411H11.6953ZM22.0849 11.6717L12.8035 19.2128L14.0647 20.765L23.3461 13.2239L22.0849 11.6717ZM24.6073 13.2239L33.8886 20.765L35.1498 19.2128L25.8685 11.6717L24.6073 13.2239ZM34.258 21.5411V34.101H36.258V21.5411H34.258ZM33.258 35.1011L23.9767 35.101V37.101L33.258 37.1011V35.1011ZM14.6953 37.101H23.9767V35.101H14.6953V37.101ZM34.258 34.101C34.258 34.6533 33.8103 35.1011 33.258 35.1011V37.1011C34.9149 37.1011 36.258 35.7579 36.258 34.101H34.258ZM33.8886 20.765C34.1223 20.9549 34.258 21.24 34.258 21.5411H36.258C36.258 20.6377 35.851 19.7824 35.1498 19.2128L33.8886 20.765ZM23.3461 13.2239C23.7135 12.9254 24.2399 12.9254 24.6073 13.2239L25.8685 11.6717C24.7662 10.7761 23.1871 10.7761 22.0849 11.6717L23.3461 13.2239ZM11.6953 34.101C11.6953 35.7579 13.0385 37.101 14.6953 37.101V35.101C14.143 35.101 13.6953 34.6533 13.6953 34.101H11.6953ZM13.6953 21.5411C13.6953 21.24 13.831 20.9549 14.0647 20.765L12.8035 19.2128C12.1024 19.7824 11.6953 20.6377 11.6953 21.5411H13.6953Z"
            fill="white"
          />
          <path
            d="M29.6205 29.8645H18.3391"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_7402_9174"
              x1="-13.5105"
              y1="2.69178e-06"
              x2="58.2718"
              y2="6.82133"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#3F62FE" />
              <stop offset="1" stop-color="#678AF5" />
            </linearGradient>
          </defs>
        </svg>
      ),
    },
    {
      id: 4,
      title: 'سوابق',
      onClick: () => handleClick(4),
      isActive: activeItemId === 4,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6.99365 17.4992H17.4937M6.99365 6.49921H17.4937M6.99365 11.9992H17.4937"
            stroke="#B3B3B3"
            stroke-width="2"
            stroke-linecap="round"
          />
          <rect
            x="1"
            y="1"
            width="22"
            height="22"
            rx="1"
            stroke="#B3B3B3"
            stroke-width="2"
          />
        </svg>
      ),
    },
    {
      id: 5,
      title: 'معامله',
      link: '/dashboard/orders/create-order',
      onClick: () => handleClick(5),
      isActive: activeItemId === 5,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="26"
          viewBox="0 0 22 26"
          fill="none"
        >
          <path
            d="M13.5573 18.3333H20.224V25M8.22396 7.66667H1.55729V1M20.7807 9.00421C20.0331 7.15389 18.7815 5.55039 17.168 4.37598C15.5545 3.20156 13.6442 2.50336 11.6536 2.36068C9.66311 2.21799 7.67204 2.63654 5.90755 3.56882C4.14306 4.5011 2.67572 5.90971 1.67188 7.63449M1 16.9958C1.74758 18.8461 2.99924 20.4496 4.61272 21.624C6.22621 22.7984 8.13735 23.4967 10.1279 23.6394C12.1184 23.782 14.1088 23.3634 15.8733 22.4312C17.6378 21.4989 19.1045 20.0903 20.1083 18.3656"
            stroke="#B3B3B3"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  ];

  return (
    <div className="xpay-bottom-menu">
      <NavigationPage
        isOpen={isNavigationOpen}
        setIsOpen={setIsNavigationOpen}
        setActiveItemId={setActiveItemId}
      />
      <OrdersNavigationPage
        isOpen={isOrdersNavigationOpen}
        setIsOpen={setIsOrdersNavigationOpen}
        setActiveItemId={setActiveItemId}
      />
      {MobileMenuItems.map((item, index) => (
        <BottomMenuItem
          key={`bottom-menu-item-${index}`}
          id={item.id}
          icon={item.icon}
          title={item.title}
          link={item.link}
          secondaryLink={item?.secondaryLink}
          onClick={item?.onClick}
          isActive={item?.isActive}
        />
      ))}
    </div>
  );
};
