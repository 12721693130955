import { Enum } from 'core/domains';

export const _marketTypes: Enum[] = [
  {
    id: 1,
    title: 'کریپتو',
  },
  {
    id: 2,
    title: 'ریال',
  },
  {
    id: 3,
    title: 'ووچر',
  },
];

export const _types: Enum[] = [
  {
    id: 1,
    title: 'واریز',
  },
  {
    id: 2,
    title: 'برداشت',
  },
  {
    id: 3,
    title: 'واریز همکاری',
  },
  {
    id: 4,
    title: 'رفرال',
  },
  {
    id: 5,
    title: 'جایزه',
  },
];

export const _states: Enum[] = [
  {
    id: 1,
    title: 'در انتظار پرداخت',
  },
  {
    id: 2,
    title: 'انجام شده',
  },
  {
    id: 3,
    title: 'رد شده',
  },
  {
    id: 4,
    title: 'در انتظار',
  },
  {
    id: 5,
    title: 'در انتظار کد تایید',
  },
  {
    id: 6,
    title: 'در انتظار تایید ادمین',
  },
  {
    id: 7,
    title: 'در انتظار تایید ارائه دهنده',
  },
  {
    id: 8,
    title: 'لغو شده',
  },
  {
    id: 9,
    title: 'تایید دستی',
  },
];

export const _transactionStates: Enum[] = [
  {
    id: 1,
    title: 'در انتظار پرداخت',
  },
  {
    id: 2,
    title: 'انجام شده',
  },
  {
    id: 3,
    title: 'رد شده',
  },
  {
    id: 4,
    title: 'در انتظار تایید',
  },
  {
    id: 5,
    title: 'در انتظار تایید کد',
  },
  {
    id: 6,
    title: 'در انتظار تایید ادمین',
  },
  {
    id: 7,
    title: 'در انتظار تسویه شبکه',
  },
  {
    id: 8,
    title: 'لغو شده',
  },
  {
    id: 9,
    title: 'درحال انجام دستی',
  },
  {
    id: 10,
    title: 'درحال انجام',
  },
  {
    id: 11,
    title: 'آماده برداشت',
  },
  {
    id: 12,
    title: 'در انتظار ارتقا سطح',
  },
  {
    id: 13,
    title: 'در انتظار گذشت زمان و ارتقا سطح',
  },
  {
    id: 14,
    title: 'در انتظار گذشت زمان',
  },
];

export const _transactionTypes: Enum[] = [
  {
    id: 1,
    title: 'واریز',
  },
  {
    id: 2,
    title: 'برداشت',
  },
  {
    id: 3,
    title: 'واریز همکاری',
  },
  {
    id: 4,
    title: 'رفرال',
  },
  {
    id: 5,
    title: 'جایزه',
  },
];

export const _dateSorts: Enum[] = [
  {
    id: 1,
    title: 'قدیمی ترین',
  },
  {
    id: 2,
    title: 'جدید ترین',
  },
];

export const _sides: Enum[] = [
  {
    id: 1,
    title: 'خرید',
  },
  {
    id: 2,
    title: 'فروش',
  },
  {
    id: 3,
    title: 'مبادله',
  },
];
