import * as React from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import './modal.scss';

type Props = {
  isOpen: boolean;
  title?: string;
  description?: string;
  setIsOpen: any;
  className?: string;
  children: any;
  closeAble?: boolean;
  onClose?: any;
};

export const Modal: React.FC<Props> = (props) => {
  return (
    <ReactModal
      style={{
        overlay: {
          zIndex: 999,
          background: '#00000066',
        },
      }}
      shouldCloseOnEsc
      ariaHideApp={false}
      className={`xpay-modal ${props.className}`}
      isOpen={props.isOpen}
      onAfterClose={props.onClose}
      onRequestClose={() => props.setIsOpen(false)}
    >
      <div className="modal-content">
        <div className="head">
          <h4>{props.title}</h4>
          <div className="close">
            {props.closeAble && (
              <Close onClick={() => props.setIsOpen(false)} />
            )}
          </div>
        </div>
        {props.description !== '' && (
          <div className="description">
            <p>{props.description}</p>
          </div>
        )}
        {props.children}
      </div>
    </ReactModal>
  );
};
