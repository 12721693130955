import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { useLocation } from 'react-router-dom';
import './bottom-menu-item.scss';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { MenuItem } from '../../../utils/constants/common';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

const BottomMenuItem: React.FC<MenuItem> = (props) => {
  const location = useLocation();
  const path = location.pathname.trim();

  return (
    <div
      className={`xpay-bottom-menu-item ${props.isActive ? 'active' : ''}`}
      key={props.id}
      onClick={props.onClick}
    >
      {props.icon}
      <span>{props.title}</span>
    </div>
  );
};

export default connector(BottomMenuItem);
