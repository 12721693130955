import { Alert, Button, Card } from 'components';
import * as React from 'react';
import { useState } from 'react';
import './buy-pm-thankyou.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { Voucher } from 'core/domains';
import { copyClipboard, copyToClipboard } from 'utils/scripts/copyToClipboard';
import moment from 'jalali-moment';
import {
  postGeneric,
  responseValidator,
} from '../../../../../../utils/scripts/api';
import { TransactionVerify } from '../../../../../../core/domains/transactions/transactionVerify';
import { API } from '../../../../../../apis';

type Props = {
  amount?: number;
  trackingCode?: string;
  voucher?: Voucher;
  depositFromWallet: boolean;
};
export const BuyPerfectMoneyThankYouPage: React.FC<Props> = ({
  amount,
  voucher,
  trackingCode,
  depositFromWallet,
}) => {
  if (!depositFromWallet) {
    const URL = window.location;
    var urlSections = URL.pathname.split('/');
    trackingCode =
      urlSections[urlSections.length - 1].split('?').length > 0
        ? urlSections[urlSections.length - 1].split('?')[0]
        : urlSections[urlSections.length - 1];
  }

  const [cardIsLoading, setCardIsLoading] = useState<boolean>(true);

  const orderIdRef = React.useRef<HTMLElement>(null);

  const [verifyResult, setVerifyResult] = useState<boolean | null>(null);

  const [verifyData, setVerifyData] = useState<TransactionVerify | null>(null);

  const navigate = useNavigate();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  //Vandar Query parametr payment_status=OK

  //Zibal Query paramer status=1
  const payment =
    query.get('payment_status') === 'OK' || query.get('status') === '1';
  var identifier = query.get('token');

  // PayStar
  const cardNumber = query.get('card_number');
  //PayStar trackingCode
  const additionalData = query.get('tracking_code');
  const verifyTransaction = () => {
    setCardIsLoading(true);

    (async () =>
      await postGeneric<TransactionVerify>(
        API.transactions.perfectMoney.verify,
        {
          trackingCode: trackingCode,
          cardNumber: cardNumber,
          additionalData: additionalData,
        },
      ).then((response: any) => {
        setCardIsLoading(false);
        if (responseValidator(response.status)) {
          setVerifyResult(true);
          setVerifyData(response.value);
        } else {
          setVerifyResult(false);
        }
      }))();
  };
  React.useEffect(() => {
    if (!depositFromWallet) verifyTransaction();
  }, []);

  return (
    <div className="xpay-pm-thankyou-dashboard">
      {!depositFromWallet && verifyResult === null ? (
        <Card blur={cardIsLoading} className="transaction-info">
          <div className="header">
            <div className="right-side">
              <h3>درحال بارگذاری نتیجه پرداخت</h3>
              <p>
                شارژ کیف پول شما به مبلغ تومان در تاریخ با موفقیت انجام شد. از
                طریق صفحه سوابق میتوانید به تاریخچه ی شارژ کیف پول خود دسترسی
                داشته باشید و از وضعیت سفارشات خود مطلع شوید.
              </p>
            </div>
            <div className="left-side">
              <img src="/assets/images/thankyou-success.svg" alt="" />
            </div>
          </div>
          <div className="body">
            <ul>
              <li className="mobile-show">
                <div className="col">
                  <span>شناسه سفارش</span>

                  <span>
                    <span className="en" ref={orderIdRef}>
                      {trackingCode}
                    </span>

                    <CopyOutlined
                      onClick={() =>
                        copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                      }
                    />
                  </span>
                </div>

                <div className="col">
                  <span>زمان پرداخت</span>
                </div>
              </li>
              <li className="mobile-show">
                <div className="col">
                  <span>مقدار</span>
                </div>
                <div className="col">
                  <span>وضعیت</span>
                </div>
              </li>
              <li className="desktop-show">
                <span>شناسه سفارش</span>
                <span>
                  <span className="en" ref={orderIdRef}>
                    {trackingCode}
                  </span>
                  <CopyOutlined
                    onClick={() =>
                      copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                    }
                  />
                </span>
              </li>
              <li className="desktop-show">
                <span>زمان پرداخت</span>
              </li>
              <li className="desktop-show">
                <span>مقدار</span>
              </li>

              <li className="desktop-show">
                <span>وضعیت</span>
              </li>
            </ul>
          </div>
          <div className="footer">
            <Button
              type={'info'}
              text={'مشاهده سوابق'}
              onClick={() => navigate('/dashboard/orders')}
            />
            <Button
              type={'outlined'}
              text={'بازگشت به صفحه اصلی'}
              onClick={() => navigate('/dashboard/wallet')}
            />
          </div>
        </Card>
      ) : (verifyResult && verifyData) || depositFromWallet ? (
        <>
          <Alert
            title="تبریک"
            type="success"
            message="پرداخت تومانی شما با موفقیت انجام شد."
            closeAble
          />
          <Card className="transaction-info">
            <div className="header">
              <div className="left-side">
                <img src="/assets/images/thankyou-success.svg" alt="" />
              </div>
            </div>
            <div className="body">
              <ul>
                <li className="mobile-show">
                  <div className="col">
                    <span>شناسه سفارش</span>
                    <span>
                      <span>{trackingCode}</span>
                      <CopyOutlined
                        onClick={() =>
                          copyClipboard(trackingCode ?? '', 'شناسه سفارش')
                        }
                      />
                    </span>
                  </div>
                  <div className="col">
                    <span>زمان پرداخت</span>
                    <span>
                      <span>
                        {moment()
                          .locale('fa')
                          .local()
                          .format('jYYYY/MM/DD HH:MM')}{' '}
                      </span>
                    </span>
                  </div>
                </li>
                <li className="mobile-show">
                  <div className="col">
                    <span>مقدار</span>
                    <span>
                      {depositFromWallet ? amount : verifyData?.amount}
                      {depositFromWallet
                        ? ' ووچر پرفکت مانی'
                        : ' ووچر پرفکت مانی'}
                    </span>
                  </div>
                  <div className="col">
                    <span>وضعیت</span>
                    <span className="success">موفق</span>
                  </div>
                </li>
                <li className="desktop-show">
                  <span>شناسه سفارش</span>
                  <span>
                    <span className="en">{trackingCode}</span>
                    <CopyOutlined
                      onClick={() =>
                        copyClipboard(trackingCode ?? '', 'شناسه سفارش')
                      }
                    />
                  </span>
                </li>
                <li className="desktop-show">
                  <span>زمان پرداخت</span>
                  <span>
                    {' '}
                    {moment().locale('fa').local().format('jYYYY/MM/DD HH:MM')}
                  </span>
                </li>
                <li className="desktop-show">
                  <span>مقدار</span>
                  <span>
                    {depositFromWallet ? amount : verifyData?.amount} ووچر پرفکت
                    مانی
                  </span>
                </li>

                <li className="desktop-show">
                  <span>وضعیت</span>
                  <span className="success">موفق</span>
                </li>
              </ul>
              <ul>
                <li>
                  <span>کد ووچر</span>
                  <span>
                    <span>
                      {depositFromWallet
                        ? voucher?.voucher_code
                        : verifyData?.voucherCode}
                    </span>
                    <CopyOutlined
                      onClick={() =>
                        copyClipboard(
                          depositFromWallet
                            ? voucher?.voucher_code ?? ''
                            : verifyData?.voucherCode ?? '',
                          'کد ووچر',
                        )
                      }
                    />
                  </span>
                </li>
                <li>
                  <span>کد فعالسازی ووچر</span>
                  <span>
                    <span>
                      {depositFromWallet
                        ? voucher?.activation_code
                        : verifyData?.voucherActivationCode}
                    </span>
                    <CopyOutlined
                      onClick={() =>
                        copyClipboard(
                          depositFromWallet
                            ? voucher?.activation_code ?? ''
                            : verifyData?.voucherActivationCode ?? '',
                          'کد فعالسازی ووچر',
                        )
                      }
                    />
                  </span>
                </li>
              </ul>
            </div>
            <div className="footer">
              <Button
                type={'info'}
                text={'مشاهده سوابق'}
                onClick={() => navigate('/dashboard/orders')}
              />
              <Button
                type={'outlined'}
                text={'بازگشت به صفحه اصلی'}
                onClick={() => navigate('/dashboard/wallet')}
              />
            </div>
          </Card>
        </>
      ) : (
        <>
          {' '}
          <Alert
            title="تراکنش ناموفق"
            type="danger"
            message="متاسفانه پرداخت شما انجام نشد، در صورت کسر از حساب مبلغ واریزی تا ۷۲ ساعت به حساب شما برگشت داده میشود"
            closeAble
          />
          <Card blur={cardIsLoading} className="transaction-info">
            <div className="header">
              <div className="right-side">
                <h3>کیف پول شما با موفقیت شارژ نشد</h3>
                <p>
                  شارژ کیف پول شما با موفقیت انجام نشد. از طریق صفحه سوابق
                  میتوانید به تاریخچه ی شارژ کیف پول خود دسترسی داشته باشید و از
                  وضعیت سفارشات خود مطلع شوید.
                </p>
              </div>
              <div className="left-side">
                <img src="/assets/images/thankyou/danger.svg" alt="" />
              </div>
            </div>
            <div className="body">
              <ul>
                <li className="mobile-show">
                  <div className="col">
                    <span>شناسه سفارش</span>
                    <span>
                      <span className="en" ref={orderIdRef}>
                        {trackingCode}
                      </span>
                      <CopyOutlined
                        onClick={() =>
                          copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                        }
                      />
                    </span>
                  </div>
                  <div className="col">
                    <span>زمان پرداخت</span>
                    <span>
                      {' '}
                      {moment()
                        .locale('fa')
                        .local()
                        .format('jYYYY/MM/DD HH:MM')}
                    </span>
                  </div>
                </li>
                <li className="mobile-show">
                  <div className="col">
                    <span>وضعیت</span>
                    <span className="danger">ناموفق</span>
                  </div>
                </li>
                <li className="desktop-show">
                  <span>شناسه سفارش</span>
                  <span>
                    <span className="en" ref={orderIdRef}>
                      {trackingCode}
                    </span>
                    <CopyOutlined
                      onClick={() =>
                        copyToClipboard(orderIdRef.current, 'شناسه سفارش')
                      }
                    />
                  </span>
                </li>
                <li className="desktop-show">
                  <span>زمان پرداخت</span>
                  <span>
                    <span>
                      {' '}
                      {moment()
                        .locale('fa')
                        .local()
                        .format('jYYYY/MM/DD HH:MM')}
                    </span>
                  </span>
                </li>

                <li className="desktop-show">
                  <span>وضعیت</span>
                  <span className="danger">ناموفق</span>
                </li>
              </ul>
            </div>
            <div className="footer">
              <Button
                type={'info'}
                text={'مشاهده سوابق'}
                onClick={() => navigate('/dashboard/orders')}
              />
              <Button
                type={'outlined'}
                text={'بازگشت به صفحه اصلی'}
                onClick={() => navigate('/dashboard/wallet')}
              />
            </div>
          </Card>
        </>
      )}
    </div>
  );
};
