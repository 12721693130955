import './orders-navigation-item.scss';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';

interface Props {
  id: number;
  title: string;
  link?: string;
  icon?: React.ReactElement;
  active?: boolean;
  isLogout?: boolean;
  onClick?: () => void;
}

export const OrderNavigationItem: React.FC<Props> = (props) => {
  const location = useLocation();

  return (
    <div className={`xpay-order-navigation-item`} onClick={props.onClick}>
      <div className="content">
        {props.icon}
        <p className="title">{props.title}</p>
      </div>
      <LeftArrowIcon />
    </div>
  );
};
