import './action-box.scss';
import { ReactComponent as TelegramBot } from 'assets/icons/main/action-box/TelegramBot.svg';
import { ReactComponent as DownloadApplication } from 'assets/icons/main/action-box/DownloadApplication.svg';
import { ReactComponent as Blog } from 'assets/icons/main/action-box/Blog.svg';
import { ReactComponent as InstantSell } from 'assets/icons/main/action-box/InstantSell.svg';
import { ReactComponent as InstantBuy } from 'assets/icons/main/action-box/InstantBuy.svg';
import { ReactComponent as TomanWithdrawal } from 'assets/icons/main/action-box/TomanWithdrawal.svg';
import { ReactComponent as TomanDeposit } from 'assets/icons/main/action-box/TomanDeposit.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/main/action-box/Support.svg';
import { ActionButton } from './action-button/action-button';
import { APP_LINK, SITE_URL, TGBOT_ID } from '../../../../apis/constants';
import { isMobile } from 'react-device-detect';

export const ActionBox: React.FC = () => {
  return (
    <div className="action-box">
      <ActionButton
        icon={<TomanDeposit />}
        text="واریز تومانی"
        link={'/dashboard/wallet/deposit/IRT'}
      />
      <ActionButton
        icon={<TomanWithdrawal />}
        text="برداشت تومانی"
        link={'/dashboard/wallet/withdrawal/IRT'}
      />
      <ActionButton
        icon={<InstantBuy />}
        text="خرید آنی"
        link={'/dashboard/orders/create-order?side=buy'}
      />
      <ActionButton
        icon={<InstantSell />}
        text="فروش آنی"
        link={'/dashboard/orders/create-order?side=sell'}
      />
      <ActionButton icon={<Blog />} text="بلاگ" link={SITE_URL + '/blog/'} />
      <ActionButton
        icon={<DownloadApplication />}
        text="دانلود اپلیکیشن"
        link={APP_LINK}
      />
      <ActionButton icon={<TelegramBot />} text="ربات تلگرام" link={TGBOT_ID} />
      {isMobile && (
        <ActionButton
          icon={<SupportIcon />}
          text={'پشتیبانی'}
          link={''}
          onClick={() => window.Goftino.toggle()}
        />
      )}
    </div>
  );
};
