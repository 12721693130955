const USER_DATA = 'USER_DATA';
const ORDER_DATA = 'ORDER_DATA';
const MENU = 'MENU';
const SIDEBAR = 'SIDEBAR';
const PROFILE_DATA = 'PROFILE_DATA';
const NOTIFICATIONS = 'NOTIFICATIONS';

const setUserData = (payload: any) => {
  return { type: USER_DATA, payload: payload };
};

const setNotifications = (payload: any) => {
  return { type: NOTIFICATIONS, payload: payload };
};

const setOrderData = (payload: any) => {
  return { type: ORDER_DATA, payload: payload };
};

const setMenuItem = (payload: number) => {
  return { type: MENU, payload: payload };
};

const setSidebar = (payload: 'close' | 'open') => {
  return { type: SIDEBAR, payload: payload };
};
const setProfileData = (payload: any) => {
  return { type: PROFILE_DATA, payload: payload };
};

export {
  USER_DATA,
  setUserData,
  ORDER_DATA,
  setOrderData,
  MENU,
  setMenuItem,
  SIDEBAR,
  setSidebar,
  setProfileData,
  PROFILE_DATA,
  NOTIFICATIONS,
  setNotifications,
};
