import './sidebar-item-child.scss';
import { Link, useLocation } from 'react-router-dom';
import * as React from 'react';
import { Dispatch, FC, SetStateAction } from 'react';
import { ReactComponent as ActiveIcon } from 'assets/icons/sidebar-child-active.svg';

interface SidebarItemChildProps {
  id?: number;
  title?: string;
  icon?: React.ReactNode;
  link?: string;
  className?: string;
  sidebarOpen: boolean;
}

export const SidebarItemChild: FC<SidebarItemChildProps> = ({
  id,
  title,
  icon,
  link,
  className,
  sidebarOpen,
}) => {
  const location = useLocation();

  return (
    <>
      <Link
        to={link ?? ''}
        className={`sidebar-item-child ${sidebarOpen ? 'open' : 'closed'} ${location.pathname + location.search === link ? 'active' : ''} ${className ?? ''}`}
      >
        <div className="content">
          {icon}
          {sidebarOpen && <p className="title">{title}</p>}
        </div>
        {location.pathname + location.search === link && <ActiveIcon />}
      </Link>
    </>
  );
};
