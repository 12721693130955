import { Divider } from 'antd';
import './authentication-box.scss';
import { Button, Card } from 'components';
import { ReactComponent as LevelArrow } from 'assets/icons/main/LevelArrow.svg';
import { ReactComponent as BlueDot } from 'assets/icons/main/BlueDot.svg';
import { ReactComponent as GreenDot } from 'assets/icons/main/GreenDot.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { getMineLevelAsync } from 'core/repositories';
import { UserLevel } from 'core/domains';
import { digitSperator } from 'utils/scripts/exchange';
import { useNavigate } from 'react-router-dom';
import { getViaAuth, responseValidator } from '../../../../utils/scripts/api';
import { API } from '../../../../apis';

interface Props {
  step?: number;
}

export const AuthenticationBox: React.FC<Props> = ({ step = 0 }) => {
  const [currentStep, setCurrentStep] = useState(step);
  const [level, setLevel] = useState<UserLevel>();
  const [loading, setLoading] = useState<boolean>(false);
  const [depositLimit, setDepositLimit] = useState<number>();
  const [depositLimitLoading, setDepositLimitLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    getMineLevelAsync(setLoading, setLevel);
    getMineDepositLimit();
  }, []);

  const handleUpgradeClick = () => {
    navigate('/dashboard/authentication');
  };

  const getMineDepositLimit = () => {
    setDepositLimitLoading(true);
    getViaAuth(API.transactions.depositLimit).then((response: any) => {
      setDepositLimitLoading(false);
      if (responseValidator(response.status)) {
        setDepositLimit(response.value.depositLeftAmount);
      }
    });
  };

  return (
    <Card
      className="authentication-box"
      title={`احراز هویت (${level?.name ? level.name : '-'} از 2)`}
      button={
        <Button
          className="authentication-button"
          type="primary"
          text="ارتقا سطح"
          icon={<LevelArrow />}
          onClick={handleUpgradeClick}
          disabled={loading || level?.uniqueName?.id! - 1 === 2}
        />
      }
      divider
      blur={loading}
    >
      <div className="authentication-info">
        <div>
          <h5>
            <GreenDot /> سقف واریز ریالی:
          </h5>
          <span>{digitSperator(25000000)} تومان</span>
        </div>
        <div>
          <h5>
            <BlueDot /> میزان باقی مانده:
          </h5>
          {depositLimitLoading ? (
            <></>
          ) : (
            <span>{digitSperator(depositLimit)} تومان</span>
          )}
        </div>
      </div>
      <div className="authentication-steps">
        {level?.uniqueName?.id && (
          <>
            {/* <div className={`step ${level?.uniqueName?.id - 1 === 0 || level?.uniqueName?.id - 1 === 2 || level?.uniqueName?.id - 1 === 3 || level?.uniqueName?.id - 1 === 4 ? 'active' : ''}`} /> */}
            <div
              className={`step ${level?.uniqueName?.id - 1 === 1 || level?.uniqueName?.id - 1 === 2 || level?.uniqueName?.id - 1 === 3 || level?.uniqueName?.id - 1 === 4 ? 'active' : ''}`}
            />
            <div
              className={`step ${level?.uniqueName?.id - 1 === 2 || level?.uniqueName?.id - 1 === 3 || level?.uniqueName?.id - 1 === 4 ? 'active' : ''}`}
            />
            {/* <div className={`step ${level?.uniqueName?.id - 1 === 3 || level?.uniqueName?.id - 1 === 4 ? 'active' : ''}`} />
            <div className={`step ${level?.uniqueName?.id - 1 === 4 ? 'active' : ''}`} /> */}
          </>
        )}
      </div>
    </Card>
  );
};
