import './comment-modal.scss';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button, Card, Modal } from '../../../../../components';
import union from 'assets/images/wallet/comment-modal/union.png';
import warning from 'assets/images/wallet/comment-modal/warning.png';
import { WarningOutlined } from '@ant-design/icons';
import { ReactComponent as Close } from 'assets/icons/authentication/Close.svg';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const CommentModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  return (
    <Modal
      className={'xpay-comment-modal'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card
        title={'هشدار!'}
        button={<Close onClick={() => setIsOpen(false)} />}
        divider
        className={'xpay-comment-card'}
      >
        <img src={union} alt={'Union'} className={'comment-union'} />
        <img src={warning} alt={'Warning'} className={'comment-warning'} />
        <div className={'row'}>
          <p>
            <WarningOutlined />
          </p>
          <p>
            برای واریز این رمزارز، باید علاوه بر آدرس، کامنت (comment) را نیز
            وارد کنید؛ در غیر این صورت دارایی شما وارد فرایند بازیابی می شود.
          </p>
        </div>
        <div className={'row'}>
          <p>
            <WarningOutlined />
          </p>
          <p>
            توجه کنید برای وارد کردن کامنت (comment) از گزینه ی Encrypted
            Message در کیف پول مبداء خود استفاده نکنید، در صورتی که به جای کامنت
            (comment) پیام رمز گذاری شده را (Encrypted Message) وارد کنید،
            دارایی شما وارد فرایند بازیابی می شود.{' '}
          </p>
        </div>
        <div className={'row has-checkbox'}>
          <p>
            <input
              checked={isChecked}
              type={'checkbox'}
              onChange={() => setIsChecked(!isChecked)}
            />
          </p>
          <p>
            من ریسک وارد نکردن کامنت (Comment) را میدانم و مسئولیت وارد نکردن آن
            را میپذیرم و هیچ مسئولیتی بر عهده ایکس پی نمی باشد.
          </p>
        </div>
        <Button
          type={'primary'}
          text={'تایید'}
          onClick={() => setIsOpen(false)}
          disabled={!isChecked}
        />
      </Card>
    </Modal>
  );
};
