import './register.scss';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ReactComponent as BackIcon } from 'assets/icons/main/Back.svg';
import { Carousel, Input } from 'antd';
import { Button } from '../../../components';
import ReactCodeInput from 'react-verification-code-input';
import { Enum } from '../../../core/domains';
import { useLocation, useNavigate } from 'react-router-dom';
import { post, responseValidator } from '../../../utils/scripts/api';
import { API } from '../../../apis';
import moment from 'moment';
import { faNumToEnNum, IsNumeric } from '../../../utils/scripts/decimal';
import { ReactComponent as CallIcon } from 'assets/icons/main/Call.svg';
import { ReactComponent as LockIcon } from 'assets/icons/main/Lock.svg';
import { ReactComponent as DownIcon } from 'assets/icons/main/Down.svg';
import { ReactComponent as RightIcon } from 'assets/icons/login/rightarrow.svg';
import { ReactComponent as LeftIcon } from 'assets/icons/login/leftarrow.svg';
import XPayMask from 'assets/images/xpay-mask.png';
import {
  CloseCircleFilled,
  InfoCircleFilled,
  WarningOutlined,
} from '@ant-design/icons';
import { APPNAME, Logo, SITE_URL, TERM_LINK } from '../../../apis/constants';
import { toast } from 'react-toastify';
import x from 'assets/images/login/x.png';
import btc from 'assets/images/login/btc.png';
import support from 'assets/images/login/support.png';
import card from 'assets/images/login/card.png';

enum Stage {
  ENTER_PHONE,
  ENTER_2FA,
}

interface FieldError {
  phone: string[];
  password: string[];
}

interface HasError {
  phone: boolean;
  password: boolean;
}

const CAROUSEL_ITEMS = [
  {
    src: btc,
    title: 'خرید و فروش آسان',
    description:
      'با رابط کاربری ساده و اپلیکیشنی سریع در سریعترین زمان ممکن معاملات خود را انجام دهید!',
  },
  {
    src: support,
    title: 'پشتیبانی ۲۴ ساعته',
    description:
      'هفت روز هفته به صورت تلفنی از ۹ الی ۱۸ و ۲۴ ساعته از چت آنلاین آماده ی پاسخگویی به شما هستیم.',
  },
  {
    src: card,
    title: 'واریز و تسویه آنی',
    description:
      'بدون معطلی و در کمتر از چند دقیقه تسویه برداشت های تومانی خود را دریافت کنید.',
  },
];

export const Register = () => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [referralValue, setReferralValue] = useState<string>('');
  const [twoFactorType, setTwoFactorType] = useState<Enum>();
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [repeatPasswordValue, setRepeatPasswordValue] = useState<string>('');
  const [fieldError, setFieldError] = useState<FieldError>({
    password: [''],
    phone: [''],
  });
  const [hasError, setHasError] = useState<HasError>({
    phone: false,
    password: false,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [stage, setStage] = useState<Stage>(Stage.ENTER_PHONE);
  const [code, setCode] = useState<string>('');
  const [timer, setTimer] = useState<number>(120);

  const navigate = useNavigate();

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  function handleRegisterSubmit(): void {
    setLoading(true);
    post(API.signup, {
      phoneNumber: phoneValue,
      password: passwordValue,
      repeatPassword: repeatPasswordValue,
      referralCode: referralValue,
    })
      .then(function (response: any) {
        setLoading(false);
        if (responseValidator(response.status)) {
          toast.success('حساب شما با موفقیت ایجاد شد.');
          (window as any).dataLayer = (window as any).dataLayer || [];

          (window as any).dataLayer.push({
            event: 'sign_up',
          });
          localStorage.setItem('phoneNumber', phoneValue);
          localStorage.setItem('referralCode', referralValue);
          localStorage.setItem('passwordValue', passwordValue);
          localStorage.setItem('repeatPasswordValue', repeatPasswordValue);
          setStage(Stage.ENTER_2FA);
        }
      })
      .catch(function (error: any) {
        setLoading(false);
      });
  }

  function handleCodeVerify(codeValue: string) {
    setLoading(true);
    if (codeValue.length === 6) {
      post(API.signupVerify, {
        OtpCode: codeValue,
        phoneNumber: phoneValue,
      }).then(function (response: any) {
        setLoading(false);
        if (responseValidator(response.status)) {
          toast.success('شماره تماس شما تایید شد');
          localStorage.removeItem('passwordValue');
          localStorage.removeItem('repeatPasswordValue');
          localStorage.setItem('token', response.data.value.access_token);
          response.data.value.fullName &&
            localStorage.setItem('name', response.data.value.fullName);
          localStorage.setItem('expires_in', response.data.value.expires_in);
          localStorage.setItem('auth', response.data.value.isAuthorized);
          phoneValue && localStorage.setItem('phone', phoneValue);
          localStorage.setItem('date', moment.utc().toString());
          localStorage.setItem('ref', response.data.value.refresh_token);
          navigate('/dashboard');
        } else {
          toast.error('کد وارد شده اشتباه است.');
        }
      });
    } else {
      toast.error('کد باید دارای ۶ رقم باشد.');
    }
  }

  function handlePhoneChange(value: string): void {
    setHasError((prev) => ({
      ...prev,
      phone: false,
    }));

    if (value !== '') value = faNumToEnNum(value);
    if (value !== '' && !IsNumeric(faNumToEnNum(value[value.length - 1])))
      return;

    setPhoneValue(value);
  }

  function handlePasswordChange(value: string): void {
    setPasswordValue(value);
    const errors = [];

    if (value.length < 8) {
      errors.push('حداقل ۸ کاراکتر داشته باشد.');
    }

    if (!/[a-z]/.test(value)) {
      errors.push('حداقل یک حرف کوچک داشته باشد.');
    }

    if (!/[A-Z]/.test(value)) {
      errors.push('حداقل یک حرف بزرگ داشته باشد.');
    }

    if (!/\d/.test(value)) {
      errors.push('حداقل یک عدد داشته باشد.');
    }

    setPasswordErrors(errors);
  }

  function handleOpenReferral() {
    document.body.classList.toggle('scrollbar-none', !isOpen);
    setIsOpen(!isOpen);
  }

  function handleRepeatPasswordChange(value: string): void {
    setRepeatPasswordValue(value);
  }

  function handleReferralChange(value: string): void {
    setReferralValue(value);
  }

  useEffect(() => {
    // Extract referral code from URL
    const params = new URLSearchParams(location.search);
    const refCode = params.get('ref'); // Get the value of 'ref' parameter
    if (refCode) {
      setReferralValue(refCode); // Set the referral value if it exists
    }

    localStorage.removeItem('token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('auth');
    localStorage.removeItem('ref');
    localStorage.removeItem('date');
    localStorage.removeItem('phone');
    localStorage.removeItem('name');
    localStorage.removeItem('profile');
    localStorage.removeItem('twoFactorMethod');
  }, [location.search]);

  return (
    <div className={'xpay-register'}>
      <div className={'register-content'}>
        <div className={'xpay-heading'}>
          <img
            src={Logo}
            alt={'Logo'}
            onClick={() => window.open(SITE_URL, '_self')}
          />
        </div>
        <img
          src={Logo}
          alt={'Logo'}
          onClick={() => window.open(SITE_URL, '_self')}
          className={'xpay-logo-desktop'}
        />
        <div className={'register-form'}>
          <div className={'domain-assurance'}>
            <InfoCircleFilled />
            <p>
              مطمئن شوید که در دامنه
              <a
                href={
                  window.location.protocol + '//' + window.location.hostname
                }
                className={'en'}
              >
                {window.location.protocol + '//' + window.location.hostname}
              </a>
              هستید.
            </p>
          </div>

          {stage === Stage.ENTER_PHONE && (
            <>
              <h2>ایجاد حساب کاربری</h2>
              <p>
                برای ایجاد حساب کاربری، اطلاعات متعلق به خود را در کادرهای
                مربوطه وارد نمایید.
              </p>
              <Input
                name="phoneNumber"
                type="text"
                className="phoneNumber-input"
                value={phoneValue}
                onChange={(el) => handlePhoneChange(el.target.value)}
                placeholder="موبایل"
                prefix={<CallIcon />}
                maxLength={11}
                dir={phoneValue.length !== 0 ? 'ltr' : 'rtl'}
              />
              <p className={'warning'}>
                <WarningOutlined />
                شماره موبایل باید به نام خودتان باشد در غیر اینصورت امکان احراز
                وجود ندارد.
              </p>
              <Input.Password
                name="password"
                type="text"
                className="password-input"
                value={passwordValue}
                onChange={(el) => handlePasswordChange(el.target.value)}
                placeholder="رمز عبور"
                prefix={<LockIcon />}
                dir={passwordValue.length !== 0 ? 'ltr' : 'rtl'}
              />

              {passwordValue.length > 0 && passwordErrors.length > 0 && (
                <ul className="password-errors">
                  {passwordErrors.map((error, index) => (
                    <li key={index}>
                      <CloseCircleFilled />
                      {error}
                    </li>
                  ))}
                </ul>
              )}

              <Input.Password
                name="repeatPassword"
                type="text"
                className="repeatPassword-input"
                value={repeatPasswordValue}
                onChange={(el) => handleRepeatPasswordChange(el.target.value)}
                placeholder="تکرار رمز عبور"
                prefix={<LockIcon />}
                dir={repeatPasswordValue.length !== 0 ? 'ltr' : 'rtl'}
              />

              {repeatPasswordValue.length > 0 &&
                passwordValue !== repeatPasswordValue && (
                  <ul className="password-errors">
                    <li>
                      <CloseCircleFilled />
                      تکرار رمز عبور مطابق با رمز عبور وارد شده نیست.
                    </li>
                  </ul>
                )}

              <div className={'referral'}>
                <span onClick={handleOpenReferral}>
                  <DownIcon
                    style={{ transform: `${isOpen ? '' : 'rotate(90deg)'}` }}
                  />{' '}
                  کد معرف دارید؟
                </span>
                {isOpen && (
                  <Input
                    name="referral"
                    type="text"
                    className={'referral-input'}
                    value={referralValue}
                    onChange={(el) => handleReferralChange(el.target.value)}
                    placeholder="کد معرف را وارد کنید..."
                    dir={referralValue.length !== 0 ? 'ltr' : 'rtl'}
                  />
                )}
              </div>

              <div className={'row'}>
                <div className={'checkbox'}>
                  <input
                    type="checkbox"
                    checked={termsChecked}
                    onChange={(e) => setTermsChecked(!termsChecked)}
                  />
                  <span>
                    <a href={TERM_LINK}>قوانین و شرایط استفاده از {APPNAME}</a>{' '}
                    را خوانده ام و با آن موافقم.
                  </span>
                </div>
              </div>

              <Button
                type={'primary'}
                className={'register-button'}
                text={'ثبت نام'}
                onClick={handleRegisterSubmit}
                loading={loading}
                disabled={
                  loading ||
                  phoneValue.length < 11 ||
                  !passwordValue ||
                  !repeatPasswordValue ||
                  passwordValue !== repeatPasswordValue ||
                  !termsChecked
                }
              />

              <div className={'row'}>
                <div className={'has-account'}>
                  <span>حساب کاربری دارید؟</span>
                  <a href={'/login'}>بازگشت به صفحه ی ورود</a>
                </div>
                <a href={SITE_URL}>بازگشت به سایت</a>
              </div>
            </>
          )}
          {stage === Stage.ENTER_2FA && (
            <>
              <h2>تایید شماره همراه</h2>
              <p>
                کد 6 رقمی پیامک شده به شماره {phoneValue} را در کادر زیر وارد
                نمایید.
              </p>
              <ReactCodeInput
                className={`input`}
                fieldWidth={48}
                fieldHeight={48}
                values={code ? code.split('') : []}
                autoFocus={true}
                onChange={(value) => {
                  setCode(value);
                }}
                onComplete={(e) => handleCodeVerify(e)}
                fields={6}
              />
              <div className={'row'}>
                {timer > 0 ? (
                  <p id="waiting">{timer} ثانیه تا ارسال مجدد کد</p>
                ) : (
                  <p id="resend" onClick={() => handleCodeVerify(code)}>
                    ارسال مجدد کد
                  </p>
                )}

                <a onClick={() => setStage(Stage.ENTER_PHONE)}>
                  تغییر شماره همراه
                </a>
              </div>

              <div className={'buttons'}>
                <Button
                  type={'primary'}
                  text={'تایید کد'}
                  className={'code-submit-button'}
                />
                <Button
                  type={'outlined'}
                  text={'بازگشت'}
                  icon={<BackIcon />}
                  className={'back-button'}
                  onClick={() => setStage(Stage.ENTER_PHONE)}
                />
              </div>
            </>
          )}
        </div>
        <img src={XPayMask} alt={'XPayMask'} className={'xpay-mask'} />
      </div>
      <div className={'register-banner'}>
        <img src={x} alt={'XPayX'} className={'xpay-x'} />
        <Carousel
          autoplay
          draggable
          adaptiveHeight
          arrows
          nextArrow={<RightIcon />}
          prevArrow={<LeftIcon />}
        >
          {CAROUSEL_ITEMS.map((item, index) => (
            <div key={index} className="carousel-slide">
              <img src={item.src} alt={item.title} className="carousel-image" />
              <h3 className={'carousel-title'}>{item.title}</h3>
              <p className={'carousel-description'}>{item.description}</p>
            </div>
          ))}
        </Carousel>{' '}
      </div>
    </div>
  );
};
