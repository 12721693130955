import './failure.scss';
import { Button, Card } from '../../../../components';
import union from 'assets/images/create-order/union.png';
import { Divider } from 'antd';
import { TransactionVerify } from '../../../../core/domains/transactions/transactionVerify';
import * as React from 'react';
import { FC, useRef } from 'react';
import moment from 'jalali-moment';
import { CopyOutlined } from '@ant-design/icons';
import { copyToClipboard } from '../../../../utils/scripts/copyToClipboard';
import { PlatformLabel } from '../../../../core/domains';
import failure from 'assets/images/verify/failure.png';
import { digitSperator } from '../../../../utils/scripts/exchange';
import { ReactComponent as LeftArrowIcon } from 'assets/images/verify/leftarrow.svg';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../../utils/scripts/route';

interface Props {
  verifyData: TransactionVerify | null;
  trackingCode: string;
}

export const Failure: FC<Props> = ({ verifyData, trackingCode }) => {
  const orderIdRef = useRef<HTMLElement>(null);
  let query = useQuery();

  const platform = query.get('platform') ?? '1';
  return (
    <div className={'xpay-failure'}>
      <div className={'mobile-header'}>
        <p>نتیجه ی تراکنش</p>
        <Link to={'/dashboard'}>
          <LeftArrowIcon />
        </Link>
      </div>
      <Card className={'failure-card'}>
        <img src={union} className={'union'} />
        <img src={failure} alt={'Failure'} className={'image-failure'} />
        <div className={'header'}>
          <div className={'content'}>
            <h1>واریز ناموفق</h1>
            <p>
              مشکلی در واریز شتابی پیش آمده است، تراکنش به صورت خودکار بعد از ۱۵
              دقیقه دوباره استعلام گرفته می شود. در صورت خطای دوباره، مبلغ به
              حساب شما برمی گردد.
            </p>
          </div>
        </div>
        <ul>
          <li>
            <span>مبلغ</span>
            <Divider />
            <span>
              <span>{digitSperator(verifyData?.amount)} تومان</span>
            </span>
          </li>
          <li>
            <span>شناسه پرداخت</span>
            <Divider />
            <span>
              <span className="en" ref={orderIdRef}>
                {trackingCode}
              </span>
              <CopyOutlined
                onClick={() =>
                  copyToClipboard(orderIdRef.current, 'شناسه پرداخت')
                }
              />
            </span>
          </li>
          <li>
            <span>تاریخ و ساعت</span>
            <Divider dashed />
            <span>{moment().locale('fa').format('HH:mm - jYYYY/MM/DD')}</span>
          </li>
        </ul>
        <div className={'buttons'}>
          <Button
            type={'primary'}
            text={` بازگشت به ${PlatformLabel.get(parseInt(platform))}`}
            url={
              (parseInt(platform) ?? 1) === 3 ? 'xpay://openapp' : '/dashboard'
            }
          />
        </div>
      </Card>
    </div>
  );
};
