import { XpayComponent } from "core/domains";
import * as React from "react";
import "./badge.scss";

interface Props extends XpayComponent {
  type: "success" | "error" | "primary" | "info" | "secondary" | "waiting";
  text: string | number;
  icon?: string;
}

export const Badge: React.FC<Props> = (props) => {
  return (
    <div
      className={`xpay-badge${
        props.className && props.className !== "" ? props.className : ""
      }`}
    >
      <div className={props.type}>
        <img src={props.icon} />
        <span>{props.text}</span>
      </div>
    </div>
  );
};
