import { digitSperator } from './exchange';

export function ToFa(value: any) {
  const persionDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  return value.toString().replace(/\d/g, (x: any) => persionDigits[Number(x)]);
}

export function ToEn(value: any) {
  let str = value.replace(',', '');
  const persionDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  for (let i = 0; i < 10; i++) {
    str = str.replaceAll(persionDigits[i], i);
  }
  return str;
}

export function faNumToEnNum(value: any): any {
  let str = value.replace(',', '');
  const persionDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  for (let i = 0; i < 10; i++) {
    str = str.replaceAll(persionDigits[i], i);
  }
  return str;
}

export function ToIranCurrency(num: any) {
  if (!num) return 0;
  if (num.toString().startsWith('0.')) return num;
  if (num) return digitSperator(parseFloat(num).toFixed(0));
  return num;
}

export function toFixed(x: any) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
}

export function IsNumeric(num: any) {
  return (
    num == '.' ||
    num == '' ||
    num == '0' ||
    num == '1' ||
    num == '2' ||
    num == '3' ||
    num == '4' ||
    num == '5' ||
    num == '6' ||
    num == '7' ||
    num == '8' ||
    num == '9'
  );
}
