import * as React from 'react';
import { useEffect } from 'react';
import { Enum, XpayComponent } from '../../core/domains';
import moment from 'jalali-moment';
import { Dropdown } from '../dropdown/dropdown';
import './date-picker.scss';
import { MONTHS } from '../../utils/constants';

interface Props extends XpayComponent {
  error?: string[];
  label?: string;
  name?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

const min_year = parseInt(moment(moment.now()).format('jYYYY')) - 70;
const max_year = parseInt(moment(moment.now()).format('jYYYY'));

const { useState, useRef } = React;

export const DatePicker: React.FC<Props> = (props) => {
  var years: Enum[] = renderYears();
  var months: Enum[] = renderMonths();
  var days: Enum[] = renderDays();

  function renderMonths() {
    var list: Enum[] = [];
    for (let i = 0; i <= 11; i++) {
      let month = MONTHS[i];
      list.push({
        id: i + 1,
        title: month,
      });
    }
    return list;
  }

  function renderYears() {
    var list: Enum[] = [];

    for (let year = min_year; year <= max_year - 18; year++) {
      list.push({
        id: year,
        title: year.toString(),
      });
    }
    return list;
  }

  function renderDays() {
    var list: Enum[] = [];

    let monthDays = 31;

    for (let day = 1; day <= monthDays; day++) {
      list.push({ id: day, title: day.toString() });
    }
    return list;
  }

  const [year, setYear] = useState<number>(0);
  const [day, setDay] = useState<number>(0);
  const [month, setMonth] = useState<number>(0);

  useEffect(() => {
    renderMonths();
    renderYears();
    renderDays;
  }, []);
  useEffect(() => {
    props.onChange &&
      year !== 0 &&
      month !== 0 &&
      day !== 0 &&
      props.onChange(`${year}/${month}/${day}`);
  }, [day, month, year]);
  return (
    <div className="date-picker-custom">
      <Dropdown
        defaultSelected={{
          id: 0,
          title: 'روز',
        }}
        onChangeHandle={(e) => setDay(e)}
        items={days}
      ></Dropdown>

      <Dropdown
        defaultSelected={{
          id: 0,
          title: 'ماه',
        }}
        onChangeHandle={(e) => setMonth(e)}
        items={months}
      ></Dropdown>
      <Dropdown
        defaultSelected={{
          id: 0,
          title: 'سال',
        }}
        onChangeHandle={(e) => setYear(e)}
        items={years}
      ></Dropdown>
    </div>
  );
};
