import {
  getViaAuth,
  postViaAuth,
  responseValidator,
} from '../../../utils/scripts/api';
import { TransactionFilterValues, TransactionList } from '../../domains';
import { API } from '../../../apis';
import { Page } from '../../domains/pagging/pagging';
import { Dispatch, SetStateAction } from 'react';

export const transactionFilterChecker = (filters: TransactionFilterValues) => {
  var query = '';

  if (filters?.marketType)
    query += `${query.includes('?') ? '&' : '?'}markettype=${
      filters?.marketType
    }`;
  if (filters?.type && filters?.type.id !== 0)
    query += `${query.includes('?') ? '&' : '?'}type=${filters?.type.id}`;
  if (filters?.state && filters?.state.id !== 0)
    query += `${query.includes('?') ? '&' : '?'}state=${filters?.state.id}`;
  if (filters?.startDate)
    query += `${query.includes('?') ? '&' : '?'}startDate=${
      filters?.startDate
    }`;
  if (filters?.endDate)
    query += `${query.includes('?') ? '&' : '?'}endDate=${filters?.endDate}`;
  if (filters?.sort && filters?.sort.id !== 0)
    query += `${query.includes('?') ? '&' : '?'}dateSort=${filters?.sort.id}`;
  if (filters?.keywords)
    query += `${query.includes('?') ? '&' : '?'}keywords=${filters?.keywords}`;

  return query;
};
export const getAllMineTransactions = (
  setloader: any,
  setData: any,
  page: Page,
  query: string,
) => {
  if (page) {
    setloader(true);
    getViaAuth<TransactionList>(
      API.transactions.mine + `/${page.pageNumber}/${page.pageSize}` + query,
    ).then((response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setData(response.value);
      }
    });
  }
};

export const cancelTransaction = (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: { trackingCode: string },
  onComplete: () => void,
) => {
  setLoading(true);
  postViaAuth(API.transactions.cancel, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
};
