import * as React from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Card } from 'components';

import './referral-purchase.scss';
import { Table } from 'components/table/table';
import { TableColumn } from 'react-data-table-component';
import { Pagination } from 'components/pagination/pagination';

const { useState, useRef } = React;

const data = [
  {
    id: 1,
    orderDate: '1400/01/05',
    prize: 589000,
    friendsPrize: 564600,
    orderType: 'تتر',
    fullName: 'مسعود رضایی',
  },
  {
    id: 2,
    orderDate: '1400/01/05',
    prize: 589000,
    friendsPrize: 564600,
    orderType: 'اتریوم',
    fullName: 'مسعود رضایی',
  },
  {
    id: 3,
    orderDate: '1400/01/05',
    prize: 589000,
    friendsPrize: 564600,
    orderType: 'بیتکوین',
    fullName: 'مسعود رضایی',
  },
];

export const ReferralPurchase: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      id: 'index',
      selector: (row: any, rowIndex: any) => rowIndex + 1,
      width: '50px',
    },
    {
      name: 'نام و فامیل',
      id: 'fullName',
      selector: (row: any) => row.fullName,
    },
    {
      name: 'نوع سفارش',
      id: 'orderType',
      selector: (row: any) => row.orderType,
    },
    {
      name: 'پورسانت شما',
      id: 'prize',
      selector: (row: any) => row.prize,
    },
    {
      name: 'پورسانت دوست',
      id: 'friendsPrize',
      selector: (row: any) => row.friendsPrize,
    },
    {
      name: 'تاریخ ثبت سفارش',
      id: 'orderDate',
      selector: (row: any) => row.orderDate,
    },
  ];

  return (
    <>
      <Card className={`referral-table-card referral-purchase `}>
        <div className="head">
          <div className="right">
            <div className="desktop-show">
              <h4>سوابق خرید دوستان معرفی شده توسط شما</h4>
            </div>
            <div className="mobile-show">
              <h4>سوابق خرید دوستان</h4>
            </div>
          </div>

          <Button
            text={`${isExpanded ? 'بستن' : 'نمایش'} لیست`}
            type="outlined"
            icon={isExpanded ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>

        {isExpanded && (
          <div className={`desktop-show '}`}>
            <Table
              className="purchase-table"
              data={data}
              column={columns}
              hasSearchbar
              hasFiltering
            />
          </div>
        )}
        {isExpanded && (
          <ul className={`mobile-show ${isExpanded ? '' : 'd-none'}`}>
            {data.map((item) => (
              <li>
                <div className="row">
                  <div className="right">
                    <p>نام و فامیل:</p>
                    <span>{item.fullName}</span>
                  </div>
                  <div className="left">
                    <p>نوع سفارش: </p>
                    <span>{item.orderType}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="right">
                    <p>پورسانت شما:</p>
                    <span>{item.prize} تومان</span>
                  </div>
                  <div className="left">
                    <p>پورسانت دوست:</p>
                    <span>{item.friendsPrize} تومان</span>
                  </div>
                </div>
                <div className="row">
                  <div className="right">
                    <p>تاریخ ثبت سفارش:</p>
                  </div>
                  <div className="left">
                    <span>{item.orderDate}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Card>
    </>
  );
};
