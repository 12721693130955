import axios from 'axios';
import { faNumToEnNum } from './decimal';
import { SERVER_URL } from '../../apis/constants';

export function isNumeric(num: any) {
  if (
    num == '.' ||
    num == '' ||
    num == '0' ||
    num == '1' ||
    num == '2' ||
    num == '3' ||
    num == '4' ||
    num == '5' ||
    num == '6' ||
    num == '7' ||
    num == '8' ||
    num == '9'
  ) {
    return true;
  } else return false;
}

export const isPositiveNumeric = (value: string) => {
  return /^\d*$/.test(value);
};

export function digitSperator(value: any): any {
  if (value) {
    const str = value.toString().split('.');
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.').trim().replaceAll(' ', '');
  }
  return value;
}

export function digitSeparator(value: any): string {
  if (!value && value !== 0) return value; // Handle null, undefined, and empty string

  let [integerPart, decimalPart] = value.toString().split('.');

  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Return the number with the original decimal part (if it exists)
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}

export function deleteCommaSperator(value: any): any {
  return value.toString().replaceAll(',', '');
}

export const exchangeCalculator = (
  value: any,
  source: string,
  destination: string,
  side: any,
  intervalRef: any,
  setSourceValue: any,
  setDestinationValue: any,
  setDestinationProcess: any,
  setSourceProcess: any,
) => {
  function clearInputs() {
    setSourceValue('');
    setDestinationValue('');
  }

  if (intervalRef.current) {
    clearTimeout(intervalRef.current);
  }
  if (side === 'SOURCE') setSourceValue(value);
  else if (side == 'DESTINATION') setDestinationValue(value);

  intervalRef.current = setTimeout(() => {
    if (value !== '') value = faNumToEnNum(value);
    else clearInputs();

    const char =
      value.length !== 0
        ? value.split('')[value.split('').length - 1]
        : setDestinationValue('');

    setSourceValue('');
    if (
      char == '.' ||
      char == '0' ||
      char == '1' ||
      char == '2' ||
      char == '3' ||
      char == '4' ||
      char == '5' ||
      char == '6' ||
      char == '7' ||
      char == '8' ||
      char == '9'
    ) {
      if (side === 'SOURCE') {
        setSourceValue(digitSperator(value));

        if (value !== '') {
          setDestinationValue('');
          setDestinationProcess(true);
          axios
            .get(
              SERVER_URL +
                `market/exchnage/1/${value}/${source}/${destination}/`,
            )
            .then((res) => {
              setDestinationProcess(false);
              setDestinationValue(
                digitSperator(res.data.value.destinationAmount),
              );
            });
        }
      } else if (side === 'DESTINATION') {
        setDestinationValue(digitSperator(value));
        if (value !== '') {
          setSourceValue('');
          setSourceProcess(true);
          axios
            .get(
              SERVER_URL +
                `market/exchnage/2/${value}/${source}/${destination}/`,
            )
            .then((res) => {
              setSourceProcess(false);
              setSourceValue(digitSperator(res.data.value.sourceAmount));
            });
        }
      }
    }
    intervalRef.current = null;
  }, 500);
};
