import { Slider } from 'components/slider/slider';
import * as React from 'react';
import './main.scss';
import { Asset } from 'core/domains/markets/asset';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'core/redux/store';
import { Divider, Tooltip } from 'antd';
import { ActionBox } from './action-box/action-box';
import { ReferralBox } from './referral-box/referral-box';
import { WalletBox } from './wallet-box/wallet-box';
import { AuthenticationBox } from './authentication-box/authentication-box';
import { Alert } from 'components';
import { PopularCurrenciesBox } from './popular-currencies-box/popular-currencies-box';
import { isMobile } from 'react-device-detect';
import { UpdateApplicationModal } from 'components/update-application-modal/update-application-modal';
import { readProfile } from '../../../core/repositories/profile/profile';
import { KEY_PROFILE } from '../../../apis/local-storage-constants';
import { useEffect, useState } from 'react';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const Main: React.FC<Props> = (props) => {
  const [profile, setProfile] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkProfile = () => {
      const profileData = readProfile();
      if (profileData) {
        setProfile(profileData);
        setLoading(false);
      }
    };

    checkProfile();

    const intervalId = setInterval(() => {
      const profileData = readProfile();
      if (profileData) {
        setProfile(profileData);
        setLoading(false);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {!loading && profile?.level?.uniqueName?.id === 1 && (
        <Alert
          closeAble
          type="danger"
          className={'xpay-verification-alert'}
          title="اطلاعیه مهم"
          message={
            'کاربر عزیز برای استفاده از تمامی امکانات پنل کاربری لطفاً نسبت به احراز هویت خود اقدام نمایید.'
          }
        />
      )}
      <Alert
        closeAble
        className="desktop-show"
        type="warning"
        title="اخطار!"
        message="کاربر گرامی؛ آگهـی هـای کاریـابی کـه بـه درخـواسـت از شمــا جهــت ثبـت نـام در سایـت هـای فروش ارز دیجیتال و اقدام جهت خرید رمز ارز ختم می گردد،کلاهبرداری و به قصد سو استفاده از هویـت و حساب بانکی شما با اهداف مجرمانه می باشند و مسئولیت قانونی آن بر عهده شماست."
      />
      {isMobile && (
        <Alert
          closeAble
          type="warning"
          title="اخطار!"
          message="کاربر گرامی؛ آگهـی هـای کاریـابی کـه بـه درخـواسـت از شمــا جهــت ثبـت نـام در سایـت هـای فروش ارز دیجیتال و اقدام جهت خرید رمز ارز ختم می گردد،کلاهبرداری و به قصد سو استفاده از هویـت و حساب بانکی شما با اهداف مجرمانه می باشند و مسئولیت قانونی آن بر عهده شماست."
        />
      )}
      <div className="xpay-dashboard-main">
        <Slider />
        <ActionBox />
        <ReferralBox />
        <Divider
          className="desktop-show"
          type="vertical"
          style={{
            gridArea: 'divider',
            height: 'calc(100% + 48px)',
            transform: 'translateY(-24px)',
          }}
        />
        <WalletBox />
        <AuthenticationBox />
        <PopularCurrenciesBox />
      </div>
    </>
  );
};

export default connector(Main);
