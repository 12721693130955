import {API} from 'apis';
import {getViaAuth, responseValidator} from '../../../utils/scripts/api';
import moment from 'moment/moment';

export interface Popup {
  title: string;
  description: string;
  viewCount: number;
  intervalView: number;
  viewAfterSecond: number;
  id: number;
  urlPage: string;
  linkTo: string;

  imageUrl: string;
}

interface PopupsAllDataModel {
  lastShows: PopupShowsDetail[];
  details: Popup[];
  date: string;
}

interface PopupShowsDetail {
  date: string;
  popupId: number;
  showedCount: number;
}

const getMinePopup = async () => {
  if (canGetPopup()) {
    let oldPopup = localStorage.getItem('popup');
    getViaAuth<Popup[]>(API.popup.getActiveMinePopup).then((res) => {
      if (responseValidator(res.status)) {
        if (res.value) {
          let model: PopupsAllDataModel = {
            details: res.value,
            date: moment.utc().toString(),
            lastShows:
              oldPopup && JSON.parse(oldPopup).lastShows
                ? JSON.parse(oldPopup).lastShows
                : [],
          };
          localStorage.setItem('popup', JSON.stringify(model));
        }
      }
    });
  }
};

const canGetPopup = () => {
  let popup = localStorage.getItem('popup');
  if (popup) {
    let popupObject = JSON.parse(popup);
    if (Math.abs(moment(popupObject.date).diff(moment.utc(), 'hour')) < 1)
      return false;
  }
  return true;
};

export const getPopUpDetailsForShow = async (): Promise<Popup[]> => {
  await getMinePopup();
  let popup = localStorage.getItem('popup');
  var popups: Popup[] = [];
  if (popup) {
    let popupObject: PopupsAllDataModel = JSON.parse(popup);

    popupObject.details.map((item: Popup, index) => {
      let lastShowDetail = popupObject.lastShows.filter(
        (q) => q.popupId === item.id,
      )[0];
      console.log('lastShowDetail', lastShowDetail);

      if (lastShowDetail) {
        // check view count
        console.log(lastShowDetail.showedCount + '<' + item.viewCount);
        if (lastShowDetail.showedCount < item.viewCount) {
          // check interval
          console.log(
            Math.abs(moment(lastShowDetail.date).diff(moment.utc(), 'hour')) +
              '>' +
              item.intervalView,
          );

          if (
            Math.abs(moment(lastShowDetail.date).diff(moment.utc(), 'hour')) >
            item.intervalView
          )
            popups.push(item);
        }
      } else popups.push(item);
    });
  }
  return popups;
};

export const logShowPopUp = (popup: Popup) => {
  let popups = localStorage.getItem('popup');
  if (popups) {
    let popupsObject: PopupsAllDataModel = JSON.parse(popups);

    var targetPopupLastShow = popupsObject.lastShows.filter(
      (q) => q.popupId === popup.id,
    )[0];

    //remove log off popup
    popupsObject.lastShows = popupsObject.lastShows.filter(
      (q) => q.popupId !== popup.id,
    );

    //cerate new log of popup
    var targetPopUp: PopupShowsDetail = {
      date: moment.utc().toString(),
      popupId: popup.id,
      showedCount: targetPopupLastShow
        ? targetPopupLastShow.showedCount + 1
        : 1,
    };
    popupsObject.lastShows.push(targetPopUp);

    localStorage.setItem('popup', JSON.stringify(popupsObject));
  }
};
