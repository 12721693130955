import * as React from 'react';
import './slider.scss';
import { Carousel } from 'antd';
import { ReactComponent as SliderArrowRight } from 'assets/icons/main/slider/SliderArrowRight.svg';
import { ReactComponent as SliderArrowLeft } from 'assets/icons/main/slider/SliderArrowLeft.svg';
import { CHANNEL_ID, SLIDER_BANNER } from '../../apis/constants';
import { Link } from 'react-router-dom';
import { useRef } from 'react';

const SLIDES = [
  {
    id: 1,
    src: SLIDER_BANNER,
    link: CHANNEL_ID,
  },
];

export const Slider: React.FC = () => {
  const carouselRef = useRef<any>(null);

  const nextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const prevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  return (
    <div className="xpay-slider">
      <Carousel draggable autoplay adaptiveHeight dots ref={carouselRef}>
        {SLIDES.map((slide, index) => (
          <div className="xpay-slide" key={index}>
            <Link to={slide.link}>
              <img src={slide.src} alt="Slide" />
            </Link>
          </div>
        ))}
      </Carousel>
      <SliderArrowLeft className={'prev-navigation'} onClick={nextSlide} />
      <SliderArrowRight className={'next-navigation'} onClick={prevSlide} />
    </div>
  );
};
