import React from 'react';
import { getIconPath } from '../../../utils/scripts/getIconPath';
import { copyClipboard } from '../../../utils/scripts/copyToClipboard';
import { Order, OrderSide, OrderState } from '../../../core/domains';
import { digitSperator } from '../../../utils/scripts/exchange';
import { ReactComponent as CopyOutlined } from 'assets/icons/copy-outlined.svg';
import moment from 'jalali-moment';
import { Divider } from 'antd';

interface Props {
  orderItem: Order;
}

export const OrderDetailModalData: React.FC<Props> = ({ orderItem }) => {
  return (
    <>
      {orderItem.pair && (
        <div className="row">
          <span>نام ارز</span>
          <Divider dashed />
          <div>
            <img src={getIconPath(orderItem.pair.base)} />
            <span>
              <span className="en">{orderItem.pair.base}</span>
            </span>
          </div>
        </div>
      )}
      {orderItem.commission !== null && (
        <div className="row">
          <span>کارمزد سفارش</span>
          <Divider dashed />
          <div>
            <span>{digitSperator(orderItem.commission)} تومان</span>
          </div>
        </div>
      )}
      {orderItem.destinationQuantity && orderItem.sourceQuantity && (
        <>
          <div className="row">
            <span>مقدار مبداء</span>
            <Divider dashed />
            <div>
              <span>{digitSperator(orderItem.sourceQuantity)}</span>
              <span className={orderItem.side.id !== OrderSide.Buy ? 'en' : ''}>
                {orderItem.side.id === OrderSide.Buy
                  ? 'تومان'
                  : orderItem.side.id === OrderSide.Buy
                    ? orderItem.pair.quote
                    : orderItem.pair.base}
              </span>
            </div>
          </div>
          <div className="row">
            <span>مقدار مقصد</span>
            <Divider dashed />
            <div>
              <span>{digitSperator(orderItem.destinationQuantity)}</span>
              <span
                className={orderItem.side.id !== OrderSide.Sell ? 'en' : ''}
              >
                {orderItem.side.id === OrderSide.Sell
                  ? 'تومان'
                  : orderItem.side.id === OrderSide.Sell
                    ? orderItem.pair.quote
                    : orderItem.pair.base}
              </span>
            </div>
          </div>
        </>
      )}

      {orderItem.trackingCode && (
        <div className="row">
          <span>شناسه سفارش</span>
          <Divider dashed />
          <div>
            <span className="en">{orderItem.trackingCode}</span>
            <CopyOutlined
              onClick={() =>
                copyClipboard(orderItem.trackingCode, 'شناسه سفارش')
              }
            />
          </div>
        </div>
      )}
      {orderItem.type && (
        <div className="row">
          <span>نوع سفارش</span>
          <Divider dashed />
          <span>{orderItem.type.title}</span>
        </div>
      )}
      {orderItem.side && (
        <div className="row">
          <span>سمت سفارش</span>
          <Divider dashed />
          <span>{orderItem.side.title}</span>
        </div>
      )}
      {orderItem.createdOn && (
        <div className="row">
          <span>تاریخ و ساعت</span>
          <Divider dashed />
          <span>
            {moment
              .utc(orderItem.createdOn)
              .locale('fa')
              .local()
              .format('  HH:mm  -  jYYYY/MM/DD  ')}
          </span>
        </div>
      )}
      {orderItem.state && (
        <div className="row">
          <span>وضعیت</span>
          <Divider dashed />
          <span
            className={`${
              orderItem.state.id == OrderState.Filled
                ? 'success'
                : orderItem.state.id == OrderState.Rejected ||
                    orderItem.state.id == OrderState.Canceled
                  ? 'error'
                  : 'waiting'
            }`}
          >
            {orderItem.state.title}
          </span>
        </div>
      )}
    </>
  );
};
