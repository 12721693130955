import { toast } from 'react-toastify';

export function copyClipboard(value: string, name: string) {
  navigator.clipboard.writeText(value);
  toast.info(`${name} کپی شد`);
}

export function copyToClipboard(element: HTMLElement | null, name: string) {
  navigator.clipboard.writeText(element ? element?.innerText : '');
  toast.info(`${name} کپی شد`);
}
