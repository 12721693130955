import { API } from 'apis';
import {
  delViaAuth,
  getViaAuth,
  postViaAuth,
  responseValidator,
} from 'utils/scripts/api';
import { toast } from 'react-toastify';

export const getMineCarts = (setLoader: any, setResponse: any) => {
  setLoader(true);
  getViaAuth(API.bankAccount.mine).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) setResponse(response.value);
  });
};

export const getMineBankAccount = (setLoader: any, setResponse: any) => {
  setLoader(true);
  getViaAuth(API.bankAccount.mine).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    }
  });
};

export const createBankAccountByCard = (
  setLoading: any,
  cardNumber: string,
) => {
  setLoading(true);
  postViaAuth(API.bankAccount.addCard, {
    cardNumber: cardNumber.replaceAll('-', ''),
  }).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success('شماره کارت با موفقیت اضافه شد');
    }
  });
};

export const createBankAccountByIBan = (setLoading: any, iBan: string) => {
  setLoading(true);
  postViaAuth(API.bankAccount.addCard, {
    iBan: iBan,
  }).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success('شماره شبا با موفقیت اضافه شد');
    }
  });
};

export const deleteBankAccount = (
  setLoading: any,
  data: any,
  onComplete: any,
) => {
  setLoading(true);
  delViaAuth(API.bankAccount.delete, { bankAccountId: data }).then(
    (response: any) => {
      if (responseValidator(response.status)) {
        toast.success('کارت بانکی با موفقیت حذف شد');
        onComplete();
      }
    },
  );
};

export const createCart = (
  cardNumber: string,
  loading: React.Dispatch<React.SetStateAction<boolean>>,
  iban?: string,
) => {
  loading(true);
  postViaAuth(API.bankAccount.addCard, {
    cardNumber: cardNumber.replaceAll('-', ''),
    iban: iban,
  }).then((response: any) => {
    loading(false);
    if (responseValidator(response.status)) {
      toast.success('کارت با موفقیت اضافه شد');
    }
  });
};

export const createCartAndReload = (
  cardNumber: string,
  reload: any,
  setdata: any,
  setLoader: any,
  iban?: string,
) => {
  postViaAuth(API.bankAccount.addCard, {
    cardNumber: cardNumber.replaceAll('-', ''),
    iban: iban,
  }).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      reload(setLoader, setdata);
      toast.success('کارت با موفقیت اضافه شد');
    }
  });
};
