import { getIconPath } from '../../../utils/scripts/getIconPath';
import { digitSperator } from '../../../utils/scripts/exchange';
import { copyClipboard } from '../../../utils/scripts/copyToClipboard';
import { getMarketType, MarketType } from '../../../utils/scripts/market';
import {
  detect_bank,
  formatCardNumber,
} from '../../../utils/scripts/bankDetecter';
import moment from 'jalali-moment';
import * as React from 'react';
import { Transaction, TransactionType } from '../../../core/domains';
import { ReactComponent as CopyOutlined } from 'assets/icons/copy-outlined.svg';
import { Divider } from 'antd';

interface Props {
  transactionItem: Transaction;
}

export const TransactionDetailModalData: React.FC<Props> = ({
  transactionItem,
}) => {
  return (
    <>
      {transactionItem?.market && (
        <div className="row">
          <span>نام ارز</span>
          <Divider dashed />
          <div className="coin-info">
            <img src={getIconPath(transactionItem?.market?.symbol)} />
            <span>{transactionItem?.market.name}</span>
          </div>
        </div>
      )}
      {transactionItem.network && (
        <div className="row">
          <span>نوع شبکه</span>
          <Divider dashed />
          <div>
            <span className="en">
              {transactionItem?.network.name.toUpperCase()} |{' '}
              {transactionItem?.network.network.toUpperCase()}
            </span>
          </div>
        </div>
      )}
      {transactionItem?.trackingCode && (
        <div className="row">
          <span>شناسه تراکنش</span>
          <Divider dashed />
          <div>
            <span className="en">{transactionItem?.trackingCode}</span>
            <CopyOutlined
              onClick={() =>
                transactionItem?.trackingCode &&
                copyClipboard(transactionItem?.trackingCode, 'شناسه تراکنش')
              }
            />
          </div>
        </div>
      )}
      {transactionItem?.type && (
        <div className="row">
          <span>نوع تراکنش</span>
          <Divider dashed />
          <div>
            <span>{transactionItem?.type.title}</span>
          </div>
        </div>
      )}
      {transactionItem?.amount && (
        <>
          <div className="row">
            <span>مقدار تراکنش</span>
            <Divider dashed />
            <div>
              {transactionItem?.market.symbol === 'IRT' ? (
                <span>
                  {digitSperator(transactionItem?.amount.toFixed(0)) + ' تومان'}
                </span>
              ) : (
                <span>
                  {digitSperator(transactionItem?.amount) +
                    ' ' +
                    transactionItem.market.name}
                </span>
              )}
            </div>
          </div>
          {transactionItem.type.id === TransactionType.Withdrawal &&
            transactionItem.network &&
            transactionItem.network.fee && (
              <div className="row">
                <span> کارمزد شبکه</span>
                <Divider dashed />
                <div>
                  <span>
                    {digitSperator(transactionItem?.network.fee)}{' '}
                    {transactionItem.market.name}
                  </span>
                </div>
              </div>
            )}
        </>
      )}

      {transactionItem?.voucher && (
        <>
          <div className="row">
            <span>کد ووچر</span>
            <Divider dashed />
            <div>
              <span>{transactionItem?.voucher.voucherCode || ''}</span>
              <CopyOutlined
                onClick={() =>
                  copyClipboard(
                    transactionItem?.voucher.voucherCode || '',
                    'کد ووچر',
                  )
                }
              />
            </div>
          </div>
          <div className="row">
            <span>کد فعال ساز ووچر</span>
            <Divider dashed />
            <div>
              <span>
                {transactionItem?.voucher.voucerhActivationCode || ''}
              </span>
              <CopyOutlined
                onClick={() =>
                  copyClipboard(
                    transactionItem?.voucher.voucerhActivationCode || '',
                    'کد فعال ساز ووچر',
                  )
                }
              />
            </div>
          </div>
          <div className="row">
            <span>بچ نامبر</span>
            <Divider dashed />
            <div>
              <span>{transactionItem?.voucher.batchNumber || ''}</span>
              <CopyOutlined
                onClick={() =>
                  copyClipboard(
                    transactionItem?.voucher.batchNumber || '',
                    'بچ نامبر',
                  )
                }
              />
            </div>
          </div>
        </>
      )}
      {transactionItem?.digitalReceipt && (
        <>
          <div className="row">
            <span>رسید دیجیتال</span>
            <Divider dashed />
            <div>
              <a target="_blank" href={transactionItem.digitalReceipt}>
                <span className="en long-text">
                  {transactionItem.digitalReceipt}
                </span>
              </a>
            </div>
          </div>
        </>
      )}
      {transactionItem?.network &&
        transactionItem.type.id === TransactionType.Withdrawal && (
          <>
            <div className="row">
              <span>مقدار نهایی پس از کسر کارمزد</span>
              <Divider dashed />
              <div>
                <span>
                  {transactionItem?.amount - transactionItem?.network.fee}{' '}
                  {transactionItem.market.name}
                </span>
              </div>
            </div>
          </>
        )}
      {transactionItem?.bankAccount &&
        getMarketType(transactionItem?.market.symbol) === MarketType.IRT && (
          <div className="row">
            <span>حساب بانکی</span>
            <Divider dashed />
            <div className="bankaccount">
              <img
                src={
                  detect_bank(transactionItem?.bankAccount.cardNumber)
                    ?.bank_logo
                }
              />
              <span>
                {transactionItem?.type.title === 'برداشت'
                  ? transactionItem?.bankAccount.iBan
                  : formatCardNumber(transactionItem?.bankAccount.cardNumber)}
              </span>
            </div>
          </div>
        )}
      {transactionItem?.commission !== null &&
        transactionItem.commission !== 0 &&
        transactionItem?.market?.symbol !== 'PM' && (
          <div className="row">
            <span>کارمزد تراکنش</span>
            <Divider dashed />
            <div>
              <span>
                {digitSperator(transactionItem?.commission)}{' '}
                {transactionItem?.market.name}
              </span>
            </div>
          </div>
        )}
      {transactionItem?.txId && (
        <>
          <div className="row">
            <span>هش تراکنش (تی ایکس آیدی)</span>
            <Divider dashed />
            <div>
              {transactionItem?.network?.network.toUpperCase() === 'TRX' ? (
                <a
                  className="long-text"
                  href={`https://tronscan.org/#/transaction/${transactionItem.txId}`}
                >
                  {`https://tronscan.org/#/transaction/${transactionItem.txId.slice(0, 6)}...${transactionItem.txId.slice(-6)}`}
                </a>
              ) : (
                <>
                  <span className="long-text">
                    {transactionItem?.txId
                      ? `${transactionItem.txId.slice(0, 6)}...${transactionItem.txId.slice(-6)}`
                      : '-'}
                  </span>
                </>
              )}
              <CopyOutlined
                onClick={() =>
                  copyClipboard(transactionItem?.txId ?? '', 'هش تراکنش')
                }
              />
            </div>
          </div>
        </>
      )}
      {transactionItem?.wallet && (
        <div className="row">
          <span>آدرس کیف پول</span>
          <Divider dashed />
          <div>
            <span className="en">
              {transactionItem?.wallet.walletAddress
                ? `${transactionItem.wallet.walletAddress.slice(0, 6)}...${transactionItem.wallet.walletAddress.slice(-6)}`
                : ''}
            </span>
            <CopyOutlined
              onClick={() =>
                copyClipboard(
                  transactionItem?.wallet?.walletAddress || '',
                  'آدرس کیف پول',
                )
              }
            />
          </div>
        </div>
      )}
      {transactionItem?.createdAt && (
        <div className="row">
          <span>تاریخ و ساعت</span>
          <Divider dashed />
          <Divider dashed />
          <span>
            {moment
              .utc(transactionItem.createdAt)
              .locale('fa')
              .local()
              .format('  HH:mm  -  jYYYY/MM/DD  ')}
          </span>
        </div>
      )}
    </>
  );
};
