import { Link } from 'react-router-dom';
import './navigation-item.scss';
import { useLocation } from 'react-router-dom';
import React, { ReactNode, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
import { ReactComponent as ActiveIcon } from 'assets/icons/sidebar-child-active.svg';

interface Props {
  id: number;
  title: string;
  link?: string;
  icon?: ReactNode;
  active?: boolean;
  isLogout?: boolean;
  childrens?: {
    id: number;
    title: string;
    link: string;
  }[];
  isChild?: boolean;
}

export const NavigationItem: React.FC<Props> = (props) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Link
        to={props.link ?? ''}
        className={`${props.isChild ? 'xpay-navigation-item-child' : 'xpay-navigation-item'} ${location.pathname === props.link || props.childrens?.some((child) => location.pathname.startsWith(child.link)) ? 'active' : ''}`}
        onClick={(e) => {
          if (props.childrens && props.childrens.length > 0) {
            e.preventDefault();
            toggleDropdown();
          }
        }}
      >
        <div className="content">
          {props.icon}
          <p className="title">{props.title}</p>
        </div>
        {props.isChild &&
          location.pathname + location.search === props.link && <ActiveIcon />}
        {!props.isChild && (
          <LeftArrowIcon
            className="navigation-left-arrow"
            style={{
              transform: `rotate(${isOpen ? -90 : 0}deg)`,
              transition: 'all 0.2s ease',
            }}
          />
        )}
      </Link>
      {isOpen && (
        <ul className={'navigation-items-list'}>
          {props?.childrens?.map((item, i) => (
            <NavigationItem
              isChild
              id={item.id}
              title={item.title}
              link={item.link}
            />
          ))}
        </ul>
      )}
    </>
  );
};
