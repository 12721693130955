import './authentication-card.scss';
import { ReactComponent as Checked } from 'assets/icons/authentication/Checked.svg';
import { ReactComponent as Unchecked } from 'assets/icons/authentication/Unchecked.svg';
import { ReactComponent as PossibilityOn } from 'assets/icons/authentication/PossibilityOn.svg';
import { ReactComponent as PossibilityOff } from 'assets/icons/authentication/PossibilityOff.svg';
import { ReactComponent as Requirement } from 'assets/icons/authentication/Requirement.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/authentication/ChevronDown.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/authentication/ChevronUp.svg';
import { Divider } from 'antd';
import { Button } from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  stage: number;
  currentLevel: number;
}

export const AuthenticationCard: React.FC<Props> = ({
  stage,
  currentLevel,
}) => {
  const navigate = useNavigate();
  const [level0IsOpen, setLevel0IsOpen] = useState<boolean>(false);
  const [level1IsOpen, setLevel1IsOpen] = useState<boolean>(false);
  const [level2IsOpen, setLevel2IsOpen] = useState<boolean>(false);
  const level = currentLevel;
  return (
    <div className="xpay-authentication-card">
      {stage === 0 && (
        <>
          <div className="level0-background desktop-show">{stage}</div>
          {<Checked className="desktop-show" />}
          <h3>
            {<Checked className="mobile-show" />}احراز هویت سطح پیش فرض کاربران
            جدید
          </h3>
          <Divider />
          <div className="desktop-show">
            <h4>امکانات</h4>
            <div className="possibilities">
              <div className="info-row">
                <PossibilityOff /> واریز تومان ( غیر فعال )
              </div>
              <div className="info-row">
                <PossibilityOff /> برداشت تومان ( غیر فعال )
              </div>
              <div className="info-row">
                <PossibilityOff /> واریز شناسه دار ( غیر فعال )
              </div>
              <div className="info-row">
                <PossibilityOn /> واریز رمز ارز ( بدون محدودیت )
              </div>
              <div className="info-row">
                <PossibilityOff /> برداشت رمز ارز ( غیر فعال )
              </div>
              <div className="info-row">
                <PossibilityOn /> معامله ( بدون محدودیت )
              </div>
            </div>
            <h4>مدارک مورد نیاز برای احراز سطح پایه</h4>
            <div className="requirements">
              <div className="info-row">
                <Requirement /> ثبت نام
              </div>
              <div className="info-row">
                <Requirement /> تایید پیامکی شماره موبایل
              </div>
            </div>
            {/* <Button type='primary' text='ارتقا به سطح پیش فرض' onClick={() => navigate('upgrade')} disabled /> */}
          </div>

          <div className="mobile-show">
            {!level0IsOpen && (
              <Button
                className="view-button"
                type="primary"
                text="مشاهده امکانات"
                icon={<ChevronDown />}
                onClick={() => setLevel0IsOpen(!level0IsOpen)}
              />
            )}
            {level0IsOpen && (
              <>
                <h4>امکانات</h4>
                <div className="possibilities">
                  <div className="info-row">
                    <PossibilityOff /> واریز تومان ( غیر فعال )
                  </div>
                  <div className="info-row">
                    <PossibilityOff /> برداشت تومان ( غیر فعال )
                  </div>
                  <div className="info-row">
                    <PossibilityOff /> واریز شناسه دار ( غیر فعال )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> واریز رمز ارز ( بدون محدودیت )
                  </div>
                  <div className="info-row">
                    <PossibilityOff /> برداشت رمز ارز ( غیر فعال )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> معامله ( بدون محدودیت )
                  </div>
                </div>
                <h4>مدارک مورد نیاز برای احراز سطح یک</h4>
                <div className="requirements">
                  <div className="info-row">
                    <Requirement /> ثبت نام
                  </div>
                  <div className="info-row">
                    <Requirement /> تایید پیامکی شماره موبایل
                  </div>
                </div>
                {/* <Button type='primary' text='ارتقا به سطح پیش فرض' onClick={() => navigate('upgrade')} disabled /> */}
                <Divider />
                <Button
                  className="view-button"
                  type="primary"
                  text="بستن امکانات"
                  icon={<ChevronUp />}
                  onClick={() => setLevel0IsOpen(!level0IsOpen)}
                />
              </>
            )}
          </div>
        </>
      )}

      {stage === 1 && (
        <>
          <div className="level1-background desktop-show">{stage}</div>
          {level == 2 || level == 3 ? (
            <Checked className="desktop-show" />
          ) : (
            <Unchecked className="desktop-show" />
          )}
          <h3>
            {level == 2 || level == 3 ? (
              <Checked className="mobile-show" />
            ) : (
              <Unchecked className="mobile-show" />
            )}
            احراز هویت سطح 1
          </h3>
          <Divider />
          <div className="desktop-show">
            <h4>امکانات</h4>
            <div className="possibilities">
              <div className="info-row">
                <PossibilityOn /> واریز تومان ( ۲۵ میلیون تومان روزانه )
              </div>
              <div className="info-row">
                <PossibilityOn />
                برداشت تومان (۱۰ میلیون تومان روزانه )
              </div>
              <div className="info-row">
                <PossibilityOn />
                واریز شناسه دار ( غیر فعال )
              </div>
              <div className="info-row">
                <PossibilityOn /> واریز رمز ارز ( بدون محدودیت )
              </div>
              <div className="info-row">
                <PossibilityOff />
                برداشت رمز ارز ( تا سقف ۵۰۰ هزار تومان )
              </div>
              <div className="info-row">
                <PossibilityOn /> معامله ( بدون محدودیت )
              </div>
            </div>
            <h4>مدارک مورد نیاز برای احراز سطح یک</h4>
            <div className="requirements">
              <div className="info-row">
                <Requirement />
                فرم اطلاعات فردی
              </div>
              <div className="info-row">
                <Requirement />
                ثبت کارت بانکی{' '}
              </div>
              <div className="info-row">
                <Requirement />
                تکمیل اطلاعات فردی
              </div>
            </div>
            <Button
              type="primary"
              text="ارتقا به سطح یک"
              onClick={() => navigate('one')}
              disabled={level !== 1}
            />
          </div>

          <div className="mobile-show">
            {!level1IsOpen && (
              <Button
                className="view-button"
                type="primary"
                text="مشاهده امکانات"
                icon={<ChevronDown />}
                onClick={() => setLevel1IsOpen(!level1IsOpen)}
              />
            )}
            {level1IsOpen && (
              <>
                <h4>امکانات</h4>
                <div className="possibilities">
                  <div className="info-row">
                    <PossibilityOn /> واریز تومان ( ۲۵ میلیون تومان روزانه )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> برداشت تومان ( ۱۰ میلیون تومان روزانه )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> واریز شناسه دار ( بدون محدودیت )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> واریز رمز ارز ( بدون محدودیت )
                  </div>
                  <div className="info-row">
                    <PossibilityOff /> برداشت رمز ارز ( تا سقف ۵۰۰ هزار تومان )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> معامله ( بدون محدودیت )
                  </div>
                </div>
                <h4>مدارک مورد نیاز برای احراز سطح دو</h4>
                <div className="requirements">
                  <div className="info-row">
                    <Requirement />
                    فرم اطلاعات فردی
                  </div>
                  <div className="info-row">
                    <Requirement />
                    ثبت کارت بانکی
                  </div>
                  <div className="info-row">
                    <Requirement />
                    تکمیل اطلاعات فردی
                  </div>
                </div>
                <Button
                  type="primary"
                  text="ارتقا به سطح یک"
                  onClick={() => navigate('one')}
                  disabled={level !== 1}
                />
                <Divider />
                <Button
                  className="view-button"
                  type="primary"
                  text="بستن امکانات"
                  icon={<ChevronUp />}
                  onClick={() => setLevel1IsOpen(!level1IsOpen)}
                />
              </>
            )}
          </div>
        </>
      )}

      {stage === 2 && (
        <>
          <div className="level2-background desktop-show">{stage}</div>
          {level == 3 ? (
            <Checked className="desktop-show" />
          ) : (
            <Unchecked className="desktop-show" />
          )}
          <h3>
            {level == 3 ? (
              <Checked className="mobile-show" />
            ) : (
              <Unchecked className="mobile-show" />
            )}
            احراز هویت سطح 2
          </h3>
          <Divider />
          <div className="desktop-show">
            <h4>امکانات</h4>
            <div className="possibilities">
              <div className="info-row">
                <PossibilityOn />
                واریز تومان ( ۲۵ میلیون تومان روزانه )
              </div>
              <div className="info-row">
                <PossibilityOn /> برداشت تومان ( بدون محدودیت )
              </div>
              <div className="info-row">
                <PossibilityOn /> واریز شناسه دار ( بدون محدودیت )
              </div>
              <div className="info-row">
                <PossibilityOn /> واریز رمز ارز ( بدون محدودیت )
              </div>
              <div className="info-row">
                <PossibilityOn /> برداشت رمز ارز ( بدون محدودیت )
              </div>
              <div className="info-row">
                <PossibilityOn /> معامله ( بدون محدودیت )
              </div>
            </div>
            <h4>مدارک مورد نیاز برای احراز سطح دو</h4>
            <div className="requirements">
              <div className="info-row">
                <Requirement />
                بارگذاری تصویر سلفی با مدارک
              </div>
            </div>
            <Button
              type="primary"
              text="ارتقا به سطح دو"
              onClick={() => navigate('two')}
              disabled={level !== 2}
              className={'upgrade-to-2-button'}
            />
          </div>
          <div className="mobile-show">
            {!level2IsOpen && (
              <Button
                className="view-button"
                type="primary"
                text="مشاهده امکانات"
                icon={<ChevronDown />}
                onClick={() => setLevel2IsOpen(!level2IsOpen)}
              />
            )}
            {level2IsOpen && (
              <>
                <h4>امکانات</h4>
                <div className="possibilities">
                  <div className="info-row">
                    <PossibilityOn />
                    واریز تومان ( ۲۵ میلیون تومان روزانه )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> برداشت تومان ( بدون محدودیت )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> واریز شناسه دار ( بدون محدودیت )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> واریز رمز ارز ( بدون محدودیت )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> برداشت رمز ارز ( بدون محدودیت )
                  </div>
                  <div className="info-row">
                    <PossibilityOn /> معامله ( بدون محدودیت )
                  </div>
                </div>
                <h4>مدارک مورد نیاز برای احراز سطح دو</h4>
                <div className="requirements">
                  <div className="info-row">
                    <Requirement /> بارگذاری تصویر سلفی
                  </div>
                  <div className="info-row">
                    <Requirement /> تکمیل احراز هویت
                  </div>
                </div>
                <Button
                  type="primary"
                  text="ارتقا به سطح دو"
                  onClick={() => navigate('two')}
                  disabled={level !== 2}
                />
                <Divider />
                <Button
                  className="view-button"
                  type="primary"
                  text="بستن امکانات"
                  icon={<ChevronUp />}
                  onClick={() => setLevel2IsOpen(!level2IsOpen)}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
