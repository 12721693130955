import { Card } from 'components';
import './wallet-box.scss';
import { ReactComponent as ProgressCircle } from 'assets/icons/main/wallet-box/ProgressCircle.svg';
import { ReactComponent as BlueDot } from 'assets/icons/main/BlueDot.svg';
import { ReactComponent as GreenDot } from 'assets/icons/main/GreenDot.svg';
import { digitSperator } from 'utils/scripts/exchange';
import { useState, useEffect } from 'react';
import { Asset } from 'core/domains/markets/asset';
import { getViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { Spinner } from '../../../../components/spinner/spinner';
import { getMineAssets } from '../../../../core/repositories/wallet/wallet';
import Progress from 'antd/es/progress';
import * as React from 'react';

export const WalletBox = () => {
  const [assets, setAssets] = useState<Asset>();
  const [loading, setLoading] = useState<boolean>(false);
  const [percentages, setPercentages] = useState({ irt: 0, crypto: 0 });

  const getMineAssets = () => {
    setLoading(true);
    getViaAuth<Asset>(`${API.wallet.assets}`).then((data: any) => {
      if (responseValidator(data.status) && data.value) {
        setAssets(data.value);
      }
      setLoading(false);
    });
  };

  const handleProgress = () => {
    if (assets) {
      const cryptoDollarValue = assets?.assets
        .filter((asset) => asset.symbol.toUpperCase() !== 'IRT')
        .reduce((acc, asset) => acc + asset.balance * asset.lastPrice, 0);

      const irtDollarValue = assets?.usdtBalance - cryptoDollarValue;

      const usdtBalance = assets.usdtBalance || 0.01;

      const irtPercent = (irtDollarValue / usdtBalance) * 100;
      const cryptoPercent = (cryptoDollarValue / usdtBalance) * 100;

      setPercentages({
        irt: irtPercent ?? 0,
        crypto: cryptoPercent ?? 0,
      });
    }
  };

  useEffect(() => {
    !assets && getMineAssets();
    handleProgress();
  }, [assets]);

  return (
    <Card
      className="wallet-box"
      title="کیف پول"
      hrefTitle="انتقال به صفحه کیف پول"
      href="/dashboard/wallet"
      divider
      blur={loading}
    >
      <div className="top-section">
        <div className="col">
          <h5>مجموع کل دارایی</h5>
          <span>{digitSperator(assets?.irtBalance?.toFixed(0))} تومان</span>
        </div>
        <Progress
          percent={percentages?.irt}
          strokeColor={'rgb(21, 82, 240)'}
          trailColor={'rgb(64, 197, 242)'}
          type="circle"
          strokeWidth={10}
          format={() => <></>}
          showInfo
        />
      </div>
      <div className="bottom-section">
        <div className="col">
          <h5>
            <GreenDot /> ارزش رمز ارز (دلاری)
          </h5>
          <span>{Number(assets?.usdtBalance).toFixed(2)} دلار</span>
        </div>
        <div className="col">
          <h5>
            <GreenDot /> ارزش رمز ارز (تومانی)
          </h5>
          <span>
            {digitSperator(
              Number((assets?.irtBalance ?? 0).toFixed(0)) -
                Number(
                  (
                    assets?.assets?.find(
                      (asset) => asset?.symbol?.toUpperCase() === 'IRT',
                    )?.balance ?? 0
                  ).toFixed(0),
                ),
            )}{' '}
            تومان
          </span>
        </div>
        <div className="col">
          <h5>
            <BlueDot /> موجودی تومانی
          </h5>
          <span>
            {digitSperator(
              assets?.assets
                ?.find((asset) => asset.symbol.toUpperCase() === 'IRT')
                ?.balance?.toFixed(0),
            )}{' '}
            تومان
          </span>
        </div>
      </div>
    </Card>
  );
};
