import * as React from 'react';
import './coins-dropdown.scss';
import 'components/transaction-box/transaction-box.scss';
import { CloseOutlined, PushpinOutlined, SearchOutlined, } from '@ant-design/icons';
import { Input } from 'components';
import { Market } from 'core/domains';
import { ToIranCurrency } from 'utils/scripts/decimal';
import { getIconPath } from "../../../utils/scripts/getIconPath";

const { useState } = React;

type Props = {
    selected?: Market;
    onClick: any;
    isOpen: boolean;
    onClose: any;
    markets: Market[];
    ref: any
};

export const CoinDropDown: React.FC<Props> = ({
    isOpen,
    markets,
    selected,
    onClick,
    onClose,
    ref
}) => {
    const [search, setSearch] = useState<string>('');
    const [results, setResults] = useState<Market[]>(markets);

    const onSearchChangeHandler = (value: string) => {
        setSearch(value);
        setResults(
            markets.filter((s) => s.symbol.includes(value) || s.name.includes(value)),
        );
    };

    React.useEffect(() => {
        if (isOpen)
            setResults(
                markets.filter((s) => s.symbol.includes('') || s.name.includes('')),
            );
    }, [isOpen]);

    return (
        <div ref={ref} className={`xpay-coins-dropdown ${isOpen ? 'active' : ''}`}>
            <div className="searchbox">
                <Input
                    type="text"
                    dir={'rtl'}
                    placeholder="نام یا نماد ارز مورد نظر خود را جست و جو کنید"
                    adorments={{
                        startAdornment: {
                            adornmentIcon: <SearchOutlined />,
                        },
                        endAdornment: {
                            adornmentIcon: <CloseOutlined onClick={() => onClose(false)} />,
                        },
                    }}
                    name="searchvalue"
                    value={search}
                    onChange={(e) => onSearchChangeHandler(e.target.value)}
                />
            </div>
            <ul>
                <li
                    className="pinned"
                    onClick={() => {
                        onClick(
                            results.filter((coin) => coin.symbol.toLowerCase() === 'pm')[0],
                        );
                        onClose(false);
                    }}
                >
                    <div className="coin-info">
                        <PushpinOutlined />
                        <img src={`/coins/pm.svg`} />
                        <span>ووچر پرفکت مانی</span>
                        <span>PM</span>
                    </div>
                    <div className="price-info">
                        <span>
                            قیمت خرید : <b>{ToIranCurrency(0)} تومان</b>
                        </span>
                    </div>
                </li>

                <li
                    className="pinned"
                    onClick={() => {
                        onClick(
                            results.filter((coin) => coin.symbol.toLowerCase() === 'usdt')[0],
                        );
                        onClose(false);
                    }}
                >
                    <div className="coin-info">
                        <PushpinOutlined />
                        <img src={`/coins/usdt.svg`} />
                        <span>تتر</span>
                        <span>usdt</span>
                    </div>
                    <div className="price-info">
                        <span>
                            قیمت خرید : <b>{ToIranCurrency(0)} تومان</b>
                        </span>
                    </div>
                </li>

                <li
                    className="pinned"
                    onClick={() => {
                        onClick(
                            results.filter((coin) => coin.symbol.toLowerCase() === 'trx')[0],
                        );
                        onClose(false);
                    }}
                >
                    <div className="coin-info">
                        <PushpinOutlined />
                        <img src={`/coins/trx.svg`} />
                        <span>ترون</span>
                        <span>trx</span>
                    </div>
                    <div className="price-info">
                        <span>
                            قیمت خرید : <b>{ToIranCurrency(0)} تومان</b>
                        </span>
                    </div>
                </li>

                {results
                    .filter((coin) => coin.symbol.toLowerCase() !== 'irt')
                    .filter((coin) => coin !== selected)
                    .filter((coin) => coin.symbol.toLowerCase() !== 'trx')
                    .filter((coin) => coin.symbol.toLowerCase() !== 'pm')
                    .filter((coin) => coin.symbol.toLowerCase() !== 'usdt')
                    .map((item, index) => (
                        <React.Fragment key={`coin-${index}`}>
                            <li
                                onClick={() => {
                                    onClick(item);
                                    onClose(false);
                                }}
                            >
                                <div className="coin-info">
                                    <img src={getIconPath(item.symbol)} />
                                    <span>{item.name}</span>
                                    <span>{item.symbol}</span>
                                </div>
                                <div className="price-info">
                                    <span>
                                        قیمت خرید : <b>{ToIranCurrency(item.sellPrice)} تومان</b>
                                    </span>
                                </div>
                            </li>
                        </React.Fragment>
                    ))}
            </ul>
        </div>
    );
};
