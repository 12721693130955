import { Alert, Button, Card, Input } from 'components';
import { ReactComponent as ReturnArrow } from 'assets/icons/bank-accounts/ReturnArrow.svg';
import { ReactComponent as BankCard } from 'assets/icons/bank-accounts/Card.svg';
import { useNavigate } from 'react-router-dom';
import './add-card.scss';
import { Divider } from 'antd';
import React from 'react';
import { createBankAccountByCard } from 'core/repositories';

export const AddCard = () => {
  const navigate = useNavigate();
  const { useState } = React;
  const [cardNumber, setCardNumber] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const formatCardNumber = (input: string) => {
    const numericInput = input.replace(/\D/g, '');
    const formattedInput = numericInput
      .slice(0, 16)
      .replace(/(\d{4})/g, '$1-')
      .slice(0, 19);
    setCardNumber(formattedInput);
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    formatCardNumber(inputValue);
  };

  const handleAddCard = async () => {
    cardNumber.length === 19 &&
      (await createBankAccountByCard(setLoading, cardNumber));
  };

  return (
    <Card
      className="xpay-add-card"
      title="افزودن شماره کارت"
      button={
        <Button
          onClick={() => navigate('/dashboard/bank-accounts')}
          type="primary"
          text="بازگشت"
          icon={<ReturnArrow />}
        />
      }
      divider
    >
      <div className="add-section">
        <h4 className="field-label">شماره کارت</h4>
        <div className="field">
          <BankCard />
          <input
            id="cardNumber"
            name="cardNumber"
            value={cardNumber}
            onChange={(e) => handleCardNumberChange(e)}
            maxLength={19}
          />
        </div>
        <Button
          text="ثبت شماره کارت"
          type="primary"
          loading={loading}
          onClick={handleAddCard}
          disabled={cardNumber.length < 19}
        />
      </div>
      <Divider className="vertical-divider" type="vertical" />
      <div className="hint-section">
        <Alert
          type="warning"
          title={'اخطار!'}
          message="شماره کارت باید به نام خودتان باشد."
        />
      </div>
    </Card>
  );
};
