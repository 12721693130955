import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { toast } from 'react-toastify';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

toast.warning('برای تجربه بهتر از خدمات سایت وی پی ان خود را خاموش کنید', {
  position: 'bottom-right',
  style: { width: '100%', bottom: 'unset', background: '#FEF6E6' },
  autoClose: false,
  draggable: true,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
