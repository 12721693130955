// Formats 09123456789 To 0912 345 6789
export function formatPhoneNumber(phoneNumber: any) {
  if (!phoneNumber) return phoneNumber;
  const phoneNumberString = phoneNumber.toString();
  const numericPhoneNumber = phoneNumberString.replace(/\D/g, '');
  if (numericPhoneNumber.length !== 11 || isNaN(numericPhoneNumber)) {
    console.error('Invalid phone number');
    return phoneNumberString;
  }
  const formattedPhoneNumber = `${numericPhoneNumber.slice(
    7,
  )} ${numericPhoneNumber.slice(4, 7)} ${numericPhoneNumber.slice(0, 4)}`;
  return formattedPhoneNumber;
}
