import React from 'react';
import './authentication-stepper.scss';

interface AuthenticationStepperProps {
  step: number;
}

const stepTexts = ['آدرس محل سکونت', 'عکس سلفی'];

export const AuthenticationStepper: React.FC<AuthenticationStepperProps> = ({
  step,
}) => {
  return (
    <div className="xpay-authentication-stepper">
      {/* Step 1 */}
      <div className={`step ${1 <= step ? 'active' : ''}`}>
        <div className="circle">1</div>
        <div className="text">{stepTexts[0]}</div>
      </div>
      <div className={`line ${2 <= step ? 'active' : ''}`} />

      {/* Step 2 */}
      <div className={`step ${2 <= step ? 'active' : ''}`}>
        <div className="circle">2</div>
        <div className="text">{stepTexts[1]}</div>
      </div>
    </div>
  );
};
