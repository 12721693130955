export const SERVER_ADDRESS = 'https://api.xpay.co/';
export const SERVER_URL = 'https://api.xpay.co/v1/';
export const SITE_ADDRESS = 'www.XPAY.co';
export const SITE_URL = 'https://xpay.co';
export const CDN_URL = 'https://cdn.xpay.co';

export const APPNAME = 'ایکس پی';
export const APP_VERSION = '1.0.32';
export const APP_LINK = 'https://cafebazaar.ir/app/co.app.xpay';

export const Logo = '/xpay-logo-type.svg';
export const Fav = '/fav.png';

export const SAMPLEAUTHPHOTOLevel2 =
  '/assets/images/auth/auth-samples/xpay/level2.jpg';
export const SAMPLEAUTHPHOTOLevel3 =
  '/assets/images/auth/auth-samples/xpay/level3.jpg';

export const ReferralPost = '/assets/images/referral/xpay-referral-post.jpg';
export const ReferralStory = '/assets/images/referral/xpay-referral-story.png';

export const MANIFEST = 'manifest-Xpay.json';

export const CHANNEL_ID = 'https://t.me/Xpay_co';
export const TGBOT_ID = 'https://t.me/xpay_robot';

export const ONLINE_CHAT = '/xpay-goftino.js';
export const ONLINE_CHAT_LOGO = '/assets/icons/logo-chat-desktop.svg';

export const SLIDER_BANNER = '/assets/images/xpay-telegram-slide.png';

export const GTM = '/gtm-xpay.js';

export const TERM_LINK = 'https://xpay.co/terms/';
