import { Alert, Button, Card, Dropdown } from 'components';
import './authentication-two.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Close } from 'assets/icons/authentication/Close.svg';
import { ReactComponent as Continue } from 'assets/icons/authentication/Continue.svg';
import { ReactComponent as UploadImage } from 'assets/icons/authentication/UploadImage.svg';
import { ReactComponent as BlueDot } from 'assets/icons/authentication/BlueDot.svg';
import { ReactComponent as CongratulationsMask } from 'assets/masks/authentication/CongratulationsMask.svg';
import { ReactComponent as Tick } from 'assets/icons/authentication/Tick.svg';
import { ReactComponent as Retry } from 'assets/icons/authentication/Retry.svg';
import { AuthenticationStepper } from './authentication-stepper/authentication-stepper';
import { Divider, Image, Upload } from 'antd';
import cities from 'assets/constants/cities.json';
import provinces from 'assets/constants/provinces.json';
import { UploadProps } from 'antd/lib/upload';
import warning from 'assets/images/authentication/warning.png';
import {
  addAddressAndPostalCodeUpgradeToLevel2,
  checkForAddressAndPostalCode,
  checkForLevel,
} from 'core/repositories';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { toast } from 'react-toastify';
import { readProfile } from '../../../../core/repositories/profile/profile';
import moment from 'jalali-moment';
import selfiePreview from 'assets/images/authentication/selfie-preview.jpg';
import { APPNAME } from '../../../../apis/constants';

interface LevelTwoInfo {
  number: string;
  code: string;
  province: string | number;
  city: string | number;
  address: string;
  postalCode: string;
  documentImage?: any;
  selfieImage?: any;
}

export const AuthenticationTwo = () => {
  const navigate = useNavigate();
  const parsedLevel = JSON.parse(localStorage.getItem('level') ?? 'null');
  const [step, setStep] = useState<number>(0);
  const [info, setInfo] = useState<LevelTwoInfo>({
    number: '',
    code: '',
    province: '',
    city: '',
    address: '',
    postalCode: '',
    documentImage: undefined,
    selfieImage: undefined,
  });
  const [timer, setTimer] = useState<number>(30);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [rejectionMessage, setRejectionMessage] = useState<string>();

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    parsedLevel?.uniqueName?.id === 2 ? handleChecks() : navigate('/dashboard');
  }, []);

  const handleChecks = async () => {
    setIsChecking(true);
    await checkForAddressAndPostalCode(setStep);
    await checkForLevel(setStep, setRejectionMessage);
    setIsChecking(false);
  };

  const handleAddressAndPostalCodeSubmit = async () => {
    await addAddressAndPostalCodeUpgradeToLevel2(
      setLoading,
      {
        address:
          provinces.find((province) => province?.id === info?.province)?.title +
          ' - ' +
          cities.find((city) => city.id === info?.city)?.title +
          ' - ' +
          info?.address,
        postalCode: info?.postalCode,
      },
      () => {
        setStep(3);
      },
    );
  };

  const handleImageSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    if (info.selfieImage != '') {
      formData.append('SelfiePic', info.selfieImage);
    }

    await postViaAuth(API.level.uploadFilesUpgradeToLevelTwo, formData, true, {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent;
        const progress = Math.round((loaded / total) * 100);
        console.log(`Upload Progress: ${progress}%`);
      },
    }).then((response: any) => {
      if (responseValidator(response.status)) {
        setRejectionMessage(undefined);
        setStep(4);
      } else {
        toast.error(response.message);
      }
    });
    setLoading(false);
  };

  const handleTryAgain = (infoImageKey: keyof LevelTwoInfo) => {
    setInfo((prevInfo) => ({ ...prevInfo, [infoImageKey]: undefined }));
  };

  const handlePreviewClick = () => {
    setPreviewVisible(true);
  };

  const handlePreviewClose = () => {
    setPreviewVisible(false);
  };

  const selfieDraggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    customRequest: ({ file }) => {
      if (file) {
        setInfo((prevInfo) => ({ ...prevInfo, selfieImage: file }));
        toast.success('عکس با موفقیت انتخاب شد');
      }
    },
  };

  return (
    <>
      {rejectionMessage && (
        <Alert
          className={'rejection-message-alert'}
          title={'احراز هویت شما رد شد!'}
          type={'danger'}
          message={rejectionMessage}
          closeAble
        />
      )}
      {step !== 4 && (
        <Card
          blur={isChecking}
          title="ارتقا به سطح دو"
          className="xpay-authentication-two"
          button={
            <Close onClick={() => navigate('/dashboard/authentication')} />
          }
          divider
        >
          {step == 0 && (
            <div className="step-warning-content">
              <div className="bg">
                <img src={warning} />
                <span>هشــدار!</span>
              </div>
              <p>ممکنه کاسه ای زیر نیم کاسه باشه!</p>
              <p>
                اگر فردی از شما درخواست ثبت نام در سایت های ارز دیجیتال مانند
                {APPNAME}را کرده و در قبال آن پیشنهاد دریافت حقوق و کارمزد را
                داده است، از پذیرفتن آن جدا خودداری کنید. چرا که این افراد قصد
                پولشویی از طریق حساب های بانکی شما دارند که در این صورت شما را
                درگیر مسائل پیچیده قضایی خواهد کرد. جهت دریافت اطلاعات بیشتر با
                پشتیبانی {APPNAME} در تماس باشید.
              </p>
              <span>
                <input
                  type="checkbox"
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />{' '}
                متن و قوانین را مشاهده کردم و از موارد اشاره شده آگاه هستم
              </span>
              <Button
                type="primary"
                text="تایید قوانین و ادامه"
                disabled={!isCheckboxChecked}
                onClick={() => setStep(1)}
              />
            </div>
          )}
          {step == 1 && (
            <div className="step-agreement-content">
              <p>
                تایید این مرحله به منزله ی پذیرش تمامی قوانین است، آیا تایید
                میکنید؟
              </p>
              <div className="buttons">
                <Button
                  text="تایید قوانین و ادامه"
                  type="primary"
                  onClick={() => setStep(2)} //Skip Landline Step
                />
                <Button
                  text="رد و بازگشت"
                  type="secondary"
                  onClick={() => setStep(-1)}
                />
              </div>
            </div>
          )}
          {step == 2 && (
            <>
              <AuthenticationStepper step={1} />
              <Divider className="step-divider" />
              <div className="step-content">
                <div className="province-city">
                  <Dropdown
                    items={provinces}
                    onChangeHandle={(selectedProvinceId) => {
                      setInfo((prevInfo) => ({
                        ...prevInfo,
                        province: selectedProvinceId,
                      }));
                    }}
                  />
                  <Dropdown
                    items={
                      info?.province && cities
                        ? cities?.filter(
                            (city) => city?.province_id === info?.province,
                          )
                        : [{ id: 0, title: 'انتخاب شهر' }]
                    }
                    onChangeHandle={(selectedCityId) => {
                      setInfo({ ...info, city: selectedCityId });
                    }}
                  />
                </div>
                <textarea
                  className="address"
                  placeholder="آدرس محل سکونت"
                  value={info?.address}
                  onChange={(e) =>
                    setInfo({
                      ...info,
                      address: e.target.value,
                    })
                  }
                />
                <div className="field">
                  <input
                    placeholder="کد پستی محل سکونت"
                    maxLength={12}
                    onChange={(e) =>
                      setInfo({ ...info, postalCode: e.target.value })
                    }
                  />
                </div>
                <Button
                  type="primary"
                  loading={loading}
                  disabled={
                    loading ||
                    info.postalCode === '' ||
                    info.address === '' ||
                    info.province === '' ||
                    info.city === ''
                  }
                  text="ثبت و ادامه"
                  icon={<Continue />}
                  onClick={handleAddressAndPostalCodeSubmit}
                />
              </div>
            </>
          )}
          {step == 3 && (
            <>
              <AuthenticationStepper step={2} />
              <Divider className="step-divider" />
              <div className="step-content">
                <p>
                  متن زیر را روی کاغذ بنویسید و همراه کارت‌شناسایی و کارت بانکی
                  در کنار چهره خود قرار دهید و یک سلفی واضح و خوانا از خود
                  بگیرید:
                </p>
                <div className="requirements">
                  <div className="item">
                    <BlueDot /> <span>تصویر اسکن شده نباشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>تصویر بریده و ناقص نباشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>انعکاس نور وجود نداشته باشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot /> <span>تصویر تار و ناخوانا نباشد.</span>
                  </div>
                  <div className="item">
                    <BlueDot />{' '}
                    <span>بدون عینک آفتابی و ماسک تصویربرداری شود.</span>
                  </div>
                </div>
                <p id="handwritten">متن دست نوشته:</p>
                <div className="handwritten-prototype">
                  <p>
                    اینجانب {readProfile()?.fullName} به کد ملی{' '}
                    {readProfile()?.nationalCode} در تاریخ{' '}
                    {moment().locale('fa').format('jYYYY/jMM/jDD')}
                    ضمن مطالعه قوانین {APPNAME} و‌ اطلاع از اینکه کلاه برداران
                    فضای مجازی با عناوینی مثل کاریابی اینترنتی ، دورزدن مالیات ،
                    سقف تراکنش روزانه قصد سو استفاده از حساب دیگران را دارند ،
                    تعهد میدهم که مدارک و حساب کاربری خود را به منظور خرید و
                    فروش ارزهای دیجیتال یا دیگر خدمات {APPNAME} در اختیار سایر
                    افراد قرار ندهم ، در غیر این صورت ، مسئولیت جبران هرگونه
                    خسارت ناشی یا بروز مشکل کیفری و حقوقی از این امر را بر عهده
                    خواهم گرفت.
                    <br />
                    <br />
                    نام و نام‌خانوادگی، امضا و تاریخ
                  </p>
                </div>
                <div className="image-upload">
                  <Upload.Dragger {...selfieDraggerProps}>
                    <>
                      {info.selfieImage && (
                        <img
                          src={URL.createObjectURL(info.selfieImage)}
                          alt="Uploaded"
                          height={160}
                        />
                      )}
                      {!info.selfieImage && (
                        <div>
                          <UploadImage />
                          <p>افزودن تصویر</p>
                        </div>
                      )}
                    </>
                  </Upload.Dragger>
                </div>
                {info.selfieImage && (
                  <p id="retry" onClick={() => handleTryAgain('selfieImage')}>
                    انتخاب مجدد <Retry />
                  </p>
                )}
                {/*<Button*/}
                {/*  type="primary"*/}
                {/*  className="preview"*/}
                {/*  text="مشاهده نمونه عکس"*/}
                {/*  icon={<Preview />}*/}
                {/*  onClick={handlePreviewClick}*/}
                {/*/>*/}
                <Image
                  style={{ display: 'none' }}
                  src={selfiePreview}
                  preview={{
                    visible: previewVisible,
                    src: selfiePreview,
                    onVisibleChange: handlePreviewClose,
                  }}
                />
                <Button
                  className="image-submit"
                  type="primary"
                  text="ثبت و ادامه"
                  icon={<Continue />}
                  loading={loading}
                  disabled={loading || !info.selfieImage}
                  onClick={handleImageSubmit}
                />
              </div>
            </>
          )}
        </Card>
      )}
      {step == 4 && (
        <Card className="xpay-authentication-two congratulations">
          <div className="step-congratulations">
            <CongratulationsMask id="mask" />
            <Tick id="tick" />
            <div className="step-content">
              <h5>تبریک، مدارک شما با موفقیت آپلود شد!</h5>
              <p>
                مدارک شما در حال بررسی توسط واحد احراز هویت میباشد و پس از تایید
                یا رد از طریق پیامک مطلع خواهید شد. داشته باشید.
              </p>
              <Button
                type="primary"
                text="انتقال به صفحه اصلی"
                icon={<Continue />}
                onClick={() => navigate('/dashboard')}
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
