import * as React from 'react';
import { SwiperProps } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Card, Input, Modal } from 'components';
import { copyClipboard } from 'utils/scripts/copyToClipboard';
import { ReactComponent as InfoIcon } from 'assets/icons/referral/info.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/referral/copy.svg';
import { ReactComponent as GiftIcon } from 'assets/icons/referral/gift.svg';
import { ReactComponent as SwapIcon } from 'assets/icons/referral/swap.svg';
import { ReactComponent as FriendsIcon } from 'assets/icons/referral/friends.svg';
import { ReactComponent as ScanIcon } from 'assets/icons/referral/scan.svg';
import './referral.scss';
import { ReferralActionsHistory } from './referral-actions-history/referral-actions-history';
import { ReferralInvitedUsers } from './referral-invited-users/referral-invited-users';
import { postViaAuth, responseValidator } from '../../../utils/scripts/api';
import { getMineReferral } from 'core/repositories/referral/referral';
import { ReferralInfo } from 'core/domains/referral/referral';
import { API } from 'apis';
import {
  deleteCommaSperator,
  digitSeparator,
  digitSperator,
} from 'utils/scripts/exchange';
import { faNumToEnNum, ToIranCurrency } from 'utils/scripts/decimal';
import { APPNAME, ReferralStory } from 'apis/constants';
import referralBanner from 'assets/images/referral/davat-ill-sadi 1.png';
import { toast } from 'react-toastify';

const { useState, useRef } = React;
export const Referral: React.FC = () => {
  const [isInvitationModalOpen, setIsInvitationModalOpen] =
    React.useState<boolean>(false);
  const [isAdvertisingModalOpen, setIsAdvertisingModalOpen] =
    React.useState<boolean>(false);
  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] =
    React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<string[]>([]);
  const [referralInfo, setReferralInfo] = useState<ReferralInfo>();
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);

  const navigationNextRef = useRef<HTMLDivElement>(null);
  const navigationPrevRef = useRef<HTMLDivElement>(null);

  const swiper = useRef<SwiperProps>(null);

  // const handlePrev = () => {
  //   if (!swiper.current) return;
  //   swiper.current.swiper.slidePrev();
  // };
  //
  // const handleNext = () => {
  //   if (!swiper.current) return;
  //   swiper.current.swiper.slideNext();
  // };

  const fetchAllData = async () => {
    await getMineReferral(setLoading, setReferralInfo);
  };

  React.useEffect(() => {
    fetchAllData();
  }, []);

  const defaultLinkText = useRef<HTMLElement>(null);
  const invitationLinkText = useRef<HTMLParagraphElement>(null);

  const onWithdrawalAmountChange = (value: string) => {
    if (value !== undefined) value = faNumToEnNum(deleteCommaSperator(value));
    console.log(value);
    setWithdrawalAmount(value);
  };

  const ListHead: React.FC<{ title: string; name: string }> = (pr) => (
    <div className="head">
      <div className="right">
        <h4>{pr.title}</h4>
      </div>

      <Button
        text={`${isExpanded ? 'بستن' : 'نمایش'} لیست`}
        type="outlined"
        icon={isExpanded ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClick={() =>
          isExpanded.filter((value) => value === pr.name)[0]
            ? setIsExpanded(isExpanded.slice(isExpanded.indexOf(pr.name)))
            : setIsExpanded([...isExpanded, pr.name])
        }
      />
    </div>
  );

  const onConfirmHandler = () => {
    setLoading(true);
    if (withdrawalAmount)
      postViaAuth(API.referral.withdrawal, {
        amount: deleteCommaSperator(withdrawalAmount),
      }).then(function (response: any) {
        setLoading(false);
        setIsWithdrawalModalOpen(false);
        if (responseValidator(response.status)) {
          toast.success('درخواست برداشت با موفقیت ثبت شد.');
        }
      });
  };

  return (
    <>
      <div className="xpay-dashboard-referral">
        <Modal
          className="referral-invitation-modal"
          isOpen={isInvitationModalOpen}
          setIsOpen={setIsInvitationModalOpen}
          closeAble
          title="اشتراک گذاری لینک دعوت"
        >
          <div className="content-description">
            <p>
              با استفاده از کد دعوت {referralInfo?.code} کمتر از یک دقیقه در
              {APPNAME} ثبت نام کنید و به سرعت به خرید و فروش ارز های دیجیتال
              بپردازید.
              <br />
              کارمزد اولین تراکنش شما رایگان خواهد بود.
              <br /> بهترین نرخ خرید و فروش احراز هویت سریع
              <br /> تسویه حساب فوری ریالی و ارزی
              <br /> {APPNAME}، پلتفرم معاملاتی ارز های دیجیتال
              <br />
              {location.origin}/register?ref={referralInfo?.code}
            </p>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="کپی متن دعوت"
              htmlType="button"
              onClick={() =>
                copyClipboard(
                  `
با استفاده از کد دعوت ${referralInfo?.code} در
${APPNAME} ثبت نام کنید و به سرعت به خرید و فروش ارزهای دیجیتال بپردازید.

کارمزد اولین تراکنش شما رایگان خواهد بود. 

بهترین نرخ خرید و فروش                  
احراز هویت سریع                           
تسویه حساب فوری ریالی و ارزی             

${APPNAME}، پلتفرم معاملاتی ارزهای دیجیتال

${location.origin}/register?ref=${referralInfo?.code}
      `,
                  'متن دعوت',
                )
              }
            />
            <Button
              type="outlined"
              text="ارسال در تلگرام"
              htmlType="button"
              onClick={() =>
                window.open(
                  `tg://msg_url?url=با استفاده از کد دعوت ${referralInfo?.code} کمتر از یک دقیقه در ${APPNAME} ثبت نام کنید و به سرعت به خرید و فروش ارز های دیجیتال بپردازید.  کارمزد اولین تراکنش شما رایگان خواهد بود.  بهترین نرخ خرید و فروش  احراز هویت سریع  تسویه حساب فوری تومانی و ارزی ${APPNAME}   ، پلتفرم معاملاتی ارز های دیجیتال ${location.origin}/register?ref=${referralInfo?.code} 
`,
                )
              }
            />
          </div>
        </Modal>
        <Modal
          className="referral-ads-modal"
          isOpen={isAdvertisingModalOpen}
          setIsOpen={setIsAdvertisingModalOpen}
          closeAble
          title="اشتراک گذاری لینک دعوت"
        >
          <div className={'ad-image'}>
            <img src={ReferralStory} />
          </div>
          {referralInfo && (
            <div className="invitation">
              <span>لینک دعوت</span>
              <a className="en">
                {location.origin}/register?ref={referralInfo?.code}
              </a>
              <CopyIcon
                onClick={() =>
                  copyClipboard(
                    `${location.origin}/register?ref=${referralInfo?.code}`,
                    'لینک دعوت',
                  )
                }
              />
            </div>
          )}
          <div className="buttons">
            <Button
              type="outlined"
              text="کپی کردن لینک دعوت"
              htmlType="button"
              onClick={() =>
                copyClipboard(
                  `${location.origin}/register?ref=${referralInfo?.code}`,
                  'لینک دعوت',
                )
              }
            />
          </div>
        </Modal>
        <Modal
          isOpen={isWithdrawalModalOpen}
          setIsOpen={setIsWithdrawalModalOpen}
          className="referral-withdrawal-modal"
          closeAble
          title={'واریز پاداش به کیف پول'}
        >
          <Card className="referral-withdrawal">
            <span className="input-info-label">
              {
                <>
                  {
                    <>
                      موجودی :
                      <span>{ToIranCurrency(referralInfo?.balance)} تومان</span>
                    </>
                  }
                </>
              }
            </span>
            <Input
              type="text"
              name="amount"
              value={digitSeparator(withdrawalAmount)}
              placeholder="مقدار واریزی را وارد کنید"
              dir={withdrawalAmount === undefined ? 'rtl' : 'ltr'}
              onChange={(e) => onWithdrawalAmountChange(e.target.value)}
              onPressEnter={onConfirmHandler}
            />
            <Button
              type="info"
              text="واریز"
              onClick={onConfirmHandler}
              disabled={
                loading || !(withdrawalAmount && withdrawalAmount != '0')
              }
              loading={loading}
            />
          </Card>
        </Modal>

        <Card
          title="آمار کسب درآمد"
          className="referral-analytics-card"
          divider
          button={
            <div className="info-icon">
              <InfoIcon />
            </div>
          }
        >
          <div className="box info">
            <GiftIcon />
            <GiftIcon className={'mask'} />
            <p>
              <span className="title">مجموع پاداش دریافتی</span>
              <span className="value">
                {digitSperator(referralInfo?.totalBalance.toFixed(0))} تومان
              </span>
            </p>
          </div>
          <div className="box info">
            <SwapIcon />
            <SwapIcon className={'mask'} />
            <p>
              <span className="title">معاملات دوستان</span>
              <span className="value">{referralInfo?.actionCount} تراکنش</span>
            </p>
          </div>
          <div className="box info">
            <FriendsIcon />
            <FriendsIcon className={'mask'} />
            <p>
              <span className="title">دوستان دعوت شده</span>
              <span className="value">{referralInfo?.userCount} نفر</span>
            </p>
          </div>
          <div className="box info">
            <ScanIcon />
            <ScanIcon className={'mask'} />
            <p>
              <span className="title">دوستان احراز شده</span>
              <span className="value">
                {referralInfo?.authenticatedUsersCount} نفر
              </span>
            </p>
          </div>
          <div className="box balance">
            <div className="balance">
              <p>
                <span className="title">موجودی فعلی</span>
                <span className="value">
                  {digitSperator(referralInfo?.balance.toFixed(0))} تومان
                </span>
              </p>
            </div>
            <Button
              type="primary"
              text="واریز پاداش به کیف پول"
              onClick={() => setIsWithdrawalModalOpen(true)}
            />
          </div>
        </Card>

        <Card
          title="دعوت از دوستان"
          className="referral-invite-friends-card"
          divider
          button={
            <div className="info-icon">
              <InfoIcon />
            </div>
          }
        >
          <div className="referral-banner">
            <img src={referralBanner} />
          </div>
          <div className="referral-content">
            <h3>کسب درآمد میلیونی با دعوت دوستان به {APPNAME}</h3>
            <p>
              کد دعوت و یا لینک زیر را برای دوستان خود ارسال کنید و با تایید
              هویت تعداد هر چه بیشتر دوستانتان، به ازای هر معامله ی دوستتان،
              بخضی از کارمزد معامله را به عنوان پاداش دریافت کنید
            </p>
            {referralInfo && (
              <>
                <div className="invitation">
                  <span>کد دعوت</span>
                  <a className="en">{referralInfo?.code}</a>
                  <CopyIcon
                    onClick={() => copyClipboard(referralInfo?.code, 'کد دعوت')}
                  />
                </div>
                <div className="invitation">
                  <span>لینک دعوت</span>
                  <a className="en">
                    {location.origin}/register?ref={referralInfo?.code}
                  </a>
                  <CopyIcon
                    onClick={() =>
                      copyClipboard(
                        `${location.origin}/register?ref=${referralInfo?.code}`,
                        'لینک دعوت',
                      )
                    }
                  />
                </div>
              </>
            )}
            <div className="buttons">
              <Button
                text="اشتراک گذاری"
                type="primary"
                onClick={() => setIsInvitationModalOpen(true)}
              />
              <Button
                text="تصویر تبلیغاتی"
                type="outlined"
                onClick={() => setIsAdvertisingModalOpen(true)}
              />
            </div>
          </div>
        </Card>

        <Card
          title="چطور پاداش دریافت کنم؟"
          className="referral-guide-card"
          divider
          button={
            <div className="info-icon">
              <InfoIcon />
            </div>
          }
        >
          <div className="steps">
            <p className="step">1</p>
            <span />
            <p className="step">2</p>
            <span />
            <p className="step">3</p>
          </div>
          <div className="titles">
            <h4>اشتراک گذاری لینک دعوت</h4>
            <h4>ثبت‌نام در {APPNAME}</h4>
            <h4>دریافت پاداش!</h4>
          </div>
          <div className="descriptions">
            <p>
              لینک دعوت اختصاصی خود را با دوستان و یا در شبکه‌های اجتماعی، به
              اشتراک بگذارید.
            </p>
            <p>
              دوستان با کد دعوت اختصاصی شما ثبت نام، احراز هویت خود را تکمیل و
              شروع به معامله می‌کنند.
            </p>
            <p>
              به ازای هر معامله، تا %۴۵ از کارمزد تراکنش دوستان خود را پاداش
              دریافت می‌کنید.
            </p>
          </div>
        </Card>

        <Card
          title="چطور پاداش دریافت کنم؟"
          className="referral-guide-card-mobile"
          divider
          button={
            <div className="info-icon">
              <InfoIcon />
            </div>
          }
        >
          <div className="steps">
            <div className={'step-container'}>
              <p className="step">1</p>
              <h4>اشتراک گذاری لینک دعوت</h4>
              <p>
                لینک دعوت اختصاصی خود را با دوستان و یا در شبکه‌های اجتماعی، به
                اشتراک بگذارید.
              </p>
            </div>
            <div className={'step-container'}>
              <p className="step">2</p>
              <h4>ثبت‌نام در {APPNAME}</h4>
              <p>
                دوستان با کد دعوت اختصاصی شما ثبت نام، احراز هویت خود را تکمیل و
                شروع به معامله می‌کنند.
              </p>
            </div>
            <div className={'step-container'}>
              <p className="step">3</p>
              <h4>دریافت پاداش!</h4>
              <p>
                به ازای هر معامله، تا %۴۵ از کارمزد تراکنش دوستان خود را پاداش
                دریافت می‌کنید.
              </p>
            </div>
          </div>
        </Card>

        <ReferralActionsHistory />
        <ReferralInvitedUsers />
      </div>
    </>
  );
};
