import { Alert, Button, Card } from 'components';
import * as React from 'react';
import { useState } from 'react';
import './thankyou-irt.scss';
import {
  postViaAuth,
  responseValidator,
} from '../../../../../../utils/scripts/api';
import { API } from '../../../../../../apis';
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { copyToClipboard } from 'utils/scripts/copyToClipboard';
import { TransactionVerify } from '../../../../../../core/domains/transactions/transactionVerify';
import { ToIranCurrency } from '../../../../../../utils/scripts/decimal';
import moment from 'jalali-moment';
import { Failure } from '../../../../orders/failure/failure';
import { Success } from '../../../../orders/success/success';
import { Spin } from 'antd';

export const ThankYouIrt: React.FC = () => {
  const URL = window.location;
  var urlSections = URL.pathname.split('/');
  const trackingCode = urlSections[urlSections.length - 1];

  const [cardIsLoading, setCardIsLoading] = useState<boolean>(true);

  const orderIdRef = React.useRef<HTMLElement>(null);

  const navigate = useNavigate();

  const [verifyResult, setVerifyResult] = useState<boolean | null>(null);

  const [verifyData, setVerifyData] = useState<TransactionVerify | null>(null);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  // Vandar Query parametr payment_status=OK
  //Zibal Query paramer status=1
  const payment =
    query.get('payment_status') === 'OK' || query.get('status') === '1';
  var identifier = query.get('token');
  // PayStar Card
  const cardNumber = query.get('card_number');
  //PayStar trackingCode
  const additionalData = query.get('tracking_code');
  const verifyTransaction = () => {
    setCardIsLoading(true);

    (async () =>
      await postViaAuth<TransactionVerify>(API.deposit.currency.verify, {
        cardNumber: cardNumber,
        trackingCode: trackingCode,
        additionalData: additionalData,
      }).then((response: any) => {
        setCardIsLoading(false);
        if (responseValidator(response.status)) {
          setVerifyResult(true);
          setVerifyData(response.value);
        } else {
          setVerifyResult(false);
        }
      }))();
  };
  React.useEffect(() => {
    verifyTransaction();
  }, []);

  return (
    <div className="xpay-thankyou-dashboard">
      {verifyResult === null ? (
        <Card blur={cardIsLoading}>
          <></>
        </Card>
      ) : verifyResult && verifyData ? (
        <Success verifyData={verifyData} trackingCode={trackingCode} />
      ) : (
        <Failure verifyData={verifyData} trackingCode={trackingCode} />
      )}
    </div>
  );
};
