import './crypto-deposit.scss';
import {
  getViaAuth,
  postViaAuth,
  responseValidator,
} from '../../../../utils/scripts/api';
import { DepositNetwork, Fiat } from '../../../../core/domains';
import { API } from '../../../../apis';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Asset, AssetItem } from '../../../../core/domains/markets/asset';
import { getDepositInfo } from '../../../../core/repositories';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/left-arrow.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/wallet/WarningYellow.svg';
import { digitSperator } from '../../../../utils/scripts/exchange';
import {
  CloseOutlined,
  DownOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Divider } from 'antd';
import { Alert, Button, Card, Input, Modal } from '../../../../components';
import { ReactComponent as BlueChevronIcon } from 'assets/icons/wallet/BlueChevron.svg';
import { ReactComponent as SilverChevronIcon } from 'assets/icons/wallet/SilverChevron.svg';
import { ReactComponent as QRIcon } from 'assets/icons/wallet/QR.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/wallet/Copy2.svg';
import { FAQ } from '../../orders/faq/faq';
import { QRCodeSVG } from 'qrcode.react';
import { copyClipboard } from '../../../../utils/scripts/copyToClipboard';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getIconPath } from '../../../../utils/scripts/getIconPath';
import { faNumToEnNum } from '../../../../utils/scripts/decimal';
import { isMobile } from 'react-device-detect';
import { Side } from '../../orders/create/create-order';
import { Spinner } from '../../../../components/spinner/spinner';
import { ReactComponent as Star } from 'assets/icons/create-order/Star.svg';
import { CommentModal } from './comment-modal/comment-modal';

export const CryptoDeposit = () => {
  const [assets, setAssets] = useState<Asset>();
  const [assetsResult, setAssetsResult] = React.useState<Asset>();
  const [selectedAsset, setSelectedAsset] = useState<AssetItem>();
  const [assetsLoading, setAssetsLoading] = useState<boolean>(false);
  const [assetsModalIsOpen, setAssetsModalIsOpen] = useState<boolean>(false);
  const [networksModalIsOpen, setNetworksModalIsOpen] =
    useState<boolean>(false);
  const [networks, setNetworks] = useState<DepositNetwork[]>();
  const [selectedNetwork, setSelectedNetwork] = useState<DepositNetwork>();
  const [networksLoading, setNetworksLoading] = useState<boolean>(false);
  const [depositAmount, setDepositAmount] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [txId, setTxId] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [qrCodeModalIsOpen, setQrCodeModalIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [trackingCode, setTrackingCode] = useState<string>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalStage, setModalStage] = useState<'qrcode' | 'confirm'>('confirm');
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);

  const location = useLocation();

  const getAssets = async () => {
    await getViaAuth<Asset>(`${API.wallet.assets}`).then((data: any) => {
      setAssetsLoading(true);
      if (responseValidator(data.status) && data.value) {
        setAssets(data.value);
        setAssetsResult(data.value);
        location.pathname.split('/')[4]
          ? setSelectedAsset(
              data.value.assets.find(
                (s: AssetItem) =>
                  s.symbol.toUpperCase() ===
                  location.pathname.split('/')[4].toUpperCase(),
              ),
            )
          : setSelectedAsset(
              data.value.assets.find(
                (s: AssetItem) => s.symbol.toUpperCase() === 'BTC',
              ),
            );
      }
      setAssetsLoading(false);
    });
  };

  const onDepositAmountChange = (value: string) => {
    // Remove commas
    var cleanedValue = faNumToEnNum(value);

    // Limit to one decimal point
    const numericValue = cleanedValue
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');

    // Parse the cleaned value as a float
    setDepositAmount(numericValue);
  };

  const onChangeSlider = (sliderValue: number) => {
    if (sliderValue !== null && selectedAsset) {
      setDepositAmount(
        ((selectedAsset?.balance * sliderValue) / 100).toString(),
      );
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (assets) {
      const filteredAssets = assets.assets.filter(
        (asset) =>
          asset.symbol.toLowerCase().startsWith(value.toLowerCase()) ||
          asset.name.toLowerCase().startsWith(value.toLowerCase()),
      );
      setAssetsResult((prevAssetsResult) => ({
        ...prevAssetsResult!,
        assets: filteredAssets,
      }));
    }
  };

  const handleStep1 = () => {
    setLoading(true);
    postViaAuth(API.deposit.crypto.step1, {
      network: selectedNetwork?.network,
      symbol: selectedAsset?.symbol.toUpperCase(),
      amount: depositAmount,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setTrackingCode(response.value.trackingCode);
        setStep(2);
        if (selectedNetwork?.walletTag) setCommentModalOpen(true);
      }
    });
  };

  const handleStep2 = () => {
    setModalStage('confirm');
    setModalIsOpen(true);
  };

  const confirmTransaction = () => {
    setLoading(true);
    if (
      selectedAsset?.symbol.toUpperCase() === 'TRX' ||
      selectedAsset?.symbol.toUpperCase() === 'USDT'
    ) {
      toast.success('تراکنش شما با موفقیت ثبت شد');
      setModalIsOpen(false);
      setStep(1);
      setTxId(undefined);
      setDepositAmount('');
      setLoading(false);
    } else {
      postViaAuth(API.deposit.crypto.step2, {
        txId: txId,
        trackingCode: trackingCode,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'deposit',
            amount: depositAmount,
          });
          toast.success('تراکنش شما با موفقیت ثبت شد');
          setModalIsOpen(false);
          setStep(1);
          setTxId(undefined);
          setDepositAmount('');
        } else {
          setModalIsOpen(false);
        }
      });
    }
  };

  const onSubmit = () => {
    if (step === 1) {
      handleStep1();
    } else if (step === 2) {
      handleStep2();
    }
  };

  useEffect(() => {
    !assets && getAssets();
  }, []);

  useEffect(() => {
    if (selectedAsset) {
      setStep(1);
      setTxId(undefined);
      setDepositAmount('');
      setNetworks(undefined);
      setSelectedNetwork(undefined);
      getDepositInfo(setNetworksLoading, selectedAsset.symbol, setNetworks);
    }
  }, [selectedAsset]);

  useEffect(() => {
    networks && networks.length > 0 && setSelectedNetwork(networks[0]);
  }, [networks]);

  return (
    <div className={'xpay-crypto-deposit'}>
      <CommentModal isOpen={commentModalOpen} setIsOpen={setCommentModalOpen} />
      <Modal
        className="confirm-deposit-crypto-modal"
        isOpen={modalIsOpen}
        closeAble
        description={
          modalStage === 'confirm'
            ? 'آیا اطلاعات زیر را جهت واریز تایید می کنید؟'
            : 'بارکد زیر را جهت دریافت آدرس والت اسکن کنید.'
        }
        title={
          modalStage === 'confirm' ? 'تایید اطلاعات واریز' : 'آدرس والت واریزی'
        }
        setIsOpen={setModalIsOpen}
      >
        {modalStage === 'confirm' ? (
          <div className="confirm-box">
            <div className="content">
              <ul>
                <li>
                  <span>ارز واریزی</span>
                  <span>{selectedAsset?.symbol.toUpperCase()}</span>
                </li>
                <li>
                  <span>مقدار واریزی</span>
                  <span>{digitSperator(depositAmount)}</span>
                </li>
                <li>
                  <span>شبکه انتقال</span>
                  <span>{selectedNetwork?.name}</span>
                </li>
                <li>
                  <span>آدرس واریزی</span>
                  <span>{selectedNetwork?.walletAddress}</span>
                </li>
                {txId && (
                  <li>
                    <span>شناسه واریز</span>
                    <span>{txId}</span>
                  </li>
                )}
              </ul>
            </div>
            <div className="buttons">
              <Button
                type="info"
                text="بله"
                loading={loading}
                disabled={loading}
                htmlType="button"
                onClick={() => confirmTransaction()}
              />
              <Button
                type="outlined"
                text="خیر"
                onClick={() => setModalIsOpen(false)}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="address-wallet-info">
              <img
                src={`data:image/png;base64,${selectedNetwork?.qrCodeUrl}`}
                alt=""
              />
            </div>
          </>
        )}
      </Modal>
      <Modal
        isOpen={networksModalIsOpen}
        setIsOpen={setNetworksModalIsOpen}
        className={'xpay-networks-modal'}
        closeAble
      >
        <Card title={'شبکه مورد نظر را انتخاب کنید:'} divider>
          <Alert
            type="warning"
            title="هشدار!"
            message="مطمئن شوید که شبکه انتخابی با شبکه وارد شده شما مطابقت دارد. آدرس، یا ممکن است تراکنش پرداختی شما گم شود. شبکه هایی که مطابقت ندارند فیلتر شده اند. یک شبکه را از لیست انتخاب کنید."
          />
          <ul>
            {networksLoading ? (
              <LoadingOutlined />
            ) : (
              networks?.map((item, index) => (
                <>
                  <li
                    onClick={() => {
                      setSelectedNetwork(item);
                      setNetworksModalIsOpen(false);
                    }}
                    key={index}
                  >
                    <span className={'en'}>{item.name}</span>
                    {/*<div className={'fee'}>*/}
                    {/*  <p>کارمزد شبکه</p>*/}
                    {/*  <span>{selectedNetwork?.widthrawFee} واحد</span>*/}
                    {/*</div>*/}
                    <LeftArrowIcon />
                  </li>
                  {index !== networks?.length - 1 && <Divider />}
                </>
              ))
            )}
          </ul>
        </Card>
      </Modal>
      <Modal
        isOpen={assetsModalIsOpen}
        setIsOpen={setAssetsModalIsOpen}
        className={'xpay-assets-modal'}
        closeAble
      >
        <Card title={'ارز مورد نظر را انتخاب کنید:'} divider>
          <Input
            name={'assets-search'}
            type={'text'}
            value={searchValue}
            placeholder={'جست و جوی رمزارز...'}
            onChange={(e) => handleSearch(e.target.value)}
            adorments={{
              endAdornment: {
                adornmentIcon: (
                  <CloseOutlined
                    onClick={() => setSearchValue('')}
                    style={{ cursor: 'pointer' }}
                  />
                ),
              },
            }}
          />
          <h4>لیست دارایی ها</h4>
          <div className="favorite-currencies">
            <h4>
              <Star />
              رمز ارز های پرطرفدار
            </h4>
            <Spinner loading={assetsLoading}>
              <div className="currencies-box">
                {assets?.assets
                  .filter(
                    (currency) =>
                      currency.symbol.toUpperCase() === 'TRX' ||
                      currency.symbol.toUpperCase() === 'USDT',
                  )
                  .map((favCurrency, index) => (
                    <div
                      className="currency-item"
                      onClick={() => {
                        setSelectedAsset(favCurrency);
                        setAssetsModalIsOpen(false);
                      }}
                      key={index}
                    >
                      <img
                        alt="asset"
                        width={32}
                        height={32}
                        src={getIconPath(favCurrency.symbol)}
                      />
                      <div className="col">
                        <p>{favCurrency.name}</p>
                        <span className="en">{favCurrency.symbol}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </Spinner>
          </div>
          <ul>
            {assetsLoading ? (
              <LoadingOutlined />
            ) : (
              assetsResult?.assets
                ?.filter(
                  (item) =>
                    item.symbol.toUpperCase() !== 'IRT' &&
                    item.symbol.toUpperCase() !== 'PM' &&
                    item.symbol.toUpperCase() !== 'PM-U' &&
                    item.symbol.toUpperCase() !== 'TRX' &&
                    item.symbol.toUpperCase() !== 'USDT',
                )
                .map((item, index) => (
                  <>
                    <li
                      onClick={() => {
                        setSelectedAsset(item);
                        setAssetsModalIsOpen(false);
                      }}
                      key={index}
                    >
                      <div className={'currency-title'}>
                        <img
                          src={getIconPath(item.symbol)}
                          alt=""
                          width={32}
                          height={32}
                        />
                        <span>{item.name}</span>
                        <span className={'en'}>
                          {item.symbol.toUpperCase()}
                        </span>
                      </div>
                      <div className={'currency-balance'}>
                        <span>موجودی:</span>
                        <span>{digitSperator(item.balance)} واحد</span>
                      </div>
                    </li>
                    {index !== assetsResult.assets.length - 1 && <Divider />}
                  </>
                ))
            )}
          </ul>
        </Card>
      </Modal>
      <Modal
        isOpen={qrCodeModalIsOpen}
        setIsOpen={setQrCodeModalIsOpen}
        className={'xpay-qr-modal'}
        closeAble
      >
        {selectedNetwork ? (
          <QRCodeSVG value={selectedNetwork?.qrCodeUrl} />
        ) : (
          <LoadingOutlined />
        )}
      </Modal>
      <div className={'deposit-box'}>
        {selectedNetwork?.minDeposit && (
          <>
            <div className={'financial-info'}>
              <div className={'info'}>
                <p>حداقل میزان واریز:</p>
                <span>
                  {!networksLoading && selectedNetwork ? (
                    selectedNetwork.minDeposit
                  ) : (
                    <LoadingOutlined />
                  )}{' '}
                  {selectedAsset?.name}
                </span>
              </div>
            </div>
            <Divider />
          </>
        )}
        <div className={'mobile-labels'}>
          <label>مقدار واریزی ↓</label>
          <label> ↓ انتخاب ارز</label>
        </div>
        <Input
          value={digitSperator(depositAmount)}
          placeholder="تعداد واحد مورد نظر برای واریز"
          type="text"
          dir={depositAmount === '' ? 'rtl' : 'ltr'}
          name="depositAmount"
          onChange={(e) => onDepositAmountChange(e.target.value)}
          adorments={{
            endAdornment: {
              adornmentIcon: (
                <div className={'adornments'}>
                  <div
                    className={'currency-container'}
                    onClick={() =>
                      selectedAsset ? setAssetsModalIsOpen(true) : null
                    }
                  >
                    {selectedAsset ? (
                      <>
                        <DownOutlined />
                        <span className={'en'}>{selectedAsset.symbol}</span>
                        <img
                          src={getIconPath(selectedAsset.symbol.toLowerCase())}
                          alt=""
                          width={32}
                          height={32}
                        />
                      </>
                    ) : (
                      <LoadingOutlined />
                    )}
                  </div>
                </div>
              ),
            },
          }}
        />
        <div
          className={'choose-network'}
          onClick={() => setNetworksModalIsOpen(true)}
        >
          {selectedNetwork ? (
            <>
              <div className={'chosen-network'}>
                <span className={'en'}>{selectedNetwork.name}</span>
              </div>
              {networksLoading ? <LoadingOutlined /> : <SilverChevronIcon />}
            </>
          ) : (
            <>
              <p>شبکه انتقال ارز واریزی را انتخاب کنید</p>
              {networksLoading ? <LoadingOutlined /> : <BlueChevronIcon />}
            </>
          )}
        </div>
        {step === 2 && (
          <>
            <Input
              className={'wallet-address'}
              value={selectedNetwork?.walletAddress}
              label={'آدرس کیف پول:'}
              type="text"
              dir={selectedNetwork?.walletAddress === undefined ? 'rtl' : 'ltr'}
              name="walletAddress"
              onChange={() => null}
              disabled
              adorments={{
                startAdornment: {
                  adornmentIcon: (
                    <span className={'start-adornment'}>آدرس کیف پول:</span>
                  ),
                },
                endAdornment: {
                  adornmentIcon: (
                    <div className={'end-adornment'}>
                      <Button
                        type={'card'}
                        text={'اسکن'}
                        icon={<QRIcon />}
                        onClick={() => setQrCodeModalIsOpen(true)}
                      />
                      <Button
                        type={'card'}
                        text={'کپی'}
                        icon={<CopyIcon />}
                        onClick={() =>
                          selectedNetwork &&
                          copyClipboard(
                            selectedNetwork?.walletAddress,
                            'آدرس کیف پول',
                          )
                        }
                      />
                    </div>
                  ),
                },
              }}
            />
            {selectedNetwork?.walletTag && (
              <Input
                className={'wallet-address'}
                value={selectedNetwork?.walletTag}
                label={'آدرس تگ / ممو / کامنت:'}
                type="text"
                dir={selectedNetwork?.walletTag === undefined ? 'rtl' : 'ltr'}
                name="memo"
                onChange={() => null}
                disabled
                adorments={{
                  startAdornment: {
                    adornmentIcon: (
                      <span className={'start-adornment'}>
                        آدرس تگ / ممو / کامنت:
                      </span>
                    ),
                  },
                  endAdornment: {
                    adornmentIcon: (
                      <div className={'end-adornment'}>
                        <Button
                          type={'card'}
                          text={'کپی'}
                          icon={<CopyIcon />}
                          onClick={() =>
                            selectedNetwork &&
                            copyClipboard(
                              selectedNetwork?.walletTag,
                              'تگ / ممو / کامنت',
                            )
                          }
                        />
                      </div>
                    ),
                  },
                }}
              />
            )}
            {selectedAsset?.symbol.toUpperCase() === 'TRX' ||
              (selectedAsset?.symbol.toUpperCase() === 'USDT' && (
                <div className={'deposit-warning'}>
                  <span>
                    <WarningIcon /> توجه:
                  </span>
                  <span>
                    این آدرس اختصاصی برای شما می‌باشد و هرگونه واریزی به آن،
                    بدون نیاز به ارائه هش، به حساب شما منظور خواهد شد.{' '}
                  </span>
                </div>
              ))}

            {selectedAsset?.symbol.toUpperCase() !== 'USDT' &&
              selectedAsset?.symbol.toUpperCase() !== 'TRX' && (
                <Input
                  className={'txId-input'}
                  value={txId}
                  label={'هش تراکنش:'}
                  type="text"
                  dir={txId === undefined ? 'rtl' : 'ltr'}
                  name="txId"
                  placeholder={
                    isMobile
                      ? 'لینک تراکنش را پس از واریز از این قسمت وارد کنید...'
                      : ''
                  }
                  onChange={(e) => setTxId(e.target.value)}
                  adorments={{
                    startAdornment: {
                      adornmentIcon: (
                        <span className={'start-adornment'}>
                          لینک تراکنش (TXID) را پس از واریز از این قسمت وارد
                          کنید:
                        </span>
                      ),
                    },
                  }}
                />
              )}
          </>
        )}
        {!(selectedAsset?.symbol.toUpperCase() === 'TRX' && step === 2) &&
          !(selectedAsset?.symbol.toUpperCase() === 'USDT' && step === 2) && (
            <Button
              type={'primary'}
              text={`${step === 1 ? 'بعدی' : 'ثبت اطلاعات واریز'}`}
              disabled={
                loading ||
                Number(depositAmount) === 0 ||
                !depositAmount ||
                !selectedAsset ||
                !selectedNetwork ||
                (step === 2 &&
                  !(
                    selectedAsset?.symbol.toUpperCase() === 'TRX' ||
                    selectedAsset?.symbol.toUpperCase() === 'USDT'
                  ) &&
                  !txId)
              }
              onClick={onSubmit}
              loading={loading}
            />
          )}
      </div>
      {/*<FAQ />*/}
    </div>
  );
};
