import './trade-box.scss';
import { ReactComponent as TabArrowUp } from 'assets/icons/create-order/TabArrowUp.svg';
import { ReactComponent as TabArrowDown } from 'assets/icons/create-order/TabArrowDown.svg';
import { ReactComponent as ThreeDots } from 'assets/icons/create-order/ThreeDots.svg';
import { Divider, Slider } from 'antd';
import { useEffect, useState } from 'react';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'components';
import { SearchBox } from '../search-box/search-box';
import { OrderCommonProps, Side } from '../create/create-order';
import { digitSperator } from '../../../../utils/scripts/exchange';
import { getViaAuth, responseValidator } from '../../../../utils/scripts/api';
import { API } from '../../../../apis';
import { BuyPerfectMoneyModal } from '../create/buy-perfect-money-modal/buy-perfect-money-modal';
import { PlaceOrderDetailsModal } from '../create/place-order-details-modal/place-order-details-modal';
import { SellPerfectMoneyModal } from '../create/sell-perfect-money-modal/sell-perfect-money-modal';
import {
  faNumToEnNum,
  ToIranCurrency,
} from '../../../../utils/scripts/decimal';
import { getIconPath } from '../../../../utils/scripts/getIconPath';
import { Asset } from '../../../../core/domains/markets/asset';
import { getMineAssets } from '../../../../core/repositories/wallet/wallet';

export const TradeBox: React.FC<OrderCommonProps> = (props) => {
  const [searchBoxOpen, setSearchBoxOpen] = useState<boolean>(false);
  const [isBuyPerfectMoneyModalOpen, setIsBuyPerfectMoneyModalOpen] =
    useState<boolean>(false);
  const [isSellPerfectMoneyModalOpen, setIsSellPerfectMoneyModalOpen] =
    useState<boolean>(false);
  const [isPlaceOrderModalOpen, setIsPlaceOrderModalOpen] =
    useState<boolean>(false);
  const [sourceAmount, setSourceAmount] = useState<string>('');
  const [destinationAmount, setDestinationAmount] = useState<string>('');
  const [fetchedPrice, setFetchedPrice] = useState<number>();
  const [priceLoading, setPriceLoading] = useState<boolean>(false);
  const [voucher, setVoucher] = useState({ code: '', activation: '' });
  const [assets, setAssets] = useState<Asset>(props.assets);

  const handleButtonClick = () => {
    if (props.destinationMarket.symbol.toUpperCase() === 'PM') {
      setIsBuyPerfectMoneyModalOpen(true);
    } else if (props.sourceMarket.symbol.toUpperCase() === 'PM') {
      setIsSellPerfectMoneyModalOpen(true);
    } else {
      setIsPlaceOrderModalOpen(true);
    }
  };

  const handleModalOnComplete = () => {
    setSourceAmount('');
    setDestinationAmount('');
    getMineAssets(props.setAssetsLoading, setAssets, { amountSort: 2 }, '');
  };

  useEffect(() => {
    handleExchange();
  }, [props.sourceMarket, props.destinationMarket]);

  useEffect(() => {
    setAssets(props.assets);
  }, [props.assets]);

  const onBuySideClick = () => {
    if (props.destinationMarket.symbol.toUpperCase() === 'IRT') {
      props.setSide(Side.Buy);
      props.setDestinationMarket(props.sourceMarket);
      setDestinationAmount(sourceAmount);
      props.setSourceMarket({
        id: '32cb59f0-941c-ed11-b851-9d6f4adb3d5c',
        name: 'تومان',
        symbol: 'IRT',
        isSellActive: true,
        isBuyActive: true,
        type: { id: 2, title: 'پرفکت مانی' },
        iconUrl: '/coins/IRT.png',
      });
      setSourceAmount(destinationAmount);
    }
  };

  const onSellSideClick = () => {
    if (props.sourceMarket.symbol.toUpperCase() === 'IRT') {
      props.setSide(Side.Sell);
      props.setSourceMarket(props.destinationMarket);
      setSourceAmount(destinationAmount);
      props.setDestinationMarket({
        id: '32cb59f0-941c-ed11-b851-9d6f4adb3d5c',
        name: 'تومان',
        symbol: 'IRT',
        isSellActive: true,
        isBuyActive: true,
        type: { id: 2, title: 'پرفکت مانی' },
        iconUrl: '/coins/IRT.png',
      });
      setDestinationAmount(sourceAmount);
    }
  };

  const onChangeSlider = (sliderValue: number) => {
    if (sliderValue !== null) {
      const asset = assets?.assets?.find(
        (asset) =>
          asset.symbol.toUpperCase() ===
          props.sourceMarket.symbol.toUpperCase(),
      );
      if (asset) {
        const amount = asset.balance * (sliderValue / 100);
        const amountStr = amount.toString();

        if (props.side === Side.Buy) {
          setSourceAmount(amountStr);
          setDestinationAmount((amount / (fetchedPrice || 1)).toString());
        } else {
          setSourceAmount(amountStr);
          setDestinationAmount((amount * (fetchedPrice || 0)).toString());
        }
      }
    }
  };

  const handleExchange = () => {
    setPriceLoading(true);
    getViaAuth(
      `${API.markets.exchange}/${props.side === Side.Buy ? 2 : 1}/1/${props.sourceMarket.symbol.toUpperCase()}/${props.destinationMarket.symbol.toUpperCase()}/true`,
    ).then((response: any) => {
      setPriceLoading(false);
      if (responseValidator(response.status)) {
        setFetchedPrice(
          props.side === Side.Buy
            ? response.value.sourceAmount
            : response.value.destinationAmount,
        );
      }
    });
  };

  const handleSourceAmountChange = (value: string) => {
    const convertedValue = faNumToEnNum(value);

    const numericValue = convertedValue
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');

    setSourceAmount(numericValue);

    if (!isNaN(parseFloat(numericValue)) && numericValue !== '') {
      const calculatedValue =
        props.side === Side.Buy
          ? parseFloat(numericValue) / (fetchedPrice || 1)
          : parseFloat(numericValue) * (fetchedPrice || 0);
      setDestinationAmount(calculatedValue.toString());
    } else {
      setDestinationAmount('0');
    }
  };

  const handleDestinationAmountChange = (value: string) => {
    const convertedValue = faNumToEnNum(value);

    const numericValue = convertedValue
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');

    setDestinationAmount(numericValue);

    if (!isNaN(parseFloat(numericValue)) && numericValue !== '') {
      const calculatedValue =
        props.side === Side.Buy
          ? parseFloat(numericValue) * (fetchedPrice || 0)
          : parseFloat(numericValue) / (fetchedPrice || 1);
      setSourceAmount(calculatedValue.toString());
    } else {
      setSourceAmount('0');
    }
  };

  return (
    <div className="xpay-trade-box">
      <Modal
        className="search-box-modal"
        isOpen={searchBoxOpen}
        setIsOpen={setSearchBoxOpen}
        closeAble
        title={'انتخاب رمزارز'}
      >
        <SearchBox
          side={props.side}
          setSide={props.setSide}
          markets={props.markets}
          fiats={props.fiats}
          assets={assets}
          commissions={props.commissions}
          sourceMarket={props.sourceMarket}
          destinationMarket={props.destinationMarket}
          setSourceMarket={props.setSourceMarket}
          setDestinationMarket={props.setDestinationMarket}
          marketsLoading={props.marketsLoading}
          fiatsLoading={props.fiatsLoading}
          assetsLoading={props.assetsLoading}
          commissionsLoading={props.commissionsLoading}
          setMarketsLoading={props.setMarketsLoading}
          setFiatsLoading={props.setFiatsLoading}
          setAssetsLoading={props.setAssetsLoading}
          setCommissionsLoading={props.setCommissionsLoading}
          onCurrencySelect={() => setSearchBoxOpen(false)}
        />
      </Modal>
      <BuyPerfectMoneyModal
        assets={assets}
        sourceAmount={sourceAmount}
        destinationAmount={destinationAmount}
        isOpen={isBuyPerfectMoneyModalOpen}
        setIsOpen={setIsBuyPerfectMoneyModalOpen}
        side={props.side}
        onComplete={handleModalOnComplete}
      />
      <SellPerfectMoneyModal
        isOpen={isSellPerfectMoneyModalOpen}
        setIsOpen={setIsSellPerfectMoneyModalOpen}
        sourceAmount={sourceAmount}
        destinationAmount={destinationAmount}
        side={props.side}
        voucher={voucher.code}
        voucherActivation={voucher.activation}
        onComplete={handleModalOnComplete}
      />
      <PlaceOrderDetailsModal
        isOpen={isPlaceOrderModalOpen}
        setIsOpen={setIsPlaceOrderModalOpen}
        side={props.side}
        sourceAmount={sourceAmount}
        destinationAmount={destinationAmount}
        baseSymbol={
          props.side === Side.Buy ? props.destinationMarket : props.sourceMarket
        }
        quoteSymbol={'IRT'}
        commission={
          props.side === Side.Buy
            ? props.commissions[0]?.commission
            : props.commissions[1]?.commission
        }
        onComplete={handleModalOnComplete}
      />
      <div className="order-tabs">
        <div
          className={`tab ${props.side === Side.Buy ? 'active' : ''}`}
          onClick={onBuySideClick}
        >
          <TabArrowUp /> خرید از ما
        </div>
        <div
          className={`tab ${props.side === Side.Sell ? 'active' : ''}`}
          onClick={onSellSideClick}
        >
          <TabArrowDown /> فروش به ما
        </div>
      </div>
      <Divider className="order-box-divider" />
      <div className="content">
        <div className="input-label">
          <span>{props.side === Side.Buy ? 'پرداخت میکنم' : 'می فروشم'}</span>
          <div className="slider-label">
            {props.assetsLoading || !assets ? (
              <LoadingOutlined />
            ) : props.sourceMarket.symbol.toUpperCase() === 'IRT' ? (
              assets?.assets.find(
                (asset) => asset.symbol === props.sourceMarket?.symbol,
              )?.balance === 0 ? (
                <Button
                  className={'deposit-irt-button'}
                  type={'outlined'}
                  text={'واریز تومان'}
                  url={'/dashboard/wallet/deposit/IRT'}
                />
              ) : (
                <>
                  <span>موجودی در دسترس:&nbsp;&nbsp;&nbsp;</span>
                  <span>
                    {digitSperator(
                      assets?.assets
                        .find(
                          (asset) =>
                            asset.symbol === props.sourceMarket?.symbol,
                        )
                        ?.balance.toFixed(0),
                    ) + ' تومان'}
                  </span>
                </>
              )
            ) : (
              assets?.assets?.find(
                (asset) => asset.symbol === props.sourceMarket?.symbol,
              )?.balance + ' واحد'
            )}
          </div>
        </div>
        <div className="field">
          <input
            type={'text'}
            placeholder={
              props.sourceMarket.symbol.toUpperCase() === 'PM'
                ? 'لطفاً کد ووچر و کد فعالساز ووچر را در کادر های پایین وارد نمایید...'
                : props.side === Side.Buy
                  ? '۱۵۰،۰۰۰ تا ۱۰۰،۰۰۰،۰۰۰ تومان'
                  : 'تعداد مورد نیاز بر اساس واحد ارز...'
            }
            value={
              props.sourceMarket?.symbol === 'IRT'
                ? ToIranCurrency(sourceAmount ? sourceAmount : '')
                : digitSperator(sourceAmount)
                  ? digitSperator(sourceAmount)
                  : ''
            }
            onChange={(e) => handleSourceAmountChange(e.target.value)}
            disabled={props.sourceMarket?.symbol.toUpperCase() === 'PM'}
          />
          {props.side === Side.Buy && (
            <div className="currency">
              <span className="en">{props.sourceMarket?.symbol}</span>
              <img
                width={32}
                height={32}
                src={getIconPath(props.sourceMarket?.symbol)}
              />
            </div>
          )}
          {props.side === Side.Sell && (
            <div
              className="currency-select"
              onClick={(e) => {
                setSearchBoxOpen(true);
              }}
            >
              <DownOutlined />
              <span className="en">{props.sourceMarket?.symbol}</span>
              <img
                width={32}
                height={32}
                src={getIconPath(props.sourceMarket?.symbol)}
              />
            </div>
          )}
        </div>
        {props.side === Side.Sell && (
          <div className="field-sublabel">
            <p>
              <ThreeDots /> قیمت در بازار
            </p>
            {priceLoading ? (
              <LoadingOutlined />
            ) : (
              <span>{digitSperator(fetchedPrice)} تومان</span>
            )}
          </div>
        )}
        {props.sourceMarket.symbol.toUpperCase() !== 'PM' && (
          <>
            <Slider
              marks={{
                0: 'حداقل',
                25: '25٪',
                50: '50٪',
                75: '75٪',
                100: '100٪',
              }}
              step={1}
              tooltip={{
                placement: 'bottom',
                formatter: (value) => {
                  return `${value}٪`;
                },
              }}
              trackStyle={{ background: '#3F62FE' }}
              onChange={(sliderValue) => onChangeSlider(sliderValue)}
            />
          </>
        )}
        {props.sourceMarket.symbol.toUpperCase() === 'PM' && (
          <>
            <div className="input-label" style={{ marginTop: '24px' }}>
              <span>
                کد ووچر <span className={'en'}>(E-Voucher)</span>
              </span>
              <p></p>
            </div>
            <div className="field">
              <input
                type={'text'}
                placeholder={'کد ووچر خود را وارد کنید...'}
                value={voucher.code}
                onChange={(e) =>
                  setVoucher({ ...voucher, code: e.target.value })
                }
              />
            </div>

            <div className="input-label" style={{ marginTop: '24px' }}>
              <span>
                فعال ساز ووچر <span className={'en'}>(Activation Code)</span>
              </span>
              <p></p>
            </div>
            <div className="field" style={{ marginBottom: '24px' }}>
              <input
                type={'text'}
                placeholder={'فعال ساز ووچر را وارد کنید...'}
                value={voucher.activation}
                onChange={(e) =>
                  setVoucher({ ...voucher, activation: e.target.value })
                }
              />
            </div>
          </>
        )}
        {props.sourceMarket.symbol.toUpperCase() !== 'PM' && (
          <>
            <div className="input-label">
              <span>دریافت میکنم</span>
              <p></p>
            </div>
            <div className="field">
              <input
                type={'text'}
                placeholder={
                  props.side === Side.Sell
                    ? '۱۵۰،۰۰۰ تا ۱۰۰،۰۰۰،۰۰۰ تومان'
                    : 'تعداد مورد نیاز را بر اساس واحد ارز...'
                }
                value={
                  props.destinationMarket?.symbol === 'IRT'
                    ? ToIranCurrency(destinationAmount ? destinationAmount : '')
                    : digitSperator(destinationAmount)
                      ? digitSperator(destinationAmount)
                      : ''
                }
                onChange={(e) => handleDestinationAmountChange(e.target.value)}
              />
              <div>
                {props.side === Side.Buy && (
                  <div
                    className="currency-select"
                    onClick={(e) => {
                      setSearchBoxOpen(true);
                    }}
                  >
                    <DownOutlined />
                    <span className="en">
                      {props.destinationMarket?.symbol}
                    </span>
                    <img
                      width={32}
                      height={32}
                      src={getIconPath(props.destinationMarket?.symbol)}
                    />
                  </div>
                )}
                {props.side === Side.Sell && (
                  <div className="currency">
                    <span className="en">
                      {props.destinationMarket?.symbol}
                    </span>
                    <img
                      width={32}
                      height={32}
                      src={getIconPath(props.destinationMarket?.symbol)}
                    />
                  </div>
                )}
              </div>
            </div>
            {props.side === Side.Buy && (
              <div className="field-sublabel">
                <p>
                  <ThreeDots /> قیمت در بازار
                </p>
                <span>
                  {priceLoading ? (
                    <LoadingOutlined />
                  ) : (
                    digitSperator(fetchedPrice)
                  )}{' '}
                  تومان
                </span>
              </div>
            )}
            <div className="button-label">
              <span>{'کارمزد معامله: '}</span>
              <span>
                {props?.commissionsLoading || !props.commissions ? (
                  <LoadingOutlined />
                ) : (
                  digitSperator(
                    props?.commissions?.find((commission) =>
                      props.side === Side.Buy
                        ? commission.orderSide === 1
                        : commission.orderSide === 2,
                    )?.commission,
                  ) + ' تومان'
                )}
              </span>
            </div>
          </>
        )}
        <Button
          onClick={handleButtonClick}
          type="primary"
          disabled={
            (props.side === Side.Sell &&
              props.sourceMarket.symbol.toUpperCase() !== 'PM' &&
              !sourceAmount) ||
            !destinationAmount ||
            priceLoading
          }
          text={`${props.side === Side.Buy ? 'خرید' : 'فروش'} ${props.side === Side.Buy ? props.destinationMarket?.name : props.sourceMarket?.name}`}
        />
      </div>
    </div>
  );
};
