import * as React from 'react';
import './bank-account.scss';
import { Button, Card, Modal } from 'components';
import { ReactComponent as Plus } from 'assets/icons/bank-accounts/Plus.svg';
import { ReactComponent as Trash } from 'assets/icons/bank-accounts/Trash.svg';
import { Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { deleteBankAccount, getMineBankAccount } from 'core/repositories';
import { BankAccountResponse } from 'core/domains';

export const BankAccount: React.FC = () => {
  const { useState, useEffect } = React;
  const navigate = useNavigate();
  const [bankAccounts, setBankAccounts] = useState<BankAccountResponse[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<BankAccountResponse>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [deleteButtonLoading, setDeleteButtonLoading] =
    useState<boolean>(false);

  const handleDeleteBankAccount = async (item: BankAccountResponse) => {
    setSelectedCard(item);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    deleteBankAccount(setDeleteButtonLoading, selectedCard?.id, () => {
      setIsDeleteModalOpen(false);
      getMineBankAccount(setLoading, setBankAccounts);
    });
  };

  useEffect(() => {
    getMineBankAccount(setLoading, setBankAccounts);
  }, []);

  return (
    <Card
      className="xpay-bank-accounts"
      title="حساب های بانکی"
      divider
      blur={loading}
    >
      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        className="xpay-delete-bank-account-modal"
      >
        <h4>آیا از حذف کارت بانکی خود اطمینان دارید؟</h4>
        <div className="buttons">
          <Button
            type="primary"
            text="حذف"
            loading={deleteButtonLoading}
            onClick={handleDeleteConfirm}
          />
          <Button
            type="outlined"
            text="انصراف"
            onClick={() => {
              setDeleteButtonLoading(false);
              setIsDeleteModalOpen(false);
            }}
          />
        </div>
      </Modal>
      <div className="bank-accounts">
        <div className="head">
          <span>کارت های بانکی</span>
          <Button
            type="primary"
            text="افزودن"
            icon={<Plus />}
            onClick={() => navigate('add-card')}
          />
        </div>
        <Divider className="horizontal-divider" />
        <div className="item-list">
          {bankAccounts?.map((item) => (
            <>
              <div className="item-row">
                <img src={`/banks/${item.bank.code.toLowerCase()}.svg`} />
                <span dir="ltr">{item.cardNumber}</span>
                <Trash onClick={() => handleDeleteBankAccount(item)} />
              </div>
              <Divider />
            </>
          ))}
        </div>
      </div>
      <Divider className="vertical-divider" type="vertical" />
      <div className="bank-numbers">
        <div className="head">
          <span>شماره های شبا</span>
          <Button
            type="primary"
            text="افزودن"
            icon={<Plus />}
            onClick={() => navigate('add-iban')}
          />
        </div>
        <Divider className="horizontal-divider" />
        <div className="item-list">
          {bankAccounts?.map((item) => (
            <>
              <div className="item-row">
                <img src={`/banks/${item.bank.code.toLowerCase()}.svg`} />
                <span>{item.iBan}</span>
                <Trash
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDeleteBankAccount(item)}
                />
              </div>
              <Divider />
            </>
          ))}
        </div>
      </div>
    </Card>
  );
};
