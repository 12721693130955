import React, { FC, Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer } from 'antd';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { SidebarItems } from '../../utils/constants/common';
import { NavigationItem } from './navigation-item/navigation-item';
import { Modal } from 'components/modal/modal';
import { Button } from 'components/button/button';
import './navigation.scss';
import quitIcon from '../../assets/icons/quit.svg';
import { APP_VERSION } from '../../apis/constants';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setActiveItemId: Dispatch<SetStateAction<number | undefined>>;
}

export const NavigationPage: FC<Props> = ({
  isOpen,
  setIsOpen,
  setActiveItemId,
}) => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <Drawer
      title="منو"
      placement="right"
      closable={true}
      onClose={() => {
        setActiveItemId(undefined);
        setIsOpen(false);
      }}
      open={isOpen}
      className="xpay-navigation"
      headerStyle={{ padding: '24px', direction: 'ltr' }}
      bodyStyle={{ padding: 0 }}
      width="75%"
      closeIcon={<CloseOutlined />}
    >
      <Modal
        className="logout-modal"
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <div className="confirm-box">
          <div className="head">
            <h4>آیا می‌خواهید خارج شوید؟</h4>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              onClick={() => {
                setModalIsOpen(false);
                navigate('/login');
              }}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>
      {SidebarItems.map((item, index) => (
        <NavigationItem
          id={item?.id}
          title={item?.title}
          icon={item?.icon!}
          link={item?.link}
          childrens={item.childrens}
        />
      ))}
      <div
        className="xpay-navigation-item-child logout"
        onClick={() => setModalIsOpen(true)}
      >
        <p className={'title'}>
          <img src={quitIcon} alt="quit" />
          خروج
        </p>
      </div>
      <div className={'version-container'}>نسخه {APP_VERSION}</div>
    </Drawer>
  );
};
