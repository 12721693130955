import './irt-deposit.scss';
import { Collapse, CollapseProps, Divider } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, Input, Modal } from '../../../../components';
import { ReactComponent as BlueChevronIcon } from 'assets/icons/wallet/BlueChevron.svg';
import { ReactComponent as SilverChevronIcon } from 'assets/icons/wallet/SilverChevron.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/wallet/Warning.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/wallet/Info2.svg';
import { ReactComponent as BackIcon } from 'assets/icons/wallet/Back.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/wallet/Copy.svg';
import { BankAccountResponse, Cart } from '../../../../core/domains';
import {
  getMineBalanceByPair,
  getMineBankAccount,
} from '../../../../core/repositories';
import {
  deleteCommaSperator,
  digitSeparator,
  digitSperator,
} from '../../../../utils/scripts/exchange';
import {
  getViaAuth,
  postViaAuth,
  responseValidator,
} from '../../../../utils/scripts/api';
import { API } from '../../../../apis';
import { AmountPolicy } from '../../../../core/domains/sitesettings/sitesetting';
import { AssetItem } from '../../../../core/domains/markets/asset';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { copyClipboard } from '../../../../utils/scripts/copyToClipboard';
import { toast } from 'react-toastify';
import { faNumToEnNum } from '../../../../utils/scripts/decimal';
import { Spinner } from '../../../../components/spinner/spinner';
import { readProfile } from '../../../../core/repositories/profile/profile';
import { APPNAME } from '../../../../apis/constants';

enum DepositType {
  Gateway,
  CashIn,
}

interface AccountData {
  depositId: string;
  iBan: string;
  accountNumber: string;
  bankName: string;
  bankAccountOwner: string;
}

export const IRTDeposit = () => {
  const [paymentAmount, setPaymentAmount] = useState<number>();
  const [depositType, setDepositType] = useState<DepositType>(
    DepositType.Gateway,
  );
  const [isBankAccountModalOpen, setIsBankAccountModalOpen] =
    useState<boolean>(false);
  const [isIBanModalOpen, setIsIBanModalOpen] = useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = useState<BankAccountResponse[]>([]);
  const [bankAccountsLoading, setBankAccountsLoading] =
    useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [cashInLoading, setCashInLoading] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<BankAccountResponse>();
  const [policies, setPolicies] = useState<AmountPolicy>();
  const [wallet, setWallet] = useState<AssetItem>();
  const [isCashInActive, setIsCashInActive] = useState<boolean>(false);
  const [accountData, setAccountData] = useState<AccountData>();
  const [depositLimit, setDepositLimit] = useState<number>();
  const [depositLimitLoading, setDepositLimitLoading] =
    useState<boolean>(false);
  const [iBan, setIBan] = useState<string>();
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const CASHIN_WARNING: CollapseProps['items'] = [
    {
      key: '1',
      label: 'نکات ضروری در پرداخت شناسه دار',
      children: (
        <section className={'alert-content'}>
          <div className={'alert-logo'}>
            <WarningIcon />
          </div>
          <p>
            ۱- فقط از طریق حساب ثبت شده اقدام به واریز نمایید در غیر این صورت
            مبلغ پرداختی ضبط و بعد از ۷۲ ساعت کاری عودت داده می شود.
            <br />
            ۲- حتما شناسه واریز را در پرداخت وارد نمایید در غیر اینصورت واریزی
            شما بعد از ۷۲ ساعت عودت خواهد خورد.
            <br />
            ۳- واریز‌های شناسه‌دار خودکار انجام می‌شوند و نیازی نیست واریز خود
            را به {APPNAME} اطلاع دهید.
            <br />
            ۴- بعد از واریز شناسه دار امکان برداشت رمز ارز به مدت ۳۶ ساعت
            نخواهید داشت
          </p>
        </section>
      ),
    },
  ];

  const GATEWAY_WARNING: CollapseProps['items'] = [
    {
      key: '1',
      label: 'نکات ضروری در واریز از طریق درگاه پرداخت',
      children: (
        <section className={'alert-content'}>
          <div className={'alert-logo'}>
            <WarningIcon />
          </div>
          <p>
            ۱- از صحت آدرس صفحه‌ی پرداخت و بودن در یکی از سایت‌های سامانه‌ی
            شاپرک مطمئن شوید.
            <br />
            ۲- فقط از طریق کارت ثبت شده اقدام به واریز نمایید در غیر این صورت
            مبلغ پرداختی ضبط و بعد از ۷۲ ساعت کاری عودت داده می شود.
            <br />
            ۳- مطمئن شوید مبلغ نمایش‌ داده‌شده در صفحه‌ی پرداخت درست باشد.
            <br />
            ۴- جهت پرداخت فیلتر شکن خود را خاموش کنید.
          </p>
        </section>
      ),
    },
  ];
  const INFO_ITEMS: CollapseProps['items'] = [
    {
      key: '1',
      label: 'اطلاعات پرداخت شناسه دار',
      children: (
        <section className={'info-content'}>
          <div className={'info-logo'}>
            <InfoIcon />
          </div>
          <ul>
            <li>
              <p>شماره شبا</p>
              <Divider dashed />
              <span>
                <span>
                  <CopyIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      copyClipboard(accountData?.iBan!, 'شماره شبا')
                    }
                  />
                  &nbsp;&nbsp;&nbsp;
                  {accountData?.iBan}
                </span>
                <span className={'ir'}>IR</span>
              </span>
            </li>
            <li>
              <p>شماره حساب</p>
              <Divider dashed />
              <span>{accountData?.accountNumber}</span>
            </li>
            <li>
              <p>نام حساب</p>
              <Divider dashed />
              <span>
                {accountData?.bankAccountOwner} - {accountData?.bankName}
              </span>
            </li>
            <li>
              <p>شناسه واریز</p>
              <Divider dashed />
              <span>
                <CopyIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    copyClipboard(accountData?.depositId!, 'شناسه واریز')
                  }
                />
                <span>{accountData?.depositId}</span>
              </span>
            </li>
          </ul>
        </section>
      ),
    },
  ];

  const getCashInActivationStatus = async () => {
    setCashInLoading(true);
    await getViaAuth(API.deposit.cashIn.cashInActivationSetting).then(
      (response: any) => {
        setCashInLoading(false);
        if (responseValidator(response.status)) {
          setIsCashInActive(response.value.isCashInActive);
        } else {
          setIsCashInActive(false);
        }
      },
    );
  };

  const getCashInRequest = (item?: BankAccountResponse) => {
    setCashInLoading(true);
    postViaAuth(API.deposit.cashIn.request, {
      amount: paymentAmount,
      bankAccountId: item ? item.id : selectedCard?.id,
    }).then((response: any) => {
      setCashInLoading(false);
      if (responseValidator(response.status)) {
        setAccountData(response.value);
        setActiveKey(['1']);
      }
    });
  };

  const getMineDepositLimit = () => {
    setDepositLimitLoading(true);
    getViaAuth(API.transactions.depositLimit).then((response: any) => {
      setDepositLimitLoading(false);
      if (responseValidator(response.status)) {
        setDepositLimit(response.value.depositLeftAmount);
      }
    });
  };

  const handleCashInClick = () => {
    if (isCashInActive) {
      if (readProfile()?.level?.uniqueName?.id! < 3) {
        toast.error('واریز شناسه دار برای سطوح بالاتر از ۲ امکان پذیر است');
      } else {
        setSelectedCard(undefined);
        setDepositType(DepositType.CashIn);
      }
    } else {
      toast.error('واریز شناسه دار در حال حاضر غیر فعال میباشد');
    }
  };

  const getAmountPolicies = async () => {
    await getViaAuth<Cart[]>(API.policies.amount).then((response: any) => {
      if (responseValidator(response.status)) {
        setPolicies(response.value);
      }
    });
  };

  const onPaymentAmountChange = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    numericValue
      ? setPaymentAmount(parseInt(numericValue))
      : setPaymentAmount(0);
  };

  const onIBanChange = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    numericValue ? setIBan(faNumToEnNum(value)) : setIBan('');
  };

  const onSubmitGatewayPayment = () => {
    if (policies) {
      if (
        policies &&
        policies.minDepositlAmount &&
        Number.parseInt(policies.minDepositlAmount) <=
          Number.parseInt(deleteCommaSperator(paymentAmount))
      ) {
        setButtonLoading(true);
        postViaAuth(API.deposit.currency.pay, {
          amount: Number.parseInt(deleteCommaSperator(paymentAmount)),
          bankAccountId: selectedCard?.id,
        }).then((response: any) => {
          setButtonLoading(false);
          if (responseValidator(response.status)) {
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
              event: 'deposit',
              amount: paymentAmount,
            });
            toast.info('درحال انتقال به درگاه پرداخت ');
            window.open(response.value.paymentUrl, '_self');
          }
        });
      } else
        toast.error(
          `حداقل مقدار واریز ${digitSperator(
            policies?.minDepositlAmount,
          )} تومان است.`,
        );
    }
  };

  useEffect(() => {
    getCashInActivationStatus();
  }, []);

  useEffect(() => {
    !depositLimit && getMineDepositLimit();
    getMineBankAccount(setBankAccountsLoading, setBankAccounts);
    getAmountPolicies();
    getMineBalanceByPair('IRT', setBankAccountsLoading, setWallet);
  }, []);

  useEffect(() => {
    bankAccounts.length !== 0 && setSelectedCard(bankAccounts[0]);
  }, [bankAccounts]);

  useEffect(() => {
    if (!accountData) {
      setActiveKey([]);
    }
  }, [accountData]);

  return (
    <div className={'xpay-irt-deposit'}>
      <Modal
        isOpen={isIBanModalOpen}
        setIsOpen={setIsIBanModalOpen}
        className={'xpay-bank-accounts-modal'}
      >
        <Card
          title={'شماره شبای خود را برای پرداخت انتخاب کنید:'}
          href={'/dashboard/bank-accounts/add-iban'}
          hrefTitle={'افزودن شماره شبا'}
          divider
        >
          <ul>
            {bankAccountsLoading ? (
              <LoadingOutlined />
            ) : (
              bankAccounts?.map((item, index) => (
                <>
                  <li
                    onClick={() => {
                      if (item !== selectedCard) getCashInRequest(item);
                      setSelectedCard(item);
                      setIsIBanModalOpen(false);
                    }}
                    key={index}
                  >
                    <div className={'title'}>
                      <img src={`/banks/${item.bank.code.toLowerCase()}.svg`} />
                      <span>{item.bank.name}</span>
                    </div>
                    <span dir="ltr">{item.iBan}</span>
                    <BackIcon />
                  </li>
                  {index !== bankAccounts.length - 1 && <Divider />}
                </>
              ))
            )}
          </ul>
        </Card>
      </Modal>
      <Modal
        isOpen={isBankAccountModalOpen}
        setIsOpen={setIsBankAccountModalOpen}
        className={'xpay-bank-accounts-modal'}
      >
        <Card
          title={'کارت بانکی خود را برای پرداخت انتخاب کنید:'}
          href={'/dashboard/bank-accounts/add-card'}
          hrefTitle={'افزودن کارت'}
          divider
        >
          <ul>
            {bankAccountsLoading ? (
              <LoadingOutlined />
            ) : (
              bankAccounts?.map((item, index) => (
                <>
                  <li
                    onClick={() => {
                      setSelectedCard(item);
                      setIsBankAccountModalOpen(false);
                    }}
                    key={index}
                  >
                    <div className={'title'}>
                      <img src={`/banks/${item.bank.code.toLowerCase()}.svg`} />
                      <span>{item.bank.name}</span>
                    </div>
                    <span dir="ltr">{item.cardNumber}</span>
                    <BackIcon />
                  </li>
                  {index !== bankAccounts.length - 1 && <Divider />}
                </>
              ))
            )}
          </ul>
        </Card>
      </Modal>
      <div className={'deposit-box'}>
        <div className={'switches'}>
          <div
            className={`switch ${depositType === DepositType.Gateway ? 'active' : ''}`}
            onClick={() => setDepositType(DepositType.Gateway)}
          >
            <span>واریز از درگاه پرداخت</span>
          </div>
          <div
            className={`switch ${depositType === DepositType.CashIn ? 'active' : ''}`}
            onClick={handleCashInClick}
          >
            <span>واریز شناسه دار</span>
          </div>
        </div>
        <div className={'financial-info'}>
          <div className={'info'}>
            <p>موجودی فعلی شما:</p>
            <span>
              {wallet ? (
                digitSeparator(wallet?.balance.toFixed(0))
              ) : (
                <LoadingOutlined />
              )}{' '}
              تومان
            </span>
          </div>
          {depositType === DepositType.Gateway && (
            <div className={'info'}>
              <p>مانده تا سقف واریز تومانی شما:</p>
              <span>
                {depositLimitLoading ? (
                  <LoadingOutlined />
                ) : (
                  digitSeparator(depositLimit)
                )}{' '}
                تومان
              </span>
            </div>
          )}
        </div>
        <Divider className={'divider'} />
        {depositType === DepositType.CashIn && (
          <>
            <Spinner loading={cashInLoading} text={'در حال دریافت شناسه...'}>
              <Collapse
                items={INFO_ITEMS}
                defaultActiveKey={[]}
                activeKey={activeKey}
                onChange={(keys) => {
                  if (accountData) {
                    setActiveKey(keys as string[]);
                  }
                }}
                className={'info-collapse'}
                expandIconPosition="end"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <Button
                      type={'primary'}
                      icon={<EyeInvisibleOutlined />}
                      text={'بستن لیست'}
                    />
                  ) : (
                    <Button
                      type={'primary'}
                      icon={<EyeOutlined />}
                      text={'نمایش لیست'}
                    />
                  )
                }
              />
            </Spinner>
            <div
              className={`choose-card ${selectedCard ? 'chosen' : ''}`}
              onClick={() => setIsIBanModalOpen(true)}
            >
              {selectedCard ? (
                <>
                  <div className={'chosen-card'}>
                    <img
                      src={`/banks/${selectedCard.bank.code.toLowerCase()}.svg`}
                    />
                    <span>{selectedCard.bank.name}</span>
                    <span dir="ltr" className={'iban'}>
                      {selectedCard.iBan}
                    </span>
                  </div>
                  <SilverChevronIcon />
                </>
              ) : (
                <>
                  <p>شماره شبایی که با آن قصد پرداخت دارید را انتخاب کنید</p>
                  <BlueChevronIcon />
                </>
              )}
            </div>
          </>
        )}
        {depositType === DepositType.Gateway && (
          <>
            <Input
              value={digitSeparator(paymentAmount)}
              placeholder="مبلغ پرداختی را به تومان وارد کنید"
              type="text"
              dir={paymentAmount === undefined ? 'rtl' : 'ltr'}
              name="paymentAmount"
              onChange={(e) => onPaymentAmountChange(e.target.value)}
              adorments={{
                endAdornment: {
                  adornmentIcon: (
                    <div className={'adornment'}>
                      <span className={'en'}>IRT</span>
                      <img
                        src={'/coins/irt.svg'}
                        alt=""
                        width={32}
                        height={32}
                      />
                    </div>
                  ),
                },
              }}
            />
            <div className={'amount-selection'}>
              <div
                className={'amount-select'}
                onClick={() => setPaymentAmount(1000000)}
              >
                ۱م تومان
              </div>
              <div
                className={'amount-select'}
                onClick={() => setPaymentAmount(5000000)}
              >
                ۵م تومان
              </div>
              <div
                className={'amount-select'}
                onClick={() => setPaymentAmount(10000000)}
              >
                ۱۰م تومان
              </div>
              <div
                className={'amount-select'}
                onClick={() => setPaymentAmount(15000000)}
              >
                ۱۵م تومان
              </div>
              <div
                className={'amount-select'}
                onClick={() => setPaymentAmount(25000000)}
              >
                ۲۵م تومان
              </div>
            </div>
            <div
              className={`choose-card ${selectedCard ? 'chosen' : ''}`}
              onClick={() => setIsBankAccountModalOpen(true)}
            >
              {selectedCard ? (
                <>
                  <div className={'chosen-card'}>
                    <img
                      src={`/banks/${selectedCard.bank.code.toLowerCase()}.svg`}
                    />
                    <span>{selectedCard.bank.name}</span>
                    <span dir="ltr">{selectedCard.cardNumber}</span>
                  </div>
                  <SilverChevronIcon />
                </>
              ) : (
                <>
                  <p>کارت بانکی خود برای پرداخت از درگاه را انتخاب کنید</p>
                  <BlueChevronIcon />
                </>
              )}
            </div>
          </>
        )}
        {depositType === DepositType.Gateway ? (
          <Button
            type={'primary'}
            text={'انتقال به درگاه پرداخت'}
            disabled={
              bankAccountsLoading ||
              bankAccounts.length === 0 ||
              paymentAmount === 0 ||
              paymentAmount === undefined
            }
            onClick={onSubmitGatewayPayment}
            loading={buttonLoading}
          />
        ) : null}
      </div>
      <Collapse
        items={
          depositType === DepositType.Gateway ? GATEWAY_WARNING : CASHIN_WARNING
        }
        defaultActiveKey={'1'}
        className={'alert-collapse'}
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? (
            <Button
              type={'primary'}
              icon={<EyeInvisibleOutlined />}
              text={'بستن لیست'}
            />
          ) : (
            <Button
              type={'primary'}
              icon={<EyeOutlined />}
              text={'نمایش لیست'}
            />
          )
        }
      />
      {/*<FAQ />*/}
    </div>
  );
};
