import { API } from 'apis';
import { PaginationList } from 'core/domains';
import { Page } from 'core/domains/pagging/pagging';
import { ReferralAction } from 'core/domains/referral/referral';
import { getViaAuth, postViaAuth, responseValidator } from 'utils/scripts/api';
import { toast } from 'react-toastify';

export const getMineReferral = (setLoader: any, setResponse: any) => {
  setLoader(true);

  getViaAuth(API.referral.getMineReferralInfo).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    }
  });
};

export const getMineReferralActions = (
  setloader: any,
  setData: any,
  page: Page,
  query: string,
) => {
  if (page) {
    setloader(true);
    getViaAuth<PaginationList<ReferralAction>>(
      API.referral.getMineReferralActions +
        `${page.pageNumber}/${page.pageSize}` +
        query,
    ).then((response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setData(response.value);
      }
    });
  }
};

export const getMineInvitedUsers = (
  setloader: any,
  setData: any,
  page: Page,
  query: string,
) => {
  if (page) {
    setloader(true);
    getViaAuth<PaginationList<ReferralAction>>(
      API.referral.getMineInvitedUsers +
        `${page.pageNumber}/${page.pageSize}` +
        query,
    ).then((response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setData(response.value);
      }
    });
  }
};

export const setupOtp = (
  setLoader: any,
  setError: any,
  error: any,
  data: any,
  setStage: any,
  setIsOpenModal: any,
  type?: 'sms' | 'google',
) => {
  setLoader(true);
  setError(undefined);
  if (data?.phoneCode && data.phoneCode.length === 6) {
    postViaAuth(
      API.profile.setupOtp,
      type === 'google'
        ? { code: data.phoneCode, type: 2 }
        : { code: data.phoneCode, type: 1 },
    ).then(function (response: any) {
      setLoader(false);
      if (responseValidator(response.status)) {
        setIsOpenModal(false);
      } else {
        if (response.status === 400)
          setError({
            ...error,
            phoneCode: response.message,
          });
        if (response.status === 401 || response.status === 500)
          window.open('/login', '_self');
      }
    });
  } else {
    setLoader(false);
    setError({
      ...error,
      phoneCode: 'کد تایید باید دارای ۶ رقم باشد',
    });
  }
};

export const disableOtp = (setLoader: any, code: string) => {
  setLoader(true);
  if (code !== '' && code.length === 6) {
    postViaAuth(API.profile.disableOtp, {
      code: code,
    }).then(function (response: any) {
      setLoader(false);
      if (responseValidator(response.status)) {
        toast.success('تایید دو مرحله ای غیرفعال شد.');
        window.location.reload();
      }
    });
  } else toast.error('کد تایید باید دارای ۶ رقم باشد');
};

export const setupGoogleAuth = (
  setLoader: any,
  setError: any,
  error: any,
  data: any,
  setStage: any,
) => {
  setLoader(true);
  setError(undefined);
  if (data?.appCode && data.appCode.length === 6) {
    postViaAuth(API.profile.setupOtp, {
      code: data.appCode,
      type: 2,
    }).then(function (response: any) {
      setLoader(false);
      if (responseValidator(response.status)) {
        setStage('RECOVERY');
      } else {
        if (response.status === 400)
          setError({
            ...error,
            appCode: response.message,
          });
        if (response.status === 401 || response.status === 500)
          window.open('/login', '_self');
      }
    });
  } else {
    setLoader(false);
    setError({
      ...error,
      phoneCode: 'کد تایید باید دارای ۶ رقم باشد',
    });
  }
};
