import { Enum, Pagination } from '../commons/common';

export type Pair = {
  base: string;
  quote: string;
};
export type Order = {
  id: string;
  trackingCode: string;
  pair: Pair;
  side: Enum;
  state: Enum;
  type: Enum;
  destinationQuantity: number;
  sourceQuantity: number;
  createdOn: Date;
  commission: number;
  onPrice: number;
};

export interface OrderList {
  items: Order[];
  pagination: Pagination;
}

export type OrderFilterValues = {
  startDate?: string;
  endDate?: string;
  side?: Enum;
  keywords?: string;
  sort?: Enum;
  type?: Enum;
};

export enum OrderState {
  Open = 1,
  Expired,
  Filled,
  Canceled,
  Rejected,
}

export enum OrderSide {
  Buy = 1,
  Sell,
  Trade,
}
