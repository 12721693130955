import * as React from 'react';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button } from 'components/button/button';
import './pagination.scss';

const { useState } = React;

type Props = {
  data: any[];
  setdata: any;
  getdata: any;
  setloader: any;
  page: number;
  totalRows: number;
  size: number;
  query?: string;
};

export const Pagination: React.FC<Props> = ({
  totalRows,
  getdata,
  setloader,
  page,
  setdata,
  size,
  query,
}) => {
  const [totalPage, setTotalPage] = useState<number>(0);
  const [minPageLimit, setMinPageLimit] = useState<number>(0);
  const [maxPageLimit, setMaxPageLimit] = useState<number>(0);

  const onPrevPage = () => {
    page !== 1 &&
      getdata(
        setloader,
        setdata,
        {
          pageNumber: page - 1,
          pageSize: 10,
        },
        query,
      );
  };
  const onNextPage = () => {
    page !== totalPage &&
      getdata(
        setloader,
        setdata,
        {
          pageNumber: page + 1,
          pageSize: 10,
        },
        query,
      );
  };

  React.useEffect(() => {
    setTotalPage(Math.ceil(totalRows / size));
  }, []);

  React.useEffect(() => {
    totalPage && page > totalPage - 4
      ? setMinPageLimit(totalPage - 4)
      : page < 5
        ? setMinPageLimit(1)
        : setMinPageLimit(page);

    totalPage && page > totalPage - 4
      ? setMaxPageLimit(totalPage)
      : page < 5
        ? setMaxPageLimit(5)
        : setMaxPageLimit(page + 4);
  }, [page]);

  return (
    <div className="xpay-pagination">
      <div className="pagination-row">
        <DoubleRightOutlined
          className={`${page == 1 || totalPage <= 1 ? 'disabled' : ''}`}
          onClick={() =>
            page != 1 && getdata(setloader, setdata, 1, size, query)
          }
        />
        <div className={`page-navigation ${page == 1 || totalPage <= 1 ? 'disabled' : ''}`} onClick={() => onPrevPage()}>
          <RightOutlined
            className='inner-icon'
          />
          صفحه قبل
        </div>
        {/* <RightOutlined
          onClick={() => onPrevPage()}
          className={`${page == 1 || totalPage <= 1 ? 'disabled' : ''}`}
        /> */}
        <div className="pages">
          {minPageLimit > 1 && <Button type="outlined" text="..." />}
          {Array.from({ length: totalPage }, (item, index) => index + 1).map(
            (pageItem) => (
              <>
                {pageItem >= minPageLimit && pageItem <= maxPageLimit && (
                  <Button
                    onClick={() =>
                      getdata(
                        setloader,
                        setdata,
                        {
                          pageNumber: pageItem,
                          pageSize: size,
                        },
                        query,
                      )
                    }
                    text={pageItem.toString()}
                    type={pageItem === page ? 'info' : 'outlined'}
                  />
                )}
              </>
            ),
          )}
          {totalPage > maxPageLimit && <Button type="outlined" text="..." />}
        </div>
        {/* <LeftOutlined
          className={`${page == totalPage || totalPage <= 1 ? 'disabled' : ''}`}
          onClick={() => onNextPage()}
        /> */}
        <div className={`page-navigation ${page == totalPage || totalPage <= 1 ? 'disabled' : ''}`} onClick={() => onNextPage()}>
          صفحه بعد
          <LeftOutlined
            className='inner-icon'
          />
        </div>
        <DoubleLeftOutlined
          className={`${page == totalPage || totalPage <= 1 ? 'disabled' : ''}`}
          onClick={() =>
            page != totalPage &&
            getdata(
              setloader,
              setdata,
              {
                pageNumber: totalPage,
                pageSize: size,
              },
              query,
            )
          }
        />
      </div>
      {totalPage > 1 && (
        <div className="foot">
          نمایش <b>{(page - 1) * size}</b> تا{' '}
          <b>{page == totalPage ? totalRows : page * size}</b> از{' '}
          <b>{totalRows}</b> ردیف
        </div>
      )}
    </div>
  );
};
