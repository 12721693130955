import './crypto-withdrawal.scss';
import {
  getViaAuth,
  postViaAuth,
  responseValidator,
} from '../../../../utils/scripts/api';
import {
  Enum,
  Network,
  NetworkList,
  Transaction,
} from '../../../../core/domains';
import { API } from '../../../../apis';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Asset, AssetItem } from '../../../../core/domains/markets/asset';
import { getAllNetworks } from '../../../../core/repositories';
import {
  digitSeparator,
  digitSperator,
} from '../../../../utils/scripts/exchange';
import {
  CloseOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Collapse, CollapseProps, Divider, Slider } from 'antd';
import { Button, Card, Input, Modal } from '../../../../components';
import { ReactComponent as WarningIcon } from 'assets/icons/wallet/WarningYellow.svg';
import { ReactComponent as BlueChevronIcon } from 'assets/icons/wallet/BlueChevron.svg';
import { ReactComponent as SilverChevronIcon } from 'assets/icons/wallet/SilverChevron.svg';
import { ReactComponent as ThreeDots } from 'assets/icons/create-order/ThreeDots.svg';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { faNumToEnNum } from '../../../../utils/scripts/decimal';
import { isMobile } from 'react-device-detect';
import { WithdrawalConfirm } from '../widthrawl/confirmModal/withdrawal-confirm-modal';
import { getIconPath } from '../../../../utils/scripts/getIconPath';
import { APPNAME } from '../../../../apis/constants';

const ALERT_ITEMS: CollapseProps['items'] = [
  {
    key: '1',
    label: 'نکات ضروری در برداشت رمز ارز',
    children: (
      <section className={'alert-content'}>
        <div className={'alert-logo'}>
          <WarningIcon />
        </div>
        <p>
          ۱- در تعیین شبکه برداشت دقت لازم را داشته باشید و از پشتیبانی کیف پول
          مقصد از شبکه انتخابی اطمینان حاصل کنید.
          <br />
          ۲- کاربران گرامی، آگهی‌های اینترنتی کسب درآمد، کاملا با هدف کلاهبرداری
          و سوءاستفاده از مشخصات هویتی شما انجام می‌شوند. بنابراین جهت جلوگیری
          از مواجه شدن با اتهاماتی نظیر «پولشویی» یا «مشارکت در دزدی» به هیچ
          عنوان برای دیگران خرید و فروش و برداشت انجام ندهید. مسئولیت هرگونه
          تخلف به عهده‌ی صاحب حساب می‌باشد و {APPNAME} هیچ مسئولیتی در این زمینه
          ندارد.
          <br />
          ۳- در صورتی که ولت مقصد دارای ممو هست حتما وارد کنید در غیر اینصورت
          ممکن است در شبکه معلق گردد.
          <br />
          ۴- در شرایطی که شبکه مورد نظر شما در دسترس نیست ؛ مشکل فوق از سمت خود
          شبکه است و کمی بعد امتحان کنید
        </p>
      </section>
    ),
  },
];

export const CryptoWithdrawal = () => {
  const [maxCryptoWithdrawal, setMaxCryptoWithdrawal] = useState<number>(0);
  const [assets, setAssets] = useState<Asset>();
  const [assetsResult, setAssetsResult] = React.useState<Asset>();
  const [selectedAsset, setSelectedAsset] = useState<AssetItem>();
  const [assetsLoading, setAssetsLoading] = useState<boolean>(false);
  const [assetsModalIsOpen, setAssetsModalIsOpen] = useState<boolean>(false);
  const [networksModalIsOpen, setNetworksModalIsOpen] =
    useState<boolean>(false);
  const [networks, setNetworks] = useState<NetworkList>();
  const [selectedNetwork, setSelectedNetwork] = useState<Network>();
  const [networksLoading, setNetworksLoading] = useState<boolean>(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState<string>('');
  const [withdrawalInputAmount, setWithdrawalInputAmount] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [destinationWallet, setDestinationWallet] = useState<string>('');
  const [memo, setMemo] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [sliderValue, setSliderValue] = useState<number>();
  const location = useLocation();
  const [twoStepIsOpen, setTwoStepIsOpen] = useState<boolean>(false);
  const [modalStage, setModalStage] = useState<'code' | 'thankyou'>('code');
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [transaction, setTransaction] = useState<Transaction>();
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [isTwoStepActive, setIsTwoStepActive] = useState<number>();
  const [twoFactorMethod, setTwoFactorMethod] = useState<Enum>();

  const getAssets = async () => {
    await getViaAuth<Asset>(`${API.wallet.assets}`).then((data: any) => {
      setAssetsLoading(true);
      if (responseValidator(data.status) && data.value) {
        setAssets(data.value);
        setAssetsResult(data.value);
        location.pathname.split('/')[4]
          ? setSelectedAsset(
              data.value.assets.find(
                (s: AssetItem) =>
                  s.symbol.toUpperCase() ===
                  location.pathname.split('/')[4].toUpperCase(),
              ),
            )
          : setSelectedAsset(
              data.value.assets.find(
                (s: AssetItem) => s.symbol.toUpperCase() === 'BTC',
              ),
            );
      }
      setAssetsLoading(false);
    });
  };

  const onChangeSlider = (sliderValue: number) => {
    if (sliderValue !== null && selectedAsset) {
      setWithdrawalAmount(
        ((selectedAsset?.balance * sliderValue) / 100).toString(),
      );
      setSliderValue(sliderValue);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (assets) {
      const filteredAssets = assets.assets.filter(
        (asset) =>
          asset.symbol.toLowerCase().startsWith(value.toLowerCase()) ||
          asset.name.toLowerCase().startsWith(value.toLowerCase()),
      );
      setAssetsResult((prevAssetsResult) => ({
        ...prevAssetsResult!,
        assets: filteredAssets,
      }));
    }
  };

  const onWithdrawalAmountChange = (value: string) => {
    var cleanedValue = faNumToEnNum(value);
    const regex = new RegExp(
      `^(\\d*\\.{0,100}\\d{0,${networks?.withdrawPrecision ?? 6}})$`,
    );

    if (regex.test(value)) {
      const numericValue = cleanedValue
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*)\./g, '$1');

      setWithdrawalAmount(numericValue);
    }
  };

  const onConfirmHandler = () => {
    if (selectedNetwork) {
      if (!selectedNetwork.isWithdrawEnabled) {
        toast.error(
          `شبکه ${selectedNetwork.name} به دلیل اختلال در دسترس نیست ، لطفاً بعدا تلاش کنید`,
        );
        return;
      }
      if (
        selectedNetwork.addressRegex &&
        !new RegExp(selectedNetwork.addressRegex).test(destinationWallet)
      ) {
        toast.error('آدرس ولت وارد شده معتبر نیست.');
        return;
      }

      setLoading(true);
      postViaAuth(API.widthrawl.crypto, {
        amount: withdrawalAmount!,
        market: selectedAsset?.symbol,
        network: selectedNetwork.network,
        addressWallet: destinationWallet,
        memoAddress: memo,
      }).then((response: any) => {
        setLoading(false);
        if (responseValidator(response?.status)) {
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'withdrawal',
            amount: withdrawalAmount,
          });
          setModalStage('code');
          setModalIsOpen(true);
          setTwoFactorMethod(response?.value.twoFactorMethod);
          setTransaction(response?.value?.transaction);
          setIsWaiting(response?.value ? false : true);
          setIsTwoStepActive(response?.value?.twoFactorMethod.id);
        }
      });
    }
  };

  function backModal() {
    setWithdrawalAmount('');
    setTwoStepIsOpen(false);
  }

  function resetForm() {
    setModalStage('code');
    setDestinationWallet('');
    setMemo('');
    setCode('');
  }

  function onClose() {
    setModalIsOpen(false);
    resetForm();
  }

  const onVerifyHandler = (code: string) => {
    if (code.length === 6) {
      setModalLoading(true);
      postViaAuth(API.widthrawl.verifyCrypto, {
        code: code,
        trackingCode: transaction?.trackingCode,
      }).then((response: any) => {
        setModalLoading(false);
        if (responseValidator(response.status)) {
          toast.success('تراکنش برداشت شما با موفقیت ثبت شد');
          resetForm();
          setModalStage('thankyou');
        }
      });
    }
  };

  useEffect(() => {
    !assets && getAssets();
  }, []);

  useEffect(() => {
    selectedAsset &&
      getAllNetworks(
        setNetworksLoading,
        setNetworks,
        selectedAsset.symbol,
        false,
      );
  }, [selectedAsset]);

  useEffect(() => {
    networks && setSelectedNetwork(networks?.networks[0]);
  }, [networks]);

  return (
    <div className={'xpay-crypto-withdrawal'}>
      <Modal
        isOpen={modalIsOpen}
        className="currency-widthrawl-confirm-modal"
        setIsOpen={setModalIsOpen}
      >
        {transaction && assets && (
          <WithdrawalConfirm
            onBack={onClose}
            isTwoStepActive={isTwoStepActive ?? 0}
            setIsOpen={setModalIsOpen}
            setLoading={setLoading}
            isWaiting={isWaiting}
            marketName={selectedAsset?.name!}
            trackingCode={transaction?.trackingCode}
            stage={modalStage}
            amount={parseFloat(withdrawalAmount!)}
            loading={modalLoading}
            onConfirm={onVerifyHandler}
            onResend={onConfirmHandler}
            networkFee={transaction.network.fee}
            symbol={transaction.market.symbol}
            twoFactorMethod={twoFactorMethod}
          />
        )}
      </Modal>
      <Modal
        isOpen={networksModalIsOpen}
        setIsOpen={setNetworksModalIsOpen}
        className={'xpay-networks-modal'}
        closeAble
      >
        <Card title={'شبکه مورد نظر را انتخاب کنید:'} divider>
          <ul>
            {networksLoading ? (
              <LoadingOutlined />
            ) : (
              networks?.networks?.map((item, index) => (
                <>
                  <li
                    onClick={() => {
                      setSelectedNetwork(item);
                      setNetworksModalIsOpen(false);
                    }}
                    key={index}
                  >
                    <span className={'en'}>{item.name}</span>
                    <div className={'fee'}>
                      <p>کارمزد شبکه</p>
                      <span>{item?.withdrawFee} واحد</span>
                    </div>
                  </li>
                  {index !== networks.networks.length - 1 && <Divider />}
                </>
              ))
            )}
          </ul>
        </Card>
      </Modal>
      <Modal
        isOpen={assetsModalIsOpen}
        setIsOpen={setAssetsModalIsOpen}
        className={'xpay-assets-modal'}
        closeAble
      >
        <Card title={'ارز مورد نظر را انتخاب کنید:'} divider>
          <Input
            name={'assets-search'}
            type={'text'}
            value={searchValue}
            placeholder={'جست و جوی رمزارز...'}
            onChange={(e) => handleSearch(e.target.value)}
            adorments={{
              endAdornment: {
                adornmentIcon: (
                  <CloseOutlined
                    onClick={() => setSearchValue('')}
                    style={{ cursor: 'pointer' }}
                  />
                ),
              },
            }}
          />
          <h4>لیست دارایی ها</h4>
          <ul>
            {assetsLoading ? (
              <LoadingOutlined />
            ) : (
              assetsResult?.assets
                ?.filter(
                  (item) =>
                    item.symbol.toUpperCase() !== 'IRT' &&
                    item.symbol.toUpperCase() !== 'PM' &&
                    item.symbol.toUpperCase() !== 'PM-U',
                )
                .map((item, index) => (
                  <>
                    <li
                      onClick={() => {
                        setSelectedAsset(item);
                        setAssetsModalIsOpen(false);
                      }}
                      key={index}
                    >
                      <div className={'currency-title'}>
                        <img
                          alt=""
                          src={getIconPath(item.symbol)}
                          width={32}
                          height={32}
                        />
                        <span>{item.name}</span>
                        <span className={'en'}>
                          {item.symbol.toUpperCase()}
                        </span>
                      </div>
                      <div className={'currency-balance'}>
                        <span>موجودی:</span>
                        <span>{digitSeparator(item.balance)} واحد</span>
                      </div>
                    </li>
                    {index !== assetsResult.assets.length - 1 && <Divider />}
                  </>
                ))
            )}
          </ul>
        </Card>
      </Modal>
      <div className={'withdrawal-box'}>
        <div className={'financial-info'}>
          <div className={'info'}>
            <p>حداقل میزان برداشت در شبکه:</p>
            <span>
              {networksLoading ? (
                <LoadingOutlined />
              ) : selectedNetwork ? (
                digitSeparator(selectedNetwork.withdrawMin) + ' واحد'
              ) : (
                'ابتدا شبکه انتقال ارز را انتخاب نمایید'
              )}
            </span>
          </div>
          <div className={'info'}>
            <p>حداقل میزان برداشت با احتساب کارمزد:</p>
            <span>
              {networksLoading ? (
                <LoadingOutlined />
              ) : selectedNetwork ? (
                digitSeparator(
                  (selectedNetwork.withdrawMin ?? 0) +
                    (selectedNetwork.withdrawFee ?? 0),
                ) + ' واحد'
              ) : (
                'ابتدا شبکه انتقال ارز را انتخاب نمایید'
              )}
            </span>
          </div>
        </div>
        <Divider />
        <Input
          value={digitSperator(withdrawalAmount)}
          placeholder="تعداد واحد مورد نظر برای برداشت"
          type="text"
          dir={withdrawalAmount === '' ? 'rtl' : 'ltr'}
          name="withdrawalAmount"
          onChange={(e) => onWithdrawalAmountChange(e.target.value)}
          adorments={{
            endAdornment: {
              adornmentIcon: (
                <div className={'adornments'}>
                  <Button
                    type={'card'}
                    text={'انتخاب تمام موجودی'}
                    onClick={() => {
                      setWithdrawalAmount(
                        selectedAsset?.balance?.toString() ?? '0',
                      );
                      setSliderValue(100);
                    }}
                  />
                  <div
                    className={'currency-container'}
                    onClick={() =>
                      selectedAsset ? setAssetsModalIsOpen(true) : null
                    }
                  >
                    {selectedAsset ? (
                      <>
                        <DownOutlined />
                        <span className={'en'}>{selectedAsset.symbol}</span>
                        <img
                          src={getIconPath(selectedAsset.symbol)}
                          alt=""
                          width={32}
                          height={32}
                        />
                      </>
                    ) : (
                      <LoadingOutlined />
                    )}
                  </div>
                </div>
              ),
            },
          }}
        />
        <Slider
          value={sliderValue}
          marks={{
            0: 'حداقل',
            25: '25٪',
            50: '50٪',
            75: '75٪',
            100: '100٪',
          }}
          step={1}
          tooltip={{
            placement: 'bottom',
            formatter: (value) => {
              return `${value}٪`;
            },
          }}
          trackStyle={{ background: '#3F62FE' }}
          onChange={(sliderValue) => onChangeSlider(sliderValue)}
        />
        <div
          className={'choose-network'}
          onClick={() => setNetworksModalIsOpen(true)}
        >
          {selectedNetwork ? (
            <>
              <div className={'chosen-network'}>
                <span className={'en'}>{selectedNetwork.name}</span>
              </div>
              {networksLoading ? <LoadingOutlined /> : <BlueChevronIcon />}
            </>
          ) : (
            <>
              <p>شبکه انتقال ارز واریزی را انتخاب کنید</p>
              {networksLoading ? <LoadingOutlined /> : <SilverChevronIcon />}
            </>
          )}
        </div>

        {selectedNetwork?.withdrawFee ? (
          <ul className={'withdrawal-info'}>
            <li className={'withdrawal-info-item'}>
              <p>
                <ThreeDots />
                &nbsp;&nbsp;&nbsp;کارمزد شبکه
              </p>
              <p>
                {selectedNetwork?.withdrawFee} {selectedAsset?.name}
              </p>
            </li>
            {Number(withdrawalAmount) - selectedNetwork.withdrawFee > 0 ? (
              <>
                <Divider />
                <li className={'withdrawal-info-item'}>
                  <p>مقدار نهایی برداشت</p>
                  <p>
                    {Number(withdrawalAmount) - selectedNetwork?.withdrawFee}{' '}
                    {selectedAsset?.name}
                  </p>
                </li>
              </>
            ) : null}
          </ul>
        ) : null}
        <Input
          className={'destination-wallet-input'}
          value={destinationWallet}
          placeholder={isMobile ? 'کیف پول مقصد را وارد کنید...' : ''}
          type="text"
          dir={isMobile ? 'rtl' : 'ltr'}
          name="destinationWallet"
          onChange={(e) => setDestinationWallet(e.target.value)}
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <span className={'start-adornment'}>
                  آدرس کیف پول مقصد یا گیرنده:
                </span>
              ),
            },
          }}
        />

        <Input
          className={'memo-input'}
          value={memo}
          placeholder={isMobile ? 'آدرس ممو را وارد کنید (اختیاری)...' : ''}
          type="text"
          dir={isMobile ? 'rtl' : 'ltr'}
          name="memo"
          onChange={(e) => setMemo(e.target.value)}
          adorments={{
            startAdornment: {
              adornmentIcon: (
                <span className={'start-adornment'}>آدرس ممو (اختیاری):</span>
              ),
            },
          }}
        />

        <Button
          type={'primary'}
          text={'ثبت برداشت'}
          disabled={
            loading ||
            withdrawalAmount === '0' ||
            withdrawalAmount === '' ||
            destinationWallet === '' ||
            !selectedAsset ||
            !selectedNetwork
          }
          onClick={onConfirmHandler}
          loading={loading}
        />
      </div>
      <Collapse
        items={ALERT_ITEMS}
        defaultActiveKey={'1'}
        className={'alert-collapse'}
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? (
            <Button
              type={'primary'}
              icon={<EyeInvisibleOutlined />}
              text={'بستن لیست'}
            />
          ) : (
            <Button
              type={'primary'}
              icon={<EyeOutlined />}
              text={'نمایش لیست'}
            />
          )
        }
      />
      {/*<FAQ />*/}
    </div>
  );
};
