import React from 'react';
import './my-orders.scss';
import { IrtTransactions } from './irt-transactions/irt-transactions';
import { PmTransactions } from './pm-transactions/pm-transactions';
import { CryptoTransactions } from './crypto-transactions/crypto-transactions';
import { MyTrades } from './trade/my-trades';
import { Divider } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const { useState, useEffect } = React;

export enum OrderHistoryTypes {
  Trades = 0, // خرید و فروش
  IRT_Transactions = 1, // واریز و برداشت تومانی
  Currency_Transactions = 2, // واریز و برداشت ارزی
  PM_Transactions = 3, // خرید و فروش پرفکت مانی
}

interface Props {
  orderType?: OrderHistoryTypes;
}

const MyOrders: React.FC<Props> = (props) => {
  const [orderType, setOrderType] = useState<number>(
    props.orderType ?? OrderHistoryTypes.PM_Transactions,
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const typeParam = params.get('type');

    if (
      typeParam &&
      Object.values(OrderHistoryTypes).includes(parseInt(typeParam))
    ) {
      setOrderType(parseInt(typeParam));
    }
  }, [location.search]);

  const updateUrlQuery = (newType: OrderHistoryTypes) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('type', newType.toString());
    navigate(`/dashboard/orders?${searchParams.toString()}`);
  };

  const handleOrderTypeChange = (newType: OrderHistoryTypes) => {
    setOrderType(newType);
    updateUrlQuery(newType);
  };

  return (
    <div className="xpay-my-orders">
      {/*<div className="type-selection">*/}
      {/*  <div*/}
      {/*    className={`${orderType === 0 ? 'active' : ''}`}*/}
      {/*    onClick={() => handleOrderTypeChange(OrderHistoryTypes.Trades)}*/}
      {/*  >*/}
      {/*    <span>خرید و فروش</span>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className={`${orderType === 1 ? 'active' : ''}`}*/}
      {/*    onClick={() =>*/}
      {/*      handleOrderTypeChange(OrderHistoryTypes.IRT_Transactions)*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <span>واریز و برداشت تومانی</span>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className={`${orderType === 2 ? 'active' : ''}`}*/}
      {/*    onClick={() =>*/}
      {/*      handleOrderTypeChange(OrderHistoryTypes.Currency_Transactions)*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <span>واریز و برداشت ارزی</span>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className={`${orderType === 3 ? 'active' : ''}`}*/}
      {/*    onClick={() =>*/}
      {/*      handleOrderTypeChange(OrderHistoryTypes.PM_Transactions)*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <span>خرید و فروش پرفکت مانی</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<Divider className="orders-divider" />*/}
      <div className="orders-table">
        {orderType === OrderHistoryTypes.IRT_Transactions ? (
          <IrtTransactions />
        ) : orderType === OrderHistoryTypes.Trades ? (
          <MyTrades />
        ) : orderType === OrderHistoryTypes.Currency_Transactions ? (
          <CryptoTransactions />
        ) : orderType === OrderHistoryTypes.PM_Transactions ? (
          <PmTransactions />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
