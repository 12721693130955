const regex = {
    phoneRegex: /^(?!^[123456789])(?:.*[ ])?\d{11}$/gm,
    shabaRegex: /^(?=.{24}$)[0-9]*$/g,
    emailRegex:
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
    persianNameRegex:
        /(?!.*[۱۲۳۴۵۶۷۸۹۰])(?!.*\w)(?!.*[^\W])(?!.*[\d])(?!.*[~!@#$%^&*()_+<>:"';{}])[\u0600-\u06FF\s]/g,
    creditCard: /(\d{4}[-. ]?){4}|\d{4}[-. ]?\d{6}[-. ]?\d{5}/g,
    passwordRegex: /^(?=.*\d)(?=.*[a-z]).{6,}$/gm,
    enNumberRegex: /^\d{0,100}(?:\.\d{0,6}){0,6}$/,
};

export const {
    phoneRegex,
    shabaRegex,
    emailRegex,
    persianNameRegex,
    creditCard,
    enNumberRegex,
    passwordRegex,
} = regex;
