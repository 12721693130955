import './fishing-modal.scss';
import { Button, Modal } from '../../../../components';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import fishing from 'assets/images/authentication/fishing.png';
import { APPNAME } from '../../../../apis/constants';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const FishingModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className={'xpay-fishing-modal'}
      title={'هشدار!'}
      closeAble
    >
      {/*<div className={'video-container'}>*/}
      {/*  <video controls>*/}
      {/*    <source*/}
      {/*      src={''}*/}
      {/*    />*/}
      {/*    Your browser does not support the video tag.*/}
      {/*  </video>*/}
      {/*</div>*/}
      <img src={fishing} alt={'fishing'} />
      <p>ممکنه کاسه ای زیر نیم کاسه باشه!</p>
      <p>
        اگر فردی از شما درخواست ثبت نام در سایت های ارز دیجیتال مانند {APPNAME}{' '}
        را کرده و در قبال آن پیشنهاد دریافت حقوق و کارمزد را داده است، از
        پذیرفتن آن جدا خودداری کنید. چرا که این افراد قصد پولشویی از طریق حساب
        های بانکی شما دارند که در این صورت شما را درگیر مسائل پیچیده قضایی خواهد
        کرد. جهت دریافت اطلاعات بیشتر با پشتیبانی {APPNAME} در تماس باشید.
      </p>
      <span>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />{' '}
        متن و قوانین را مشاهده کردم و از موارد اشاره شده آگاه هستم
      </span>
      <Button
        type="primary"
        text="تایید قوانین و ادامه"
        disabled={!checked}
        onClick={() => setIsOpen(false)}
      />
    </Modal>
  );
};
