import React, { useState } from 'react';
import {
  CloseCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Input } from 'antd';
import { postViaAuth, responseValidator } from 'utils/scripts/api';
import { API } from 'apis';
import { toast } from 'react-toastify';
import './change-password.scss';
import { Button, Card } from '../../../../components';

type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  phone?: string;
};

export const ChangePassword: React.FC<Props> = ({ setIsOpen, phone }) => {
  const [stage, setStage] = useState<'PHONE' | 'NEW_PASS'>('NEW_PASS');
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<{
    password: string[];
    repeatPassword: string[];
  }>({ password: [], repeatPassword: [] });

  const handlePhoneCodeVerify = async () => {
    setLoading(true);
    setError('');
    if (phoneCode.length === 6) {
      try {
        const response = await postViaAuth(API.verify_2fa, {
          code: phoneCode,
          phoneNumber: phone,
        });
        setLoading(false);
        if (responseValidator(response.status)) {
          setStage('NEW_PASS');
        } else {
          setError(response.message || 'خطایی رخ داده است.');
        }
      } catch (error) {
        setLoading(false);
        setError('خطای شبکه، لطفا دوباره تلاش کنید.');
      }
    } else {
      setLoading(false);
      setError('کد تایید باید شش رقمی باشد.');
    }
  };

  const changePassword = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await postViaAuth(API.changePassword, {
        password,
        repeatPassword,
      });
      setLoading(false);
      if (responseValidator(response.status)) {
        toast.success('رمز عبور با موفقیت تغییر کرد.');
        setIsOpen(false);
        window.open('/login', '_self');
      } else {
        setError('تغییر رمز عبور ناموفق بود.');
      }
    } catch (error) {
      setLoading(false);
      setError('خطای شبکه، لطفا دوباره تلاش کنید.');
    }
  };

  const handlePasswordChange = (
    value: string,
    field: 'password' | 'repeatPassword',
  ) => {
    const newErrors = { ...passwordError, [field]: [] };

    if (value.length < 6) {
      newErrors[field].push('رمز عبور باید حداقل شش کاراکتر باشد.');
    } else {
      newErrors[field] = newErrors[field].filter(
        (msg) => msg !== 'رمز عبور باید حداقل شش کاراکتر باشد.',
      );
    }

    if (/\d/.test(value)) {
      newErrors[field] = newErrors[field].filter(
        (msg) => msg !== 'باید حداقل شامل یک عدد باشد.',
      );
    } else {
      newErrors[field].push('باید حداقل شامل یک عدد باشد.');
    }

    setPasswordError(newErrors);
    if (field === 'password') {
      setPassword(value);
    } else {
      setRepeatPassword(value);
    }

    if (!value || value === '') {
      setPasswordError({ password: [], repeatPassword: [] });
    }
  };

  const renderStage = () => {
    switch (stage) {
      case 'PHONE':
        return (
          <div className="main code">
            <form className="new-password-form">
              <Input
                prefix={<PhoneOutlined />}
                value={phoneCode}
                onChange={(e) => setPhoneCode(e.target.value)}
                maxLength={6}
                placeholder="کد تایید را وارد کنید"
                onPressEnter={handlePhoneCodeVerify}
                className="phone-input"
              />
              <Button
                type={'primary'}
                onClick={handlePhoneCodeVerify}
                loading={loading}
                text={'تایید کد'}
              />
              {error && (
                <div className="error-message">
                  <QuestionCircleOutlined />
                  <span>{error}</span>
                </div>
              )}
            </form>
          </div>
        );
      case 'NEW_PASS':
        return (
          <div className="main new-password">
            <form className="new-password-form">
              <Input.Password
                prefix={<LockOutlined />}
                value={password}
                onChange={(e) =>
                  handlePasswordChange(e.target.value, 'password')
                }
                placeholder="رمز عبور جدید"
                onPressEnter={changePassword}
                className="new-password-input"
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
                visibilityToggle={true}
              />
              <div className={'errors'}>
                {passwordError.password.length > 0
                  ? passwordError.password.map((msg, i) => (
                      <div className={'error'}>
                        <CloseCircleOutlined /> {msg}
                      </div>
                    ))
                  : null}
              </div>
              <Input.Password
                prefix={<LockOutlined />}
                value={repeatPassword}
                onChange={(e) =>
                  handlePasswordChange(e.target.value, 'repeatPassword')
                }
                placeholder="تکرار رمز عبور جدید"
                onPressEnter={changePassword}
                className="new-password-input"
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
                visibilityToggle={true}
                suffix={
                  passwordError.repeatPassword.length > 0 ? (
                    <span className="error">
                      {passwordError.repeatPassword.join(' ')}
                    </span>
                  ) : null
                }
              />
              <div className={'errors'}>
                {passwordError.repeatPassword.length > 0
                  ? passwordError.repeatPassword.map((msg, i) => (
                      <div className={'error'}>
                        <CloseCircleOutlined /> {msg}
                      </div>
                    ))
                  : null}
              </div>
              <Button
                onClick={changePassword}
                loading={loading}
                text={'تغییر رمز عبور'}
                type={'primary'}
                disabled={
                  password !== repeatPassword ||
                  !password ||
                  !repeatPassword ||
                  password.length < 6 ||
                  repeatPassword.length < 6
                }
              />
            </form>
          </div>
        );
      default:
        return;
        <div>مرحله نامعتبر است</div>;
    }
  };

  return <Card className="xpay-profile-change-password">{renderStage()}</Card>;
};
