const banks = [
    {
        card_no: 627381,
        bank_name: "ansar",
        bank_title: "بانک انصار",
        bank_logo: "/banks/Ansar.svg",
        color: "#26eeb900",
    },
    {
        card_no: 636214,
        bank_name: "ayandeh",
        bank_title: "بانک آینده",
        bank_logo: "/banks/Ayandeh.svg",
        color: "#3388391d",
    },
    {
        card_no: 502938,
        bank_name: "dey",
        bank_title: "بانک دی",
        bank_logo: "/banks/Dey.svg",
        color: "#0dffffff",
    },
    {
        card_no: 627412,
        bank_name: "eghtesad_novin",
        bank_title: "بانک اقتصادنوین",
        bank_logo: "/banks/Eghtesad_Novin.svg",
        color: "#1a662891",
    },
    {
        card_no: 628157,
        bank_name: "etebari_tosee",
        bank_title: "موسسه اعتباری توسعه",
        bank_logo: "/banks/Tosee.svg",
        color: "#33000000",
    },
    {
        card_no: 505416,
        bank_name: "gardeshgari",
        bank_title: "بانک گردشگری",
        bank_logo: "/banks/Gardeshgari.svg",
        color: "#29344143",
    },
    {
        card_no: 639599,
        bank_name: "ghavvamin",
        bank_title: "بانک قوامین",
        bank_logo: "/banks/Ghavamin.svg",
        color: "#1afff400",
    },
    {
        card_no: 627488,
        bank_name: "kar_afarin",
        bank_title: "بانک کارآفرین",
        bank_logo: "/banks/Karafarin.svg",
        color: "#333a881d",
    },
    {
        card_no: 502910,
        bank_name: "kar_afarin",
        bank_title: "بانک کارآفرین",
        bank_logo: "/banks/Karafarin.svg",
        color: "#333a881d",
    },
    {
        card_no: 603770,
        bank_name: "keshavarzi",
        bank_title: "بانک کشاورزی",
        bank_logo: "/banks/Keshavarzi.svg",
        color: "#29aa9a22",
    },
    {
        card_no: 639217,
        bank_name: "keshavarzi",
        bank_title: "بانک کشاورزی",
        bank_logo: "/banks/Keshavarzi.svg",
        color: "#29aa9a22",
    },
    {
        card_no: 628023,
        bank_name: "maskan",
        bank_title: "بانک مسکن",
        bank_logo: "/banks/Maskan.svg",
        color: "#1a000000",
    },
    {
        card_no: 639370,
        bank_name: "mehr_e_eghtesad",
        bank_title: "بانک مهر اقتصاد",
        bank_logo: "/banks/Mehr_Eghtesad.svg",
        color: "#1a656565",
    },
    {
        card_no: 606373,
        bank_name: "mehr_e_iranian",
        bank_title: "بانک قرض الحسنه مهر ایرانیان",
        bank_logo: "/banks/Mehr_Iran.svg",
        color: "#1a209b1c",
    },
    {
        card_no: 603799,
        bank_name: "melli",
        bank_title: "بانک ملی ایران",
        bank_logo: "/banks/Melli.svg",
        color: "#1a00e0d7",
    },
    {
        card_no: 610433,
        bank_name: "mellat",
        bank_title: "بانک ملت",
        bank_logo: "/banks/Mellat.svg",
        color: "#1aba0b22",
    },
    {
        card_no: 991975,
        bank_name: "mellat",
        bank_title: "بانک ملت",
        bank_logo: "/banks/Mellat.svg",
        color: "#1aba0b22",
    },
    {
        card_no: 622106,
        bank_name: "parsian",
        bank_title: "بانک پارسیان",
        bank_logo: "/banks/Parsian.svg",
        color: "#29980000",
    },
    {
        card_no: 502229,
        bank_name: "pasargad",
        bank_title: "بانک پاسارگاد",
        bank_logo: "/banks/Pasargad.svg",
        color: "#4d000000",
    },
    {
        card_no: 639347,
        bank_name: "pasargad",
        bank_title: "بانک پاسارگاد",
        bank_logo: "/banks/Pasargad.svg",
        color: "#4d000000",
    },
    {
        card_no: 627760,
        bank_name: "post_bank",
        bank_title: "پست بانک ایران",
        bank_logo: "/banks/Postbank.svg",
        color: "#1a14763e",
    },
    {
        card_no: 589463,
        bank_name: "refah",
        bank_title: "بانک رفاه",
        bank_logo: "/banks/Refah.svg",
        color: "#0d000000",
    },
    {
        card_no: 627961,
        bank_name: "saanat_va_maadan",
        bank_title: "بانک صنعت و معدن",
        bank_logo: "/banks/Sanat_Madan.svg",
        color: "#339b814c",
    },
    {
        card_no: 603769,
        bank_name: "saderat",
        bank_title: "بانک صادرات",
        bank_logo: "/banks/Saderat.svg",
        color: "#1a2d2a68",
    },
    {
        card_no: 621986,
        bank_name: "saman",
        bank_title: "بانک سامان",
        bank_logo: "/banks/Saman.svg",
        color: "#1a7dcdf1",
    },
    {
        card_no: 639607,
        bank_name: "sarmayeh",
        bank_title: "بانک سرمایه",
        bank_logo: "/banks/Sarmayeh.svg",
        color: "#29bd9ff5",
    },
    {
        card_no: 589210,
        bank_name: "sepah",
        bank_title: "بانک سپه",
        bank_logo: "/banks/Sepah.svg",
        color: "#33ffc400",
    },
    {
        card_no: 504706,
        bank_name: "shahr",
        bank_title: "بانک شهر",
        bank_logo: "/banks/Shahr.svg",
        color: "#29ffafb2",
    },
    {
        card_no: 502806,
        bank_name: "shahr",
        bank_title: "بانک شهر",
        bank_logo: "/banks/Shahr.svg",
        color: "#29ffafb2",
    },
    {
        card_no: 639346,
        bank_name: "sina",
        bank_title: "بانک سینا",
        bank_logo: "/banks/Sina.svg",
        color: "#1a277eb5",
    },
    {
        card_no: 627353,
        bank_name: "tejarat",
        bank_title: "بانک تجارت",
        bank_logo: "/banks/Tejarat.svg",
        color: "#290fadc7",
    },
    {
        card_no: 585983,
        bank_name: "tejarat",
        bank_title: "بانک تجارت",
        bank_logo: "/banks/Tejarat.svg",
        color: "#290fadc7",
    },
    {
        card_no: 636949,
        bank_name: "tejarat",
        bank_title: "بانک حکمت",
        bank_logo: "/banks/Hekmat.svg",
        color: "#29563288",
    },
    {
        card_no: 627648,
        bank_name: "tosee_saderat",
        bank_title: "بانک توسعه صادرات",
        bank_logo: "/banks/Tosee_Saderat.svg",
        color: "#1f6d6900",
    },
    {
        card_no: 502908,
        bank_name: "tosee_taavon",
        bank_title: "بانک توسعه تعاون",
        bank_logo: "/banks/Tosee_Taavon.svg",
        color: "#290076ff",
    },
];

export const detect_bank = (card_number: string) => {
    const onlyNum = (str: string) => str.replace(/[^.\d]/g, "");
    card_number = onlyNum(String(card_number));
    if (card_number.length < 6) return undefined;
    const card_number_prefix = parseInt(card_number.substr(0, 6));
    const bank = banks.filter((b) => b.card_no === card_number_prefix);
    return bank.length === 0 ? undefined : bank[0];
};

export const formatCardNumber = (value: string) => {
    const cardValue = value
        .replace(/\D/g, "")
        .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
    if (cardValue) {
        const finalValue = !cardValue[2]
            ? cardValue[1]
            : `${cardValue[1]}-${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ""
            }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
        return finalValue;
    }
};


