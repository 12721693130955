import { Xpay } from './routes/xpay';
import 'styles/global.scss';
import { Provider } from 'react-redux';
import { store } from 'core/redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import 'antd/dist/reset.css';
import {
  APPNAME,
  Fav,
  GTM,
  MANIFEST,
  ONLINE_CHAT,
  ONLINE_CHAT_LOGO,
} from './apis/constants';
import { isDesktop } from 'react-device-detect';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Helmet>
        <link href={Fav} rel="icon" type="image/x-icon" />
        <script src={`${ONLINE_CHAT}`} type="text/javascript" />
        <script src={`${GTM}`} type="text/javascript" />

        <link href={Fav} rel="icon" />
        <link href={Fav} rel="icon" type="image/png" />
        <link href={Fav} rel="apple-touch-icon" />
        <link href={MANIFEST} rel="manifest" />

        <title>{APPNAME} | پلتفرم معاملاتی ارز های دیجیتال</title>

        <meta content="no-cache" http-equiv="cache-control" />
        <meta content="0" http-equiv="expires" />
        <meta content="no-cache" http-equiv="pragma" />
        <meta
          name="description"
          content="خرید و فروش اتوماتیک و آسان پرفکت مانی ، تتر و رمز ارز ها احراز هویت هوشمند و سریع زیر ۱ دقیقه بدون معطلی نرخ های رقابتی و بدون کارمزد"
        />
      </Helmet>
      <ToastContainer position="top-center" toastStyle={{ direction: 'rtl' }} />
      {isDesktop && (
        <img
          src={`${ONLINE_CHAT_LOGO}`}
          className={'xpay-goftino-desktop'}
          onClick={() => window?.Goftino.toggle()}
        />
      )}
      <Xpay />
    </Provider>
  );
};

export default App;
