export enum MarketType {
  IRT = 1,
  Crypto,
  PM,
  USDT,
}

export const getMarketType = (symbol: string): MarketType => {
  switch (symbol) {
    case 'IRT':
      return MarketType.IRT;
    case 'USDT':
      return MarketType.USDT;
    case 'PM':
      return MarketType.PM;
    default:
      return MarketType.Crypto;
  }
};
