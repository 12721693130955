import { Button, Card } from 'components';
import './referral-box.scss';
import { digitSperator } from 'utils/scripts/exchange';
import { useEffect, useState } from 'react';
import { ReferralInfo } from 'core/domains/referral/referral';
import { getMineReferral } from 'core/repositories/referral/referral';
import { useNavigate } from 'react-router-dom';
import { copyClipboard } from '../../../../utils/scripts/copyToClipboard';
import { ReactComponent as CopyIcon } from 'assets/icons/referral/copy.svg';

export const ReferralBox = () => {
  const [loading, setLoading] = useState<boolean>();
  const [referralInfo, setReferralInfo] = useState<ReferralInfo>();
  useEffect(() => {
    getMineReferral(setLoading, setReferralInfo);
  }, []);

  const navigate = useNavigate();

  return (
    <Card
      className="referral-box"
      title="کسب درآمد"
      hrefTitle="انتقال به صفحه کسب درآمد"
      href="/dashboard/referral"
      divider
      blur={loading}
    >
      <div className="referral-compound desktop-show">
        <div className="referral-description">
          <p>
            دوستاتو دعـوت کن در ســـــود کارمــــــزد معاملات شریک شو
            میلیـــــونر شو!
          </p>
        </div>
        <div className="referral-banner" />
      </div>
      <div className="referral-info">
        <div className="col">
          <span>موجودی فعلی</span>
          <div>{digitSperator(referralInfo?.totalBalance)} تومان</div>
        </div>
        <div className="col">
          <span>تعداد دوستان دعوتی</span>
          <div>{referralInfo?.userCount} نفر</div>
        </div>
        <div className="col">
          <span>سطح و درصد پاداش</span>
          <div className={'nowrap'}>
            {referralInfo?.referralLevelTitle} - ٪
            {referralInfo?.referralLevelRewardPercent}
          </div>
        </div>
      </div>

      {referralInfo?.code ? (
        <div className="invitation">
          <span>کد دعوت</span>
          <a className="en">{referralInfo?.code}</a>
          <CopyIcon
            onClick={() => copyClipboard(referralInfo?.code, 'کد دعوت')}
          />
        </div>
      ) : (
        <Button
          type="primary"
          text="دریافت کد دعوت"
          onClick={() => navigate('/dashboard/referral')}
        />
      )}
    </Card>
  );
};
