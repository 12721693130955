import * as React from 'react';
import { Button, Card } from 'components';
import { QuestionCircleOutlined } from '@ant-design/icons';
import ReactCodeInput from 'react-verification-code-input';
import {
  TwoFactorAuthSmsVerify,
  TwoFactorAuthSmsVerifyError,
} from 'core/domains/profile/two-factor-auth/two-factor-auth';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { disableOtp, setupOtp } from 'core/repositories/profile/profile';
import './index.scss';
import { useEffect } from 'react';

type Props = {
  setStage: any;
  phone?: string;
  isActive: boolean;
  type?: 'sms' | 'google';
  setIsOpen: any;
};

export const Phone: React.FC<Props> = ({
  setStage,
  phone,
  isActive,
  type,
  setIsOpen,
}) => {
  const [fieldValue, setFieldValue] = React.useState<TwoFactorAuthSmsVerify>();
  const [countDownTime, setCountDownTime] = React.useState<number>(120);
  const [fieldError, setFieldError] =
    React.useState<TwoFactorAuthSmsVerifyError>();
  const [loading, setLoading] = React.useState<boolean>(false);

  function handlePhoneCodeVerify() {
    if (isActive) {
      disableOtp(setLoading, fieldValue?.phoneCode || '');
    } else
      setupOtp(
        setLoading,
        setFieldError,
        fieldError,
        fieldValue,
        setStage,
        type,
        setIsOpen,
      );
  }

  return (
    <Card className="xpay-two-step-verify">
      <>
        <div className="head">
          <p>
            برای امنیت بیشتر لازم است رمز یک بار مصرف ارسال شده به {phone} را
            وارد کنید.
          </p>
        </div>

        <div className="main code">
          <ReactCodeInput
            className={`input `}
            fieldWidth={48}
            values={
              fieldValue?.phoneCode ? fieldValue?.phoneCode.split('') : []
            }
            autoFocus={true}
            onChange={(value) => {
              setFieldValue((prev) => ({
                ...prev,
                phoneCode: value,
              }));
            }}
          />
          <div className="stats">
            <div className="timer">
              {countDownTime < 1 ? (
                <span className="resend" onClick={() => setCountDownTime(120)}>
                  ارسال مجدد
                </span>
              ) : (
                <>
                  <CountdownCircleTimer
                    size={30}
                    isPlaying
                    duration={countDownTime || 0}
                    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                    colorsTime={[7, 5, 2, 0]}
                    onComplete={() => setCountDownTime(0)}
                  >
                    {({ remainingTime }) => <span>{remainingTime}</span>}
                  </CountdownCircleTimer>{' '}
                  ثانیه تا ارسال مجدد کد
                </>
              )}
            </div>
            <div className="errors">
              {fieldError?.phoneCode && (
                <>
                  <QuestionCircleOutlined />
                  <span>{fieldError?.phoneCode}</span>
                </>
              )}
            </div>
          </div>
        </div>
        <Button
          type="info"
          text="تایید"
          onClick={() => handlePhoneCodeVerify()}
          loading={loading}
          disabled={fieldValue?.phoneCode?.length !== 6 ? true : false}
        />
      </>
    </Card>
  );
};
