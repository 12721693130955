import * as React from 'react';
import avatar from 'assets/images/avatar.svg';
import 'components/navbar/navbar.scss';
import useOnBlur from 'utils/scripts/useOnBlur';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'components/modal/modal';
import { Button } from 'components/button/button';
import { isLogin } from 'utils/scripts/api';
import { RootState } from 'core/redux/store';
import { connect, ConnectedProps } from 'react-redux';
import { setMenuItem } from 'core/redux/actions';
import { SidebarItems } from 'utils/constants/common';
import moment from 'jalali-moment';
import { useAppDispatch } from '../../core/redux/hooks';
import Notifications from 'components/notification/notification';
import { formatPhoneNumber } from 'utils/scripts/formatPhoneNumber';
import { ProfileType } from 'core/domains/profile/profile';
import { toast } from 'react-toastify';

import { ReactComponent as DownloadIcon } from 'assets/icons/navbar/download.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/navbar/settings.svg';
import { ReactComponent as AuthenticationIcon } from 'assets/icons/navbar/authentication.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/navbar/logout.svg';
import { APP_LINK } from '../../apis/constants';

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  sidebarOpen?: boolean;
  profile?: ProfileType;
}
const { useState, useRef } = React;

const Navbar: React.FC<Props> = (props) => {
  // const parsedProfile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile') ?? "") : null
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | null>('');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [profileInfo, setProfileInfo] = useState(null);
  const [notificationsIsOpen, setNotificationsIsOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const parsedProfile = localStorage.getItem('profile')
      ? JSON.parse(localStorage.getItem('profile') ?? '')
      : null;
    setProfileInfo(() => parsedProfile);
  }, []);

  const dropDownRef = useRef<any>(null);

  const location = useLocation();

  useOnBlur(dropDownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  const navigate = useNavigate();

  function handleModalSubmit() {
    toast.info('در حال خروج از پنل...');
    setTimeout(() => navigate('/login'), 2000);
    setModalIsOpen(false);
  }

  function findRoute(link: string) {
    var route = location.pathname.split('/');
    return link ==
      `/${route[1]}/${
        route[3] == 'create-order' ? 'orders/create-order' : route[2]
      }`
      ? true
      : false;
  }

  React.useEffect(() => {
    if (isLogin()) setUserName(localStorage.getItem('phone'));
    else window.open('/login', '_self');
  }, []);
  const dispatch = useAppDispatch();

  const path = location.pathname.trim();

  React.useEffect(() => {
    if (!isLogin()) navigate('/login');
    SidebarItems.map((item) => {
      location.pathname.includes(item?.link) && dispatch(setMenuItem(item.id));
    });
  }, [location.pathname]);

  return (
    <div className={`xpay-navbar ${props.sidebarOpen ? 'smaller' : 'bigger'}`}>
      <Modal
        className="logout-modal"
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <div className="confirm-box">
          <div className="head">
            <h4>آیا می‌خواهید خارج شوید؟</h4>
          </div>
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              onClick={() => handleModalSubmit()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setModalIsOpen(false)}
            />
          </div>
        </div>
      </Modal>

      <span className="space desktop-show"></span>
      <div className="box">
        <div className="navbar-welcome">
          <h4>
            {props?.profile?.fullName
              ? props?.profile?.fullName + ' '
              : props?.profile?.phoneNumber + ' '}
            عزیز، خوش آمدی.
          </h4>
          <span>
            {moment().locale('fa').format('dddd jD jMMMM jYYYY، ساعت HH:mm')}
          </span>
        </div>
        <div className="navbar-user">
          <div
            className="notifications-box"
            onClick={() => setNotificationsIsOpen(!notificationsIsOpen)}
          >
            <Notifications
              isOpen={notificationsIsOpen}
              setIsOpen={setNotificationsIsOpen}
            />{' '}
            <span className="desktop-show">اعلانات</span>
          </div>
          <div
            className="navbar-profile"
            ref={dropDownRef}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div className="avatar">
              <img src={avatar}></img>
            </div>
            <div className="dropdown">
              <h4>
                {props.state.profileData.level.uniqueName.id
                  ? props.state.profileData.level.uniqueName.id > 1
                    ? props.state.profileData.fullName
                      ? props.state.profileData.fullName
                      : props.state.profileData.phoneNumber
                    : props.state.profileData.phoneNumber
                  : props.state.profileData.phoneNumber}{' '}
              </h4>
              <div className="dropdown-profile">
                <span id="name">{props?.profile?.fullName}</span>
                <span id="phone" className="desktop-show">
                  {formatPhoneNumber(localStorage.getItem('phone'))}
                </span>
              </div>
              <div>
                <svg
                  className={'profile-dropdown-arrow'}
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: `rotate(${isOpen ? 180 : 0}deg)`,
                  }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.80113 1.22462L5.48011 5.78989C5.21495 6.07004 4.78505 6.07004 4.51989 5.78989L0.19887 1.22462C-0.0662899 0.944468 -0.0662899 0.490258 0.19887 0.210111C0.464029 -0.0700374 0.893937 -0.0700374 1.1591 0.210111L5 4.26813L8.8409 0.210111C9.10606 -0.070037 9.53597 -0.070037 9.80113 0.210111C10.0663 0.490259 10.0663 0.944468 9.80113 1.22462Z"
                    fill="#2C3E60"
                  />
                </svg>
              </div>
              {isOpen && (
                <ul className={'dropdown-list'}>
                  <li>
                    <Link to={APP_LINK}>
                      <DownloadIcon /> <span>دانلود اپلیکیشن</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={'/dashboard/profile'}>
                      <SettingsIcon /> <span>حساب کاربری</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={'/dashboard/authentication'}>
                      <AuthenticationIcon /> <span>احراز هویت</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={''}
                      onClick={(e) => {
                        e.preventDefault();
                        setModalIsOpen(true);
                      }}
                    >
                      <LogoutIcon /> <span>خروج از حساب</span>
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(Navbar);
